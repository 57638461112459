import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { API } from "../../service";
import swal from "sweetalert";
import moment from "moment";
import Pagination from "react-js-pagination";
import "../../common/style/style.css";
import { exportData } from "../../common/downloadxls";
import { useDispatch } from "react-redux";
import { Loader } from "../../common/Loader";
export const User = () => {
  const [user, setUser] = useState([]),
    [userEmails, setUserEmails] = useState([]),
    [selectedUser, setSelectedUser] = useState([]),
    [perPage, setPerPage] = useState(10),
    [offset, setOffset] = useState(0),
    [currentPage, setCurrentPage] = useState(1),
    [totalUser, setTotalUser] = useState(0),
    [loader, setLoader] = useState(true),
    [location, setLocation] = useState([]),
    dispatch = useDispatch();
  let timeout = 0;
  useEffect(() => {
    getUser(offset, perPage);
    getLocation()
    getUserEmail()
    dispatch({ type: "Show_Sidebar_Route", payload: "/User" });
  }, [offset, perPage]);
  //func used for api call of user
  const getUser = async (offset, perPage) => {
    const locationid = JSON.parse(localStorage.getItem("locationid"));
    const query = {
      // where: {
      // order: "id desc",
      // active: 1,
      offset: offset,
      limit: perPage,
      role: "CUSTOMER",
      locationId: locationid && locationid.length ? locationid : [],
      name: "null",
      emailId: "null",
      phoneNumber: "null",
      // }
    };
    setLoader(true);
    // localStorage.getItem('role') === "LOCATIONMANAGER" && (query.where['locationid'] = JSON.parse(localStorage.getItem('locationid')))
    const res = await API.getUserRest(query);
    //   debugger;
    if (res?.status === 200) {
      // const user = res?.data?.data?.allUserdata?.Userdata,
      //     total = res?.data?.data?.allUserdata.totalCount
      const user = res?.data?.response?.data,
        total = res?.data?.response?.totalcount; // need to be done by backend

      setTotalUser(total);
      setUser(user);
      setLoader(false);
    } else {
      setUser([]);
      setLoader(false);
      swal({
        icon: "warning",
        title: "Server Error,Try Agian",
        className: "loginSwal",
      });
    }
  },
    //func used for calling all location
    getLocation = async () => {
      const res = await API.getLocation()
      if (res && res?.status == 200 && res.statusText == "OK") {
        const data = res?.data?.data?.allLocations?.Locations ?? []
        setLocation(data)
      } else {
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
        });
      }
    },
    // get all user email 
    getUserEmail = async () => {
      const res = await API.getUserEmail()
      if (res && res?.status == 200 && res.statusText == "OK") {
        const data = res?.data?.data?.allUserdata?.Userdata ?? []
        console.log("ggggggggggggg", data)
        setUserEmails(data)
      } else {
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
        });
      }
    },
    //func used for searching
    searchUser = async (e) => {
      var searchText = e.target.value;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        if (searchText?.length > 2) {
          setLoader(true);
          const locationid = JSON.parse(localStorage.getItem("locationid"));
          const query = {
            offset: offset,
            limit: perPage,
            role: "CUSTOMER",
            locationId: locationid && locationid.length ? locationid : [],
            name: searchText,
            emailId: searchText,
            phoneNumber: searchText,
          };
          setLoader(true);
          const res = await API.getUserRest(query);
          // const res = await API.getUser(query, 1, 10);
          console.log("res **** : ", res);
          if (res?.status === 200) {
            const userT = res?.data?.response?.data,
              totalT = res?.data?.response?.totalcount; // need to be done by backend

            setTotalUser(totalT);
            setUser(userT);
            setLoader(false);
          } else {
            setUser([]);
            setLoader(false);
            swal({
              icon: "warning",
              title: "Server Error,Try Agian",
              className: "loginSwal",
            });
          }
        } else {
          getUser(offset, perPage);
        }
      }, 300);
    },
    //func for pagination showing 10 customer per page
    handlePagination = async (page_num) => {
      setCurrentPage(page_num);
      const offset_num = (perPage * (page_num - 1));
      setOffset(offset_num);
    },
    //func used for selecting all checkbox
    allSelect = (e, value, item) => {
      if (value === "all") {
        var checkboxes = document.getElementsByTagName("input");
        if (e.target.checked) {
          for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type == "checkbox") {
              checkboxes[i].checked = true;
            }
          }
          setSelectedUser(user);
        } else {
          for (var i = 0; i < checkboxes.length; i++) {
            console.log(i);
            if (checkboxes[i].type == "checkbox") {
              checkboxes[i].checked = false;
            }
          }
          setSelectedUser([]);
        }
      } else {
        if (e.target.checked) {
          const addUSer = selectedUser.concat(item);
          setSelectedUser(addUSer);
        } else {
          const filterUser = selectedUser.filter((el) => el?.id !== item?.id);
          setSelectedUser(filterUser);
        }
      }
    },
    userLocation = (id) => {
      const filterLoc = location.filter(el => el.id === id)
      if (filterLoc.length > 0) return filterLoc[0].locationname
      return "Error"
    }
  return (
    <div className="coupon">
      <h3>Customers</h3>
      <div className="couponBox">
        <div className={"row"}>
          <div className={"col-md-6"}>
            <input
              onChange={(e) => searchUser(e)}
              placeholder={"Search by Name/Email/PhoneNumber"}
              style={{
                width: "100%",
                border: "2px solid black",
                marginLeft: "10px",
                padding: "10px",
              }}
            />
            {/* <div className={"col-md-4"}>
            {console.log("lllllllllllllllllllllllllllllllll")}
            <div className="couponButton">
                <Button
                  className="deleteAllBtn"
                  onClick={() => exportData(selectedUser, "userTable")}
                >
                  Download To Customer Details
                </Button>
            </div>
          </div> */}
          </div>
          {/* <div className={"col-md-4"}>
            <div className="couponButton">
              <Button className="deleteAllBtn">
               Send Notification To All Customer
              </Button>
            </div>
          </div> */}
          <div className={"col-md-6"}>
            {console.log("oooooooooooooooo")}
            <div className="couponButton">
              {selectedUser?.length > 0 && (
                <Button
                  className="deleteAllBtn"
                  onClick={() => exportData(selectedUser, "userTable")}
                >
                  Download To CSV
                </Button>
              )}
              <Button
                className="deleteAllBtn"
                onClick={() => exportData(userEmails, "userEmail")}
              >
                Download user Email id
              </Button>
            </div>
          </div>
        </div>
        {loader ? (
          <Loader />
        ) : (
          <>
            <table className="couponTable">
              <tr>
                <th>
                  Select All
                  <input
                    style={{ marginLeft: "10px", paddingTop: "5px" }}
                    className="PrivateSwitchBase-input-20"
                    name="checkedId"
                    type="checkbox"
                    onChange={(e) => allSelect(e, "all", "item")}
                    data-indeterminate="false"
                    value=""
                  />
                </th>
                <th>
                  <span>Full Name</span>
                </th>
                <th>
                  <span>Email</span>
                </th>
                <th>
                  <span>Mobile Number</span>
                </th>
                <th>
                  <span>Location</span>
                </th>
                <th>
                  <span>Address</span>
                </th>
                <th>
                  <span>Created On</span>
                </th>
                {/*<th>*/}
                {/*  <span>Notification</span>*/}
                {/*</th>*/}
              </tr>
              {user.length > 0 ? (
                user.map((ed, ex) => {
                  return (
                    <tr key={ex}>
                      <td>
                        <input
                          onClick={(e) => allSelect(e, "one", ed)}
                          id="allCheck"
                          className="PrivateSwitchBase-input-20"
                          name="checkedId"
                          type="checkbox"
                          // onChange={(e)=>onHandleCheckBox(e,ed)}
                          data-indeterminate="false"
                          value=""
                        />
                      </td>
                      <td>
                        {ed?.firstName ?? "N/A"} {ed?.lastName ?? ""}
                      </td>
                      <td>{ed?.email ?? "N/A"}</td>
                      <td>{ed?.phoneNumber ?? "N/A"}</td>
                      <td>{userLocation(ed?.locationid ?? 0)}</td>
                      <td>{ed?.address ?? "N/A"}</td>
                      <td>
                        {moment(ed?.createdOn).format("YYYY-MM-DD") ?? "N/A"}
                      </td>
                      {/*<td className="deleteIcon" onClick={() => {}}>*/}
                      {/*  <div*/}
                      {/*    style={{*/}
                      {/*      width: "20px",*/}
                      {/*      height: "20px",*/}
                      {/*      textAlign: "center",*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <svg*/}
                      {/*      xmlns="http://www.w3.org/2000/svg"*/}
                      {/*      version="1.1"*/}
                      {/*      id="Capa_1"*/}
                      {/*      x="0px"*/}
                      {/*      y="0px"*/}
                      {/*      viewBox="0 0 60 60"*/}
                      {/*      style={{ enableBackground: "new 0 0 60 60" }}*/}
                      {/*    >*/}
                      {/*      <g>*/}
                      {/*        <path d="M26,9.586C11.664,9.586,0,20.09,0,33c0,4.499,1.418,8.856,4.106,12.627c-0.51,5.578-1.86,9.712-3.813,11.666   c-0.304,0.304-0.38,0.768-0.188,1.153C0.276,58.789,0.625,59,1,59c0.046,0,0.093-0.003,0.14-0.01   c0.349-0.049,8.432-1.213,14.317-4.585c3.33,1.333,6.874,2.009,10.544,2.009c14.336,0,26-10.503,26-23.414S40.337,9.586,26,9.586z" />*/}
                      {/*        <path d="M55.894,37.042C58.582,33.27,60,28.912,60,24.414C60,11.503,48.337,1,34,1c-8.246,0-15.968,3.592-20.824,9.42   C17.021,8.614,21.38,7.586,26,7.586c15.439,0,28,11.4,28,25.414c0,5.506-1.945,10.604-5.236,14.77   c4.946,1.887,9.853,2.6,10.096,2.634c0.047,0.006,0.094,0.01,0.14,0.01c0.375,0,0.724-0.211,0.895-0.554   c0.192-0.385,0.116-0.849-0.188-1.153C57.753,46.753,56.403,42.619,55.894,37.042z" />*/}
                      {/*      </g>*/}
                      {/*    </svg>*/}
                      {/*  </div>*/}
                      {/*</td>*/}
                    </tr>
                  );
                })
              ) : (
                <tr colspam={"8"}>No Data Found</tr>
              )}
            </table>
            <div className={"row"}>
              <div className={'col-md-7'}>
                <div className="newPagination" style={{ textAlign: "right" }}>
                  <Pagination
                    innerClass="pagination pagination-sm"
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    activeClass={"active"}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalUser}
                    pageRangeDisplayed={5}
                    onChange={handlePagination}
                  />
                </div>
              </div>
              <div className={'col-md-5'}>
                <span style={{ float: "right", color: "#ef3125" }}>
                  {`Showing ${totalUser || 0} Locations (10 PerPage) `}
                </span>
              </div>
            </div>
          </>
        )}
        {/*{isActive &&*/}
        {/*<table className="couponTable" id={"userTable"}>*/}
        {/*    <tr>*/}
        {/*        <th><span>Full Name</span></th>*/}
        {/*        <th><span>Email Id</span></th>*/}
        {/*        <th><span>Mobile Number</span></th>*/}
        {/*        <th><span>Type</span></th>*/}
        {/*        <th><span>Address</span></th>*/}
        {/*        <th><span>Created On</span></th>*/}
        {/*    </tr>*/}
        {/*    {selectedUser.map((ed, ex) =>{*/}
        {/*            return(*/}
        {/*                <tr key={ex}>*/}
        {/*                    <td>{ed?.firstname ?? "N/A"} {ed?.lastname ?? ""}</td>*/}
        {/*                    <td>{ed?.email ?? "N/A"}</td>*/}
        {/*                    <td>{ed?.phonenumber ?? "N/A"}</td>*/}
        {/*                    <td>{ed.role ?? "N/A"}</td>*/}
        {/*                    <td>{ed?.address ?? "N/A"}</td>*/}
        {/*                    <td>{moment(ed?.createdon).format('YYYY-DD-MM') ?? "N/A"}</td>*/}
        {/*                </tr>*/}
        {/*            )})}*/}
        {/*    < /table>*/}
        {/*    }*/}
      </div>
    </div>
  );
};
