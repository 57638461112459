//query for getting all actions
export const getAllActions =`
{
  allCalltoactions(where:{active:1}){
    Calltoactions{
      id
      name
      active
    }
  }
}`
//query for getting all target notification
export const getTargetNotifications = `
{
  allTargetuserforsentthenotificatons(where:{active:1}){
    Targetuserforsentthenotificatons{
      id
      name
    }
  }
}
`
//query for getting all recurrence
export const getRecurrences = `
{
  allRecurrences(where:{active:1}){
    Recurrences{
      id
      name
    }
  }
}
`
//query for save notification
export const saveNotification =`
mutation saveNotification($obj:NotificationInput!){
saveNotification(obj:$obj){
    id
    active
}
}
`
//query for get notification with map
export const getNotificationWithMap =`
query allNotificationlocationmappings($where:JSON){
          allNotificationlocationmappings(where:$where){
             Notificationlocationmappings{
              id
              active
              notificationlocationmappingnotificationidmaprel{
        Notifications{
          actionid
          enddate
          msg
          targetmsgsendId
          updatedon
          neverend
          startdate
          starttime
          recurrenceid 
          notificationmsg    
        }
      }
}
}
}
`
//query for save notification with map
export const saveNotificationWithMap =`
mutation saveNotificationlocationmapping($obj:NotificationlocationmappingInput!){
saveNotificationlocationmapping(obj:$obj){
    id
    active
}
}
`
export const getNotification =`
query allNotifications($where:JSON){
  allNotifications(where:$where){
    Notifications{
      locationid
      active
      actionid
      msg
      neverend
      notificationmsg
      targetmsgsendid
      startdate
      starttime
      recurrenceid
      id
      enddate
    }
  }
  }
`
export const getNotificationMapping =`
query allNotificationlocationmappings($where:JSON){
  allNotificationlocationmappings(where:$where){
    Notificationlocationmappings{
      id
      locationid
      notificationid
      active
      notificationlocationmappinglocationidmaprel{
        Locations{
          id
          locationname
          notificationlocationmappingLocationIdMap{
            Notificationlocationmappings{
              id
            }
          }
        }
      }
    }
  }
}
`
export const saveNotificationMapping =`
query saveNotificationlocationmapping($where:JSON){
  saveNotificationlocationmapping(where:$where){
      id
      locationid
      notificationid
      active
      notificationlocationmappinglocationidmaprel{
        Locations{
          id
          locationname
        }
      }
    }
  }
`;

export const viewNotificationQuery = `
query allNotifications($where:JSON,$first:Int,$last:Int){
    allNotifications(where: $where, first: $first, last: $last){
      totalCount
      Notifications{
        id
        active
        enddate
        locationid
        msg
        neverend
        notificationmsg
        recurrenceid
        startdate
        targetmsgsendid
        notificationrecurrenceidmaprel{
          Recurrences{
            id
            name
          }
        }
        notificationtargetmsgsendidmaprel{
          Targetuserforsentthenotificatons{
            id
            name
          }
        }
      }
    }
  }
`;