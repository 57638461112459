import React, { Component } from 'react';
import Modal from "../../Form/Modal";
import { addUserManagementConfig, editUserManagementConfig } from "./UserManagementConfig";
import "./userManagement.css";
import { API } from '../../service'
import swal from "sweetalert";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import {Loader} from "../../common/Loader";

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      count: 0,
      locationOptions: [],
      show: false,
      editableData: [],
      editLocationData: [],
      perPage:10,
      offset:10,
      currentPage:1,
      loader : true,
      filter: {
        order: "id desc",
        role: "LOCATIONMANAGER"
      }
    }
  }

  componentDidMount() {
    this.props.sideBarPath("/userManagement");
    this.getUserData(this.state.offset, this.state.perPage);
    this.getLocationData();
  }

  getLocationData = async () => {
    this.setState({loader : true})
    const test={
      where: {
        order: "locationname desc",
        active: 1
      }
    }
    const resData = await API.getLocation(test);
    if(resData?.status === 200){
      let data = resData.data.data.allLocations.Locations.map(item => {
        return { id: item.id, name: item.locationname }
      })
      this.setState({locationOptions: data, loader: false});
    }
    else{
      swal({
        icon: "warning",
        title: "Server Error,Try Agian",
        className: "loginSwal",
    });
    this.setState({locationOptions:[], loader: false}) 
    }
  }

  getUserData = async (offset, perPage) => {
    this.setState({loader : true})
    const query = {
      where: this.state.filter
    }
    const resData = await API.getUser(query, offset, perPage);
    if(resData?.status === 200){
      let userdata = resData.data.data.allUserdata.Userdata.map(item => {
        item["fullName"] = item.firstname ? item.firstname.concat(item.lastname ? " " + item.lastname : "") : "N/A";
        item["address"] =
          (item.address ? item.address + " "
            : ""
          )
            .concat(
              item.city
                ? item.city + " "
                : ""
            )
            .concat(
              item.state ? item.state + " "
                : ""
            )
            .concat(
              item.country
                ? item.country
                : ""
            );
            return item;
      });
      const total = resData.data.data.allUserdata?.totalCount || 0;
      this.setState({listData: userdata, count : total, loader: false});
    }
    else{
      swal({
        icon: "warning",
        title: "Server Error,Try Agian",
        className: "loginSwal",
    });
    this.setState({listData:[], count: 0, loader: false}) 
    }
};

  preSubmitChanges = async (e) => {
    let newData = [];
    if (e.id) {
      delete e.userdatalocationidmaprel;
      delete e.userlocationmappingUseridMap;
      e["updatedby"] = parseFloat(localStorage.getItem("id"));
      newData = e.locationid;
      delete e.locationid;
    }
    else {
      var body = {
        "firstName": e['firstname'],
        "lastName": e['lastname'],
        "emailId": e['email'],
        "mobileNumber": e['phonenumber'],
        "password": "Qss@1234",
        "role": e['role'],
        "locationId": this.getLocID(e['locationid']),
        "deviceId": "null",
        "createdby": parseFloat(localStorage.getItem("id")),
        "updatedby": parseFloat(localStorage.getItem("id"))
      }
    }
      if(e.id){
        const res = await API.saveUserData({obj: e});
        if (res?.status == 200) {
          let query = "";
          let query1 = "";
          if(this.state.editLocationData && this.state.editLocationData.length && res?.status){
            this.state.editLocationData.map((item, index)=>{
              return item && item.locationmapid
              ? (query1 += `id${index}: saveUserlocationmapping(
                obj: {
                active:${0}
                id:${item.locationmapid}    
                  }
                ){id}`)
              : "";
            })
            const resData = await API.saveUserMapData(`mutation {
              ${query1}
            }`);
            if(resData?.status === 200){
              if(newData && newData.length && resData?.status === 200){
                newData.map((item, index) => {
                  item.locationmapid
                    ? (query += `id${index}: saveUserlocationmapping(
                      obj: {
                      locationid:${parseFloat(item.id)}
                      userid: ${parseFloat(e.id)}
                      active:${1}
                      id:${item.locationmapid}    
                        }
                      ){id}`)
                    : (query += `id${index}: saveUserlocationmapping(
                      obj: {
                      locationid:${parseFloat(item.id)}
                      userid: ${parseFloat(e.id)}
                      active:${1}  
                        }
                      ){id}`);
                });
                const resData = await API.saveUserMapData(`mutation {
                  ${query}
                }`);
                if(resData?.status === 200){
                  swal({
                    title: "Updated successfully",
                    icon: "success"
                  });
                }
              }
            }
          }
          else{
            if(newData && newData.length){
              newData.map((item, index) => {
                item.locationmapid
                  ? (query += `id${index}: saveUserlocationmapping(
                    obj: {
                    locationid:${parseFloat(item.id)}
                    userid: ${parseFloat(e.id)}
                    active:${1}
                    id:${item.locationmapid}    
                      }
                    ){id}`)
                  : (query += `id${index}: saveUserlocationmapping(
                    obj: {
                    locationid:${parseFloat(item.id)}
                    userid: ${parseFloat(e.id)}
                    active:${1}  
                      }
                    ){id}`);
              });
              const resData = await API.saveUserMapData(`mutation {
                ${query}
              }`);
              if(resData?.status === 200){
                swal({
                  title: "Updated successfully",
                  icon: "success"
                });
              }
            }
          }
          swal({
            title: "Updated successfully",
            icon: "success"
          });
          this.getUserData();
        } else {
          swal({
            icon: "warning",
            title: "Server Error,Try Agian",
            className: "loginSwal",
        });
        }
      }
      else{
      const res = await API.createUser(body);
      if (res?.status == 200 && res?.data?.response.status === 200) {
        swal({
          title: "Saved successfully",
          icon: "success"
        });
        this.getUserData();
        return false;
      }
      else if(res?.status == 200 && res?.data?.response.status === 201){
        swal({
          title: res?.data?.response?.message ?? 'EmailID Already Exist',
          icon: "warning"
        });
      }
      else {
        swal({
          icon: "warning",
          title: "Server Error,Try Again",
          className: "loginSwal",
      });
          return false;
        }
      }
  };
  //func for getting location id
  getLocID = value =>{
    var isArray = [];
    for (var i = 0; i <= value.length-1 ; i++){
      const filterData = this.state.locationOptions.filter(el => el.name === value[i].name)
      isArray.push(filterData[0].id)
    }
    return isArray
  }

  handleDelteItem = (item) => {
    if (item.active === 1 || item.active === "1") {
      swal({
        title: "Are you sure you want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(async willDelete => {
        if (willDelete) {
          const test = {
            "obj":{
            id: item.id,
            active: 0
          }};

          const res = await API.saveUserData(test);
          if (res?.status == 200) {
            swal({
              title: "Deleted successfully",
              icon: "success"
            });
            this.getUserData();
          } else {
            swal({
              icon: "warning",
              title: "Server Error,Try Agian",
              className: "loginSwal",
          });
          }
  }
})
    }
}

  negateShow = () => {
    this.setState({ show: false });
  };

  handleEditModal = async item => {
    const res = await API.getUserDataByID(item.id);
    if (res?.status == 200) {
      let data = res.data.data.allUserdata.Userdata[0],
          isArray = [],
          locationmap = data.userlocationmappingUseridMap.Userlocationmappings;
      locationmap.map(el => {
        isArray.push({locationmapid: el.id, name: el?.userlocationmappinglocationidmaprel?.Locations?.length > 0 ? el?.userlocationmappinglocationidmaprel?.Locations[0].locationname : "", id: el.locationid})
      })
      data['locationid'] = isArray;
        this.setState({
          show: true,
          editableData: data,
          editLocationData: isArray
        });
    } else {
      swal({
        icon: "warning",
        title: "Server Error,Try Agian",
        className: "loginSwal",
    });
    this.setState({
      show: false,
      editableData: []
    });
      }
  };

  editList = () => {
    return (
      <Modal
        formConfig={editUserManagementConfig}
        editableData={this.state.editableData}
        showButton={false}
        modalTitle="Edit Location Manager"
        resetModal={this.negateShow}
        dialogClass="userManageAddUserModal"
        preSubmitChanges={this.preSubmitChanges}
        params={{ locationOptions: this.state.locationOptions }}
        modalCloseCallback={() => { }}
      />
    );
  };
//func for pagination showing 10 customer per page
  handlePagination = async (page_num) => {
    const offset_num = page_num * this.state.perPage
    this.setState({
      currentPage : page_num,
      offset:offset_num,
      loader: true
    })
    this.getUserData(offset_num, this.state.perPage)
  }
  render() {
    const listData = this.state.listData;
    return (
      <div className="userManagement">
        <h3>Location Manager Management</h3>
        {this.state.loader ? <Loader /> :
        <div className="userManagementInnerBox">
          {this.state.show ? this.editList() : ""}
          <div className="userManagementButton">
            {/* <Button className="notificationUser">Send Notification To Users</Button> */}
            {localStorage.getItem("role") !== "LOCATIONMANAGER" ?
                <Modal
              showButton={true}
              buttonTitle="Add New Location Manager"
              className="addUserButton"
              modalTitle="Add Location Manager"
              formConfig={addUserManagementConfig}
              dialogClass="userManageAddUserModal"
              preSubmitChanges={this.preSubmitChanges}
              params={{ locationOptions: this.state.locationOptions }}
              modalCloseCallback={() => { }}
            /> : ""}
            {/* <Button className="downloadBtn"><div>
                            <span>Download to csv</span>
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"/></g></svg>
                        </div></Button> */}
          </div>
          <table className="usertable">
            <tr>
              {/* <th><input
                    className="PrivateSwitchBase-input-20"
                    name="checkedId"
                    type="checkbox"
                    disabled={listData?.length > 0 ? false : true}
                    // onChange={(e)=>onHandleCheckBox(e,ed)}
                    data-indeterminate="false"
                    value="" /></th> */}
              <th><span>Full Name</span></th>
              <th><span>Email</span></th>
              <th><span>Mobile Number</span></th>
              <th><span>Type</span></th>
              <th><span>Locations</span></th>
              <th><span>Status</span></th>
              <th><span>Edit</span></th>
              <th><span>Delete</span></th>
            </tr>
            {listData?.length > 0 ? listData?.map((ed, ex) => {
              return (
                <tr key={ex}>
                  {/* <td><input
                             className="PrivateSwitchBase-input-20"
                             name="checkedId"
                             type="checkbox"
                             // onChange={(e)=>onHandleCheckBox(e,ed)}
                             data-indeterminate="false"
                             value="" /></td> */}
                  <td>{ed?.fullName ?? "N/A"}</td>
                  <td>{ed?.email ?? "N/A"}</td>
                  <td>{ed?.phonenumber ?? "N/A"}</td>
                  <td>{ed?.role === "SUPERADMIN" ? "Super Admin" : ed?.role === "LOCATIONMANAGER" ? "Location Manager" : ""}</td>
                  <td>{ed?.userlocationmappingUseridMap.Userlocationmappings.length > 0 ?
                      ed?.userlocationmappingUseridMap.Userlocationmappings.map(loc => `${loc?.userlocationmappinglocationidmaprel?.Locations[0]?.locationname},`)
                    : "N/A"}</td>
                  <td><span style={ed?.active === 1 ? { color:"green" } : { color:"red" }}>{ed?.active === 1 ? "Active" :"InActive"}</span></td>
                  <td className="deleteIcon" onClick={() => this.handleEditModal(ed)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" /></svg>
                  </td>
                  <td className="deleteIcon" onClick={() => this.handleDelteItem(ed)} style={ed?.active ? {} : {cursor:"not-allowed"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" /></svg>
                  </td>
                </tr>
              )
            })
              : <div style={{ paddingLeft: "10px", fontSize: "15px", fontWeight: "700" }}>
                No Data Found
              </div>
            }
          </table>
          <div className={'row'}>
            <div className={'col-md-7'}>
              <div className="newPagination" style={{textAlign:"right"}}>
                <Pagination
                    innerClass='pagination pagination-sm'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={this.state.currentPage}
                    activeClass={"active"}
                    itemsCountPerPage={this.state.perPage}
                    totalItemsCount={this.state.count}
                    pageRangeDisplayed={5}
                    onChange={this.handlePagination}
                />
              </div>
            </div>
            <div className={'col-md-5'}>
              <span style={{float:"right", color:"#ef3125"}}>{`Showing ${this.state.count || 0} Users (10 PerPage)`}</span>
            </div>
        </div>
        </div>
       }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sideBarPath: route =>
      dispatch({ type: "Show_Sidebar_Route", payload: route })
  };
};

export default connect(null, mapDispatchToProps)(UserManagement);