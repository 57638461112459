import React from 'react';
import {Bar} from 'react-chartjs-2';
import './Analytics.css';
import { connect } from 'react-redux';
import {API} from "../../service";
import swal from "sweetalert";
import moment from 'moment'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRangePicker} from "react-date-range";
import {Button, FormControl, FormHelperText, Input, Select} from "@material-ui/core";
import {exportData} from '../../common/downloadxls'
import { Loader } from '../../common/Loader'
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
class Analytics extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            labels: ['No of User', 'Orders initiated', 'No of Coupons'],
            dataset: [0, 0, 0],
            role: localStorage.getItem('role'),
            locationid:  [],
            locArray:[],
            dateRangeFilter: [],
            selectedField: "All",
            dropDownOpen: false,
            startDate: "null",
            endDate: "null",
            rangeHeading: `All`,
            rangeDateValue: [{
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }],
            dateExcel:[],
            loader:true
        }
    }

    componentDidMount() {
        this.props.sideBarPath("/analytics");
        //api call for user,order,coupon
        this.getAll()
        //api call for location
        this.getLocationData()
    }
    //func for calling location
    getLocationData = async () => {
        const test= {
                where: {
                    order: "locationname desc",
                    active: 1
                }
            }
            localStorage.getItem('LOCATIONMANAGER') && (test['where']['and'] = [{"and": [{ "id": {"inq":JSON.parse(localStorage.getItem('locationid'))} }]}])
        const resData = await API.getLocation(test);
        if(resData?.status === 200){
            // let data = resData.data.data.allLocations.Locations.map(item => {
            //   return { id: item.id, name: item.locationname }
            // })
            // const addSel = {id : 100, name :"Select All"}
            // data.unshift(addSel)
            this.setState({locationData: resData.data.data.allLocations.Locations});
        }
        else{
            swal({
                icon: "warning",
                title: "Server Error,Try Agian",
                className: "loginSwal",
            });
            this.setState({locationData:[]})
        }
    }

    //func for getting user,order,coupon credential api call
    getAll = async (startDate, endDate, location) => {
        this.setState({loader : true})
        const obj = {
            startDate: startDate || "null",
            endDate: endDate || "null",
            role: "CUSTOMER",
            locationId: location || []
        }
        // this.state.role === "LOCATIONMANAGER" && (obj['locationId'] = this.state.locationid)
       const res =await API.getAnalytics(obj)
                if(res?.status === 200){
                    const resData = res?.data?.response?.dataValue || [],
                        cloneDateset = []
                            cloneDateset.push(resData[0]?.totalUserCount || 0)
                            cloneDateset.push(resData[0]?.totalOrderCount || 0)
                            cloneDateset.push(resData[0]?.totalCouponCount || 0)
                    this.setState({dataset : cloneDateset, dateExcel: resData, loader: false })
                }else{
                    this.setState({dataset : [0, 0, 0], loader: false})
                    swal({
                        icon: "warning",
                        title: "Server Error,Try Again",
                        className: "loginSwal",
                    });
                }

    }
  //handle for date input
    onHandleChange = e => {
        if(e === "All"){
            this.getAll(null,null , this.state.locationid)
            this.setState({dropDownOpen : false, rangeHeading:`All`, startDate:"null", endDate:"null"})
        }else{
            const currDate = moment(),
                endDate = currDate.format('YYYY-MM-DD'),
                startDate = currDate.subtract(e.toString().split(" ")[0], 'days').format('YYYY-MM-DD')
            this.getAll(startDate,endDate, this.state.locArray)
            this.setState({
                dropDownOpen : false,
                rangeHeading:`${moment(startDate).format('DD MMM')}-${moment(endDate).format('DD MMM')}`,
                startDate,
                endDate
            })
        }
}
//handle for date range
    handleDateField = async () =>{
        await this.getAll(moment(this.state.rangeDateValue[0].startDate).format('YYYY-MM-DD'), moment(this.state.rangeDateValue[0].endDate).format('YYYY-MM-DD'), this.state.locArray)
        this.setState({
            dropDownOpen : false,
             startDate :  moment(this.state.rangeDateValue[0].startDate).format('YYYY-MM-DD'),
            endDate : moment(this.state.rangeDateValue[0].endDate).format('YYYY-MM-DD')
        })
    }
    handleCheck = async (event, e) => {
        if(event.target.checked) {
            const locationid = [...this.state.locationid],
                  locArray= [...this.state.locArray];
            locArray.push(e.id)
            locationid.push(e);
           await this.getAll(this.state.startDate, this.state.endDate, locArray)
            this.setState({ locationid, locArray });
        } else {
            const temp = [...this.state.locationid];
            const locationid = temp.filter(item => {
                return item ? item.id !== e.id : "";
            });
            const loctemp = [...this.state.locArray],
                  locArray = loctemp.filter(el => el !== e.id)
           await this.getAll(this.state.startDate, this.state.endDate, locArray)
            this.setState({ locationid, locArray });
        }
    };
  render() {
    return (
                    <div className="activitySection">
                        <div className="selectDropBox">
                            <div className="customDateRange">
                                <div className="dateInner">
                                    <div className="dateField">{this.state.rangeHeading}</div>
                                    <button onClick={() => this.setState({dropDownOpen : !this.state.dropDownOpen})}></button>
                                </div>
                                {
                                    this.state.dropDownOpen &&
                                    <div className="dropDown">
                                        <ul>
                                            <li className={this.state.selectedField === "All" ? "active" : ""}
                                                onClick={() => this.onHandleChange('All')}>
                                                <label>{`All`}</label>
                                            </li>
                                            <li className={this.state.selectedField === "LAST7DAYS" ? "active" : ""}
                                                onClick={() => this.onHandleChange('7 DAYS')}>
                                                <label>{`Last 7 days (${moment(new Date()).subtract(7, 'd').format("DD MMM")}-${moment(new Date()).format('DD MMM')})`}</label>
                                            </li>
                                            <li className={this.state.selectedField === "LAST30DAYS" ? "active" : ""}
                                                onClick={() => this.onHandleChange('30 DAYS')}>
                                                <label>{`Last 30 days (${moment(new Date()).subtract(30, 'd').format("DD MMM")}-${moment(new Date()).format('DD MMM')})`}</label>
                                            </li>
                                            <li className={this.state.selectedField === "LAST90DAYS" ? "active" : ""}
                                                onClick={() => this.onHandleChange('90 DAYS')}>
                                                <label>{`Last 90 days (${moment(new Date()).subtract(90, 'd').format("DD MMM")}-${moment(new Date()).format('DD MMM')})`}</label>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li className={this.state.selectedField === "DATE_RANGE" ? "active" : ""}
                                                onClick={() => this.setState({selectedField: 'SELECT_DATE_RANGE'})}>
                                                <DateRangePicker
                                                    editableDateInputs={true}
                                                    onChange={item => this.setState({rangeDateValue: [item.selection]})}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={this.state.rangeDateValue}
                                                />
                                                <Button className="applyBtn" onClick={() => this.handleDateField()}>Apply</Button>
                                            </li>
                                        </ul>
                                    </div>
                                }
                                <div className={'col-md-3'}>
                                    <FormControl>
                                        <InputLabel id="demo-multiple-checkbox-label">Location</InputLabel>
                                        <Select
                                            style={{width:300}}
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={this.state.locationid}
                                            name="locationid"
                                            multiple
                                            input={<Input />}
                                            renderValue={selected => {
                                                return selected
                                                    ? selected.map((item, index) => {
                                                        if (index + 1 === selected.length)
                                                            return item && item.locationname ? item.locationname : "";
                                                        else {
                                                            return item && item.locationname
                                                                ? item.locationname.concat(", ")
                                                                : "";
                                                        }
                                                    })
                                                    : "";
                                            }}
                                        >
                                            {this.state.locationData && this.state.locationData.length
                                                ? this.state.locationData.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.name}>
                                                            <Checkbox
                                                                checked={
                                                                    this.state.locationid
                                                                        .map(item1 => {
                                                                            return item1 ? item1.id : 0;
                                                                        })
                                                                        .indexOf(item.id) > -1
                                                                }
                                                                onChange={e => this.handleCheck(e, item)} />
                                                            <ListItemText primary={item.locationname} />
                                                            {/*{item.name}*/}
                                                        </MenuItem>
                                                    );
                                                })
                                                :  <MenuItem value={""}>
                                                    Select
                                                </MenuItem>}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="downloadIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                                         data-icon="file-excel" className="svg-inline--fa fa-file-excel fa-w-12" role="img"
                                         viewBox="0 0 384 512"
                                    >
                                        <path fill="currentColor"
                                              d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"/>
                                    </svg>
                                    <span onClick={()=>exportData(this.state.dateExcel || [], "Analytic")} style={{cursor:"pointer"}}>Download To CSV</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <h3>{this.state.dataset && this.state.dataset.reduce((prev,next ) => prev + next )} <span>OF PAGE VIEWS FOR SELECTED PERIOD</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.state.loader ?
                                <Loader/>
                                :
                                <Bar
                                    data={{
                                        labels: this.state.labels,
                                        datasets: [{
                                            label: "USER ANALYTICS",
                                            data: this.state.dataset,
                                            backgroundColor: 'rgba(239,49,37,1)',
                                        }]
                                    }}
                                    options={{
                                        title: {
                                            display: false,
                                        },
                                        legend: {
                                            display: false,
                                        },
                                        maintainAspectRatio: false,
                                        barThickness: 50
                                    }}
                                />
                            }
                        </div>
                    </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sideBarPath: route =>
      dispatch({ type: "Show_Sidebar_Route", payload: route })
  };
};

export default connect(null,mapDispatchToProps)(Analytics);