export const LoginDropDown = [
    {
        name:"SuperAdmin",
        value:"superadmin",
    },
    {
        name:"Admin",
        value:"admin"
    },
    {
        name:"Location Manager",
        value:"location manager",
    },
    ];