import React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import {Button, FormControl, FormHelperText, Input, Select} from "@material-ui/core";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import './Activity.css';
import { API } from '../../service';
import swal from "sweetalert";
import { exportData } from '../../common/downloadxls';
import {Loader} from "../../common/Loader";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRangeFilter: [],
      selectedField: "LAST7DAYS",
      dropDownOpen: false,
      // startDate: moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
      // endDate: moment(new Date()).format('YYYY-MM-DD'),
      startDate:"null",
      endDate:"null",
      rangeHeading: `All`,
      rangeDateValue: [{
        startDate: new Date(),
        endDate: null,
        key: 'selection'
      }],
      locationid:[],
      locArray:[],
      diffDays:90,
      rangeValue: [],
      dateExcel: [],
      loader: true,
      locationID : localStorage.getItem("role") === "LOCATIONMANAGER" ? JSON.parse(localStorage.getItem("locationid")) : []

    }
  }

  UNSAFE_componentWillMount() {
    let newDate = this.getDifferenceDays(90);
    let value = newDate.map(item => {
      return { "createdOn": item, "count": 0 }
    })
    this.setState({ rangeValue: newDate, dateExcel: value })
  }

  componentDidMount() {
    this.props.sideBarPath("/activity");
    this.getActivityData(this.state.startDate, this.state.endDate, this.state.diffDays, this.state.locationID);
    this.getLocationData()
  }
  getLocationData = async () => {
    const test=localStorage.getItem("role") === "LOCATIONMANAGER" ?
        {
          where: {
            order: "locationname desc",
            active: 1,
            'and' : [
              {
                "and": [{ "id": {"inq":JSON.parse(localStorage.getItem('locationid'))} }]
              }
            ]
          }
        } : {
          where: {
            order: "locationname desc",
            active: 1,
          }
        }
    const resData = await API.getLocation(test);
    if(resData?.status === 200){
      this.setState({locationData: resData.data.data.allLocations.Locations});
    }
    else{
      swal({
        icon: "warning",
        title: "Server Error,Try Agian",
        className: "loginSwal",
      });
      this.setState({locationData:[]})
    }
  }

  getActivityData = async (startDate, endDate, diffDays, locationID) => {
    debugger
    this.setState({loader : true})
    if (isNaN(diffDays) === false) {
      let activityData = new Array(diffDays).fill(0);
      const resData = await API.getActivityData({
        "startDate": startDate || moment(startDate).format('YYYY-MM-DD'),
        "endDate":  endDate || moment(endDate).format('YYYY-MM-DD'),
        "locationId": locationID
      });
      if (resData?.status === 200 && resData?.data?.data?.OrderAllActivityData?.status === 200) {
        let data = resData?.data?.data?.OrderAllActivityData?.data.map((item, index) => {
          this.state.rangeValue.forEach((newData, newIndex) => {
            if (newData === moment(item.date).format('DD.MM.YYYY')) {
              activityData[newIndex] = item.value
            }
          })
          this.state.dateExcel.forEach((valueData, valueIndex) => {
            if (valueData.createdOn === moment(item.date).format('DD.MM.YYYY')) {
              valueData.count = item.value;
            }
          })
        });
        this.setState({ dateRangeFilter: activityData, loader: false })
      }
      else {
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
        });
        this.setState({ dateRangeFilter: [], loader: false })
      }
    } 
  }

  getDifferenceDays = (noOfDays) => {
    var dates = [];
    for (let I = 0; I < Math.abs(noOfDays); I++) {
      dates.push(moment(new Date(new Date().getTime() - ((noOfDays >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).toLocaleString()).format('DD.MM.YYYY'));
    }
    return dates.reverse();
  }

  getDifferenceValueDays = (endDate, noOfDays) => {
    var dates = [];
    for (let I = 0; I < Math.abs(noOfDays); I++) {
      dates.push(moment(new Date(endDate.getTime() - ((noOfDays >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).toLocaleString()).format('DD.MM.YYYY'));
    }
    return dates.reverse();
  }

  onHandleChange = (name, value) => {
    if(value === "All"){
      let newDate = this.getDifferenceDays(90);
      let value = newDate.map(item => {
        return { "createdOn": item, "count": 0 }
      })
      this.setState({ rangeValue: newDate, dateExcel: value })
      this.setState({ rangeHeading : "All", dropDownOpen: !this.state.dropDownOpen})
      this.getActivityData("null", "null", 90, this.state.locArray);
    }
    else if(value === 'LAST7DAYS') {
      let newDate = this.getDifferenceDays(7);
      let value = newDate.map(item => {
        return { "createdOn": item, "count": 0 }
      })
      this.setState({ rangeValue: newDate, dateExcel: value })
      this.setState({ rangeHeading: `Last 7 days (${moment(new Date()).subtract(7, 'd').format("DD MMM")}-${moment(new Date()).format('DD MMM')})` })
      this.setState({ startDate: moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD'), [name]: value, dropDownOpen: !this.state.dropDownOpen })
      this.getActivityData(
          moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
          moment(new Date()).format('YYYY-MM-DD'), 7, this.state.locArray);
    }
    else if(value === 'LAST30DAYS') {
      let newDate = this.getDifferenceDays(30);
      let value = newDate.map(item => {
        return { "createdOn": item, "count": 0 }
      })
      this.setState({ rangeValue: newDate, dateExcel: value })
      this.setState({ rangeHeading: `Last 30 days (${moment(new Date()).subtract(30, 'd').format("DD MMM")}-${moment(new Date()).format('DD MMM')})` })
      this.setState({ startDate: moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD'), [name]: value, dropDownOpen: !this.state.dropDownOpen })
      this.getActivityData(
          moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'),
          moment(new Date()).format('YYYY-MM-DD'), 30,
          this.state.locArray);
    }
    else if(value === 'LAST90DAYS') {
      let newDate = this.getDifferenceDays(90);
      let value = newDate.map(item => {
        return { "createdOn": item, "count": 0 }
      })
      this.setState({ rangeValue: newDate, dateExcel: value })
      this.setState({ rangeHeading: `Last 90 days (${moment(new Date()).subtract(90, 'd').format("DD MMM")}-${moment(new Date()).format('DD MMM')})` })
      this.setState({ startDate: moment(new Date()).subtract(90, 'd').format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD'), [name]: value, dropDownOpen: !this.state.dropDownOpen })
      this.getActivityData(
          moment(new Date()).subtract(90, 'd').format('YYYY-MM-DD'),
          moment(new Date()).format('YYYY-MM-DD'), 90,
          this.state.locArray);
    }
  }

  dropDownClick = () => {
    this.setState({ dropDownOpen: !this.state.dropDownOpen })
  }

  handleDateField = () => {
    let diffDays = moment(this.state.rangeDateValue[0].endDate).diff(moment(this.state.rangeDateValue[0].startDate), 'days');
    let newDate = this.getDifferenceValueDays(this.state.rangeDateValue[0].endDate, diffDays);
    let value = newDate.map(item => {
      return { "createdOn": item, "count": 0 }
    })
    this.setState({ rangeValue: newDate, dateExcel: value, rangeHeading: `Last ${diffDays} days (${moment(this.state.rangeDateValue[0].startDate).format("DD MMM")}-${moment(this.state.rangeDateValue[0].endDate).format("DD MMM")})` })
    this.setState({ dropDownOpen: !this.state.dropDownOpen, startDate: moment(this.state.rangeDateValue[0].startDate).format('YYYY-MM-DD'), endDate: moment(this.state.rangeDateValue[0].endDate).format('YYYY-MM-DD') })
    this.getActivityData(moment(this.state.rangeDateValue[0].startDate).format('YYYY-MM-DD'), moment(this.state.rangeDateValue[0].endDate).format('YYYY-MM-DD'), diffDays, this.state.locArray);
    this.setState({startDate : moment(this.state.rangeDateValue[0].startDate).format('YYYY-MM-DD'), endDate : moment(this.state.rangeDateValue[0].endDate).format('YYYY-MM-DD')})
  }
  handleCheck = async (event, e) => {
    debugger
    if(event.target.checked) {
      const locationid = [...this.state.locationid],
          locArray= [...this.state.locArray];
      locArray.push(e.id)
      locationid.push(e);
      await this.getActivityData(this.state.startDate, this.state.endDate, this.state.diffDays , locArray)
      this.setState({ locationid, locArray });
    } else {
      const temp = [...this.state.locationid];
      const locationid = temp.filter(item => {
        return item ? item.id !== e.id : "";
      });
      const loctemp = [...this.state.locArray],
            locArray = loctemp.filter(el => el !== e.id)
      await this.getActivityData(this.state.startDate, this.state.endDate, this.state.diffDays, locArray)
      this.setState({ locationid, locArray });
    }
  };
  render() {
    return (
      <div className="activitySection">
        <h3>Notification Data</h3>
        {this.state.loader ? <Loader /> : 
          <div>
          <div className="selectDropBox">
             <div className="customDateRange">
                            <div className="dateInner">
                                <div className="dateField">{this.state.rangeHeading}</div>
                                <button onClick={()=>this.dropDownClick()}></button>
                            </div>
                            {
                                this.state.dropDownOpen &&
                                <div className="dropDown">
                                    <ul>
                                      <li className = {this.state.selectedField === "All" ? "active" : ""} onClick={()=>this.onHandleChange('selectedField', 'All')}><label>{`All`}</label></li>
                                      <li className = {this.state.selectedField === "LAST7DAYS" ? "active" : ""} onClick={()=>this.onHandleChange('selectedField', 'LAST7DAYS')}><label>{`Last 7 days (${moment(new Date()).subtract(7,'d').format("DD MMM")}-${moment(new Date()).format('DD MMM')})`}</label></li>
                                        <li className = {this.state.selectedField === "LAST30DAYS" ? "active" : ""} onClick={()=>this.onHandleChange('selectedField', 'LAST30DAYS')}><label>{`Last 30 days (${moment(new Date()).subtract(30,'d').format("DD MMM")}-${moment(new Date()).format('DD MMM')})`}</label></li>
                                      <li className = {this.state.selectedField === "LAST90DAYS" ? "active" : ""} onClick={()=>this.onHandleChange('selectedField', 'LAST90DAYS')}><label>{`Last 90 days (${moment(new Date()).subtract(90,'d').format("DD MMM")}-${moment(new Date()).format('DD MMM')})`}</label></li>
                                    </ul>
                                    <ul>
                                        <li className = {this.state.selectedField === "DATE_RANGE" ? "active" : ""} onClick={()=>this.setState({selectedField:'SELECT_DATE_RANGE'})}>
                                        <DateRangePicker
                                            editableDateInputs={true}
                                            onChange={item => this.setState({rangeDateValue: [item.selection]})}
                                            moveRangeOnFirstSelection={false}
                                            ranges={this.state.rangeDateValue}
                                        />
                                        <Button className = "applyBtn" onClick={()=>this.handleDateField()} disabled={this.state.rangeDateValue[0].endDate ? false : true}>Apply</Button>
                                             </li>
                                    </ul>
                                </div>
                            }
               <div className={'col-md-3'}>
                 <FormControl>
                   <InputLabel id="demo-multiple-checkbox-label">Location</InputLabel>
                   <Select
                       style={{width:300}}
                       labelId="demo-multiple-checkbox-label"
                       id="demo-multiple-checkbox"
                       value={this.state.locationid}
                       name="locationid"
                       multiple
                       input={<Input />}
                       renderValue={selected => {
                         return selected
                             ? selected.map((item, index) => {
                               if (index + 1 === selected.length)
                                 return item && item.locationname ? item.locationname : "";
                               else {
                                 return item && item.locationname
                                     ? item.locationname.concat(", ")
                                     : "";
                               }
                             })
                             : "";
                       }}
                   >
                     {this.state.locationData && this.state.locationData.length
                         ? this.state.locationData.map((item, index) => {
                           return (
                               <MenuItem key={index} value={item.name}>
                                 <Checkbox
                                     checked={
                                       this.state.locationid
                                           .map(item1 => {
                                             return item1 ? item1.id : 0;
                                           })
                                           .indexOf(item.id) > -1
                                     }
                                     onChange={e => this.handleCheck(e, item)} />
                                 <ListItemText primary={item.locationname} />
                                 {/*{item.name}*/}
                               </MenuItem>
                           );
                         })
                         :  <MenuItem value={""}>
                           Select
                         </MenuItem>}
                   </Select>
                 </FormControl>
               </div>
                            <div className="downloadIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-excel" class="svg-inline--fa fa-file-excel fa-w-12" role="img" viewBox="0 0 384 512"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"/></svg>
                              <span className="downloadExcel" onClick={()=>exportData(this.state.dateExcel || [], "Activity")}>Download To CSV</span>
                            </div>
                        </div>

        </div>
        <div>
          <Line
            data={{
              labels: this.state.rangeValue,
              datasets: [
                {
                  fill: true,
                  lineTension: 0.5,
                  backgroundColor: 'rgb(247, 138, 55)',
                  borderColor: 'rgb(247, 138, 55)',
                  borderWidth: 1,
                  data: this.state.dateRangeFilter
                }
              ]
            }}
            width={500}
            height={"auto"}
            options={{
              title: {
                display: false
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              tooltips: {
                enabled: false
              },
              maintainAspectRatio: true,
            }}
          />
        </div>
            </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sideBarPath: route =>
      dispatch({ type: "Show_Sidebar_Route", payload: route })
  };
};

export default connect(null, mapDispatchToProps)(Activity);
