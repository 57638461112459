import React, { Component } from "react";
import "./notification.css";
import Reuse from "../../assets/reuse.png";
import { connect } from "react-redux";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import "../../common/style/style.css";
import { API } from "../../service";
import { Loader } from "../../common/Loader";
import moment from 'moment'

class ViewNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      filter: {
        order: "id desc",
      },
      offset: 0,
      limit: 10,
      currentPage: 0,
      count: 0,
      loader: true,
    };
  }
  componentDidMount() {
    this.props.sideBarPath("/viewNotification");
    this.getNotificationData(this.state.offset, this.state.limit);
  }

  getNotificationData = async (offset, limit) => {
    const locationid = JSON.parse(localStorage.getItem("locationid"));
    const test = {
      // where: {order: "id desc"},
      // first:offset || null,
      // last:perPage || null
      locationId: locationid && locationid.length ? locationid : [],
      offset: offset,
      limit: limit,
    };
    // this.setState({loader : true})
    const resData = await API.getNotificationRest(test);
    if (resData?.status === 200) {
      // let notificationdata = resData.data.data.allNotifications && resData.data.data.allNotifications.Notifications && resData.data.data.allNotifications.Notifications.length ? resData.data.data.allNotifications.Notifications.map(item=>{
      //     item["recurrencename"] = item.notificationrecurrenceidmaprel && item.notificationrecurrenceidmaprel.Recurrences && item.notificationrecurrenceidmaprel.Recurrences.length
      //                               ? item.notificationrecurrenceidmaprel.Recurrences[0].name : "";
      //     item["targetname"] = item.notificationtargetmsgsendidmaprel && item.notificationtargetmsgsendidmaprel.Targetuserforsentthenotificatons && item.notificationtargetmsgsendidmaprel.Targetuserforsentthenotificatons.length
      //     ? item.notificationtargetmsgsendidmaprel.Targetuserforsentthenotificatons[0].name : "";
      //     return item;
      // }) : [];
      //for totalCount
      //  const total =  resData?.data?.data?.allNotifications?.totalCount || 0
      // this.setState({listData: notificationdata, count: total, loader : false});
      let activeNot = [],
          expireNot = [],
          totalNot = resData?.data?.response?.data
      if(totalNot.length > 0){
        totalNot.map((not, indx)=>{
          if(moment(not.startdate).isSame(moment(new Date()).format('YYYY-MM-DD')) ||
              moment(not.startdate).isAfter(moment(new Date()).format('YYYY-MM-DD'))
          ){
            activeNot.push(not)
          }else{
            expireNot.push(not)
          }
        })
      }
      this.setState({
        listData: activeNot,
        expireNot: expireNot,
        loader: false,
        count: resData?.data?.response?.totalcount,
      });
    } else {
      swal({
        icon: "warning",
        title: "Server Error,Try Agian",
        className: "loginSwal",
      });
      this.setState({ listData: [], count: 0, loader: false });
    }
  };

  handleDelete = async (item, type) => {
    const resDel = await swal({
      title: `Are you sure you want to ${
        type === "del" ? "delete" : "Reuse"
      } this record?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (resDel) {
      const test = {
        obj: {
          id: item.id,
          active: 0,
        },
      };
      type === "reuse" && (test.obj["active"] = 1);
      const res = await API.saveNotification(test);
      if (res && res?.status === 200) {
        const id = res?.data?.data?.saveNotification.id;
        swal({
          title: type === "del" ? "Deleted Successfully" : "Reuse Successfully",
          icon: "success",
        });
        this.getNotificationData(this.state.offset, this.state.limit);
      } else {
        swal({
          title: "Server Error,Try Again,Error saving",
          icon: "error",
        });
      }
    }
  };
  //func for edit notification
  editNotification = (id) => {
    this.props.history.push({
      pathname: "/editNotification",
      state: {
        id: id,
      },
    });
  };
  //func for pagination showing 10 notification per page
  handlePagination = async (page_num) => {
    // setCurrentPage(page_num)
    const limit = 10,
        offset_num =  (limit * (page_num - 1) )
    // setOffset(offset_num)
    this.setState({
      limit: limit,
      offset:offset_num,
    });
    this.getNotificationData(offset_num, limit);
  };
  render() {
    const listData = this.state.listData,
          expireNot = this.state.expireNot
    return (
      <div className="viewNotification">
        <h3>View Notification</h3>
        <h4>Future Notification</h4>
        {this.state.loader ? (
          <Loader />
        ) : (
          <>
            <table className="table">
              <tr>
                <th>
                  <span>S No.</span>
                </th>
                <th>
                  <span>Message</span>
                </th>
                <th>
                  <span>Scheduled Date</span>
                </th>
                <th>
                  <span>Scheduled Time</span>
                </th>
                {/*<th>*/}
                {/*  <span>Recurrence ID</span>*/}
                {/*</th>*/}
                {/*<th>*/}
                {/*  <span>Target ID</span>*/}
                {/*</th>*/}
                <th>
                  <span>Status</span>
                </th>
                <th>
                  <span>Edit</span>
                </th>
                <th>
                  <span>Delete</span>
                </th>
                <th>
                  <span>Reuse</span>
                </th>
              </tr>
              {listData?.length > 0 ? (
                listData?.map((ed, ex) => {
                  return (
                    <tr key={ex}>
                      <td>{ed?.id ?? "N/A"}</td>
                      <td>{ed?.notificationmsg ?? "N/A"}</td>
                      <td>{moment(ed?.startdate).format('MM/DD/YYYY') ?? "N/A"}</td>
                      <td>{ed?.starttime ?? "N/A"}</td>
                      {/*<td>{ed?.recurrenceid ?? "N/A"}</td>*/}
                      {/*<td>{ed?.targetmsgsendId ?? "N/A"}</td>*/}
                      <td>
                        <span
                          style={
                            ed?.active ? { color: "green" } : { color: "red" }
                          }
                        >
                          {ed?.active ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <span onClick={() => this.editNotification(ed?.id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                          </svg>
                        </span>
                      </td>
                      <td
                        className="deleteIcon"
                        // onClick={() => this.handleDelete(ed, "del")}
                      >
                        {ed?.active ?
                            <svg
                                onClick={() => this.handleDelete(ed, "del")}
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                            </svg>
                            :
                            <span style={{color:"red"}}>Deleted</span>
                        }
                      </td>
                      <td>
                        <img
                          src={Reuse}
                          alt=""
                          onClick={() => this.handleDelete(ed, "reuse")}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div
                  style={{
                    paddingLeft: "10px",
                    fontSize: "15px",
                    fontWeight: "700",
                  }}
                >
                  No Data Found
                </div>
              )}
            </table>
            <h4>Past Notification</h4>
            <table className="table">
              <tr>
                <th>
                  <span>S No.</span>
                </th>
                <th>
                  <span>Message</span>
                </th>
                <th>
                  <span>Scheduled Date</span>
                </th>
                <th>
                  <span>Scheduled Time</span>
                </th>
                {/*<th>*/}
                {/*  <span>Recurrence ID</span>*/}
                {/*</th>*/}
                {/*<th>*/}
                {/*  <span>Target ID</span>*/}
                {/*</th>*/}
                <th>
                  <span>Status</span>
                </th>
                <th>
                  <span>Edit</span>
                </th>
                <th>
                  <span>Delete</span>
                </th>
                <th>
                  <span>Reuse</span>
                </th>
              </tr>
              {expireNot && expireNot?.length > 0 ? (
                  expireNot?.map((ed, ex) => {
                    return (
                        <tr key={ex}>
                          <td>{ed?.id ?? "N/A"}</td>
                          <td>{ed?.notificationmsg ?? "N/A"}</td>
                          <td>{moment(ed?.startdate).format('MM/DD/YYYY') ?? "N/A"}</td>
                          <td>{ed?.starttime  ?? "null"}</td>
                          {/*<td>{ed?.recurrenceid ?? "N/A"}</td>*/}
                          {/*<td>{ed?.targetmsgsendId ?? "N/A"}</td>*/}
                          <td>
                        <span
                            style={
                              ed?.active ? { color: "green" } : { color: "red" }
                            }
                        >
                          {ed?.active ? "Active" : "InActive"}
                        </span>
                          </td>
                          <td>
                        <span onClick={() =>this.editNotification(ed?.id)}>
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                          </svg>
                        </span>
                          </td>
                          <td
                              className="deleteIcon"
                          >
                            {ed?.active ?
                            <svg
                                onClick={() => this.handleDelete(ed, "del")}
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                            </svg>
                                :
                                <span style={{color:"red"}}>Deleted</span>
                            }
                          </td>
                          <td>
                            <img
                                src={Reuse}
                                alt=""
                                onClick={() => this.handleDelete(ed, "reuse")}
                            />
                          </td>
                        </tr>
                    );
                  })
              ) : (
                  <div
                      style={{
                        paddingLeft: "10px",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                  >
                    No Data Found
                  </div>
              )}
            </table>
            <div className={"row"}>
              <div className={'col-md-7'}>
              <div className="newPagination" style={{ textAlign: "center" }}>
                <Pagination
                  innerClass="pagination pagination-sm"
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.currentPage}
                  activeClass={"active"}
                  itemsCountPerPage={this.state.perPage}
                  totalItemsCount={this.state.count}
                  pageRangeDisplayed={5}
                  onChange={this.handlePagination}
                />
              </div>
                </div>
              <div className={'col-md-5'}>
                <span style={{float:"right", color:"#ef3125"}}>
                                   {`Showing ${this.state.count || 0} Locations (10 PerPage) `}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sideBarPath: (route) =>
      dispatch({ type: "Show_Sidebar_Route", payload: route }),
  };
};

export default connect(null, mapDispatchToProps)(ViewNotification);
