//Query for get all user data based on user id
export const getUserDetails = (id) =>`query{
    allUserdata(where:{id:${id}}){
      Userdata{
      userlocationmappingUseridMap{
        Userlocationmappings{
          userlocationmappinglocationidmaprel{
            Locations{
              locationname
            }
          }
        }
      }
        id
        active
        firstname
        lastname
        email
        phonenumber
        role
        userdatalocationidmaprel{
          Locations{
            id
            locationname
          }
        }
      }
    }
  }
  `;