import React, { Component } from "react";
import { Button, TextField, Link, Grid, Container } from "@material-ui/core";
import { API_URL } from "../Config";
import { Dialog} from "@material-ui/core";
import "./login.css";
import swal from "sweetalert";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { withStyles} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {API} from "../service";


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    // padding: theme.spacing(2),
  }
}))(MuiDialogContent);


class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      loading: false,
      openModal: false,
      data: {
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: ""
      },
      errors: {
        otp1: false,
        otp2: false,
        otp3: false,
        otp4: false,
        otp5: false,
        otp6: false
      },
      focus: {
        otp1: React.createRef(),
        otp2: React.createRef(),
        otp3: React.createRef(),
        otp4: React.createRef(),
        otp5: React.createRef(),
        otp6: React.createRef()
      },
    };
  }

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  handleChangeOtp = e => {
    const inputArr = ["otp1", "otp2", "otp3", "otp4", "otp5", "otp6"];
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
    if (this.validateTextField(e.target.value)) {
      errors[e.target.name] = true;
      this.setState({ errors });
    } else {
      errors[e.target.name] = false;
      this.setState({ errors });
      if (inputArr.indexOf(e.target.name) < 5) {
        const nextInput = inputArr[inputArr.indexOf(e.target.name) + 1];
        const focus = { ...this.state.focus };
        focus[nextInput].current.focus();
      }
    }
  };

  validateTextField = data => {
    if (!data || data === "") {
      return true;
    }
    return false;
  };

  validateForm = email => {
    const validEmailRegex = RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return validEmailRegex.test(email.trim()) ? true : false;
  };

  onSubmit = async e => {
    e.preventDefault();
    if (this.state.email === "") {
      swal({
        icon: "warning",
        title: "Please enter your registered Email address",
        className: "loginSwal"
      });
    } else {
      if (this.validateForm(this.state.email)){
        const body ={
          "emailId" : this.state.email
        }
         const res = await API.forgotPassword(body)
          if(res?.status === 200){
            this.setState({connectionError: ""});
            if(res?.data?.response?.status === 200){
              swal({
                    icon: "success",
                    title: res?.data?.response.msg
                   }).then(()=>{
                       this.setState({openModal: true})
                });
            }
            else {
                swal({
                      icon: "warning",
                      title: res?.data?.response.msg
                      });
                    }
          }else{
            this.setState({
              connectionError: e.toString().substring(11, e.length),
            });
          }
      } else {
        swal({
          icon: "warning",
          title: "Invalid Email-ID "
        });
      }
    }
  };

  handleClose = () => {
    this.setState({openModal: !this.state.openModal})
  }

  
  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  convertEmail = (email) => {
    var a = email.split("@"); 
    var b = a[0];
    var newstr = "";
    for(var i in b){
     if(i>2 && i<b.length)newstr += "*";
     else newstr += b[i];
    }
    return newstr +"@"+ a[1];
  }

  validateField = () => {
    let data = this.state.data;
    return isNaN(parseInt(data.otp1)) ||
      isNaN(parseInt(data.otp2)) || isNaN(parseInt(data.otp3)) ||
      isNaN(parseInt(data.otp4)) || isNaN(parseInt(data.otp5)) || isNaN(parseInt(data.otp6)) ? false : true;
  }

  continueBtn = async () => {
    if(this.validateField()){
          const body = {
            "emailId" : this.state.email,
            "passcode" : `${this.state.data.otp1}${this.state.data.otp2}${this.state.data.otp3}${this.state.data.otp4}${this.state.data.otp5}${this.state.data.otp6}`
          }
      const res = await API.matchPassword(body)
        if(res){
          this.setState({connectionError: ""});
          if (res.response.status === 200) {
            swal({
              icon: "success",
              title: res.response.msg
            }).then(() => {
              localStorage.setItem("resetEmail", this.state.email)
              this.props.history.push({
                pathname: "/resetPassword",
                state: {email: this.state.email},
              });
            });
          } else {
            swal({
              icon: "warning",
              title: res.response.msg
            });
          }
        }else{
          //$
          swal({
            icon: "warning",
            title: "Server Error"
          });
          // this.setState({
          //   connectionError: res.toString().substring(11, res.length),
          // });
        }
    }
    else{
      swal({
        icon: "warning",
        title: "Please Enter Valid Passcode"
      });
    }
  }

  render() {
    return (
      <div className="login loginFormOuterContainer">
        <div className="loginFromContainer">
          <Container className="loginContainerwhite">
            <div>
              <div className="logo">
                {/* <img src={logo} alt="Chanticlear"></img> */}
              </div>
              <div className="forgotMidSection">
                <h4>Forgot Your Password</h4>
                <span>
                 You"ll recieve a system generated link on your registered email address containing a 'Reset Password Link' follow the instruction there to reset your password.
                </span>
              </div>
              <form noValidate onSubmit={this.onSubmit}>
                <TextField
                  value={this.state.email}
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  autoComplete="email"
                  autoFocus
                  onChange={this.handleChange}
                />
                {this.state.connectionError ? (
                  <div className="connectionError">
                    <p>{this.state.connectionError}</p>
                  </div>
                ) : (
                  ""
                )}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Send Passcode
                </Button>
                <Grid container className="backtologin">
                <Grid item xs>
                  <span> Don't have an account?</span>
                  <Link href="/" variant="body2">
                    Sign In
                  </Link>
                </Grid>
              </Grid>
              </form>
            </div>
          </Container>
          <Dialog
          className="modalDialogPasscode"
          // onClose={()=>this.handleClose()}
          aria-labelledby="customized-dialog-title"
          open={this.state.openModal}
        >
            <DialogTitle id="customized-dialog-title" onClose={()=>this.handleClose()}>
              <div className="ModalTitle">Enter Passcode</div>
            </DialogTitle>
            <DialogContent dividers>
              <div className="passcodeModal">
                <div>
                  <span className = "emailAddress">{`An Email with an Passcode has been sent to your registered email address ${this.convertEmail(this.state.email)}`}</span>
                </div>
                <div>
                <ul>
              <li>
                <TextField
                  value={this.state.data["otp1"]}
                  variant="outlined"
                  margin="normal"
                  id="otp1"
                  name="otp1"
                  onChange={this.handleChangeOtp}
                  error={this.state.errors["otp1"]}
                  inputProps={{ maxLength: 1 }}
                  // onInput={this.validateNumber}
                  inputRef={this.state.focus.otp1}
                />
              </li>
              <li>
                <TextField
                  value={this.state.data["otp2"]}
                  variant="outlined"
                  margin="normal"
                  id="otp2"
                  name="otp2"
                  onChange={this.handleChangeOtp}
                  error={this.state.errors["otp2"]}
                  inputProps={{ maxLength: 1 }}
                  // onInput={this.validateNumber}
                  inputRef={this.state.focus.otp2}
                />
              </li>
              <li>
                <TextField
                  value={this.state.data["otp3"]}
                  variant="outlined"
                  margin="normal"
                  id="otp3"
                  name="otp3"
                  onChange={this.handleChangeOtp}
                  error={this.state.errors["otp3"]}
                  inputProps={{ maxLength: 1 }}
                  // onInput={this.validateNumber}
                  inputRef={this.state.focus.otp3}
                />
              </li>
              <li>
                <TextField
                  value={this.state.data["otp4"]}
                  variant="outlined"
                  margin="normal"
                  id="otp4"
                  name="otp4"
                  onChange={this.handleChangeOtp}
                  error={this.state.errors["otp4"]}
                  inputProps={{ maxLength: 1 }}
                  // onInput={this.validateNumber}
                  inputRef={this.state.focus.otp4}
                />
              </li>
              <li>
                {" "}
                <TextField
                  value={this.state.data["otp5"]}
                  variant="outlined"
                  margin="normal"
                  id="otp5"
                  name="otp5"
                  onChange={this.handleChangeOtp}
                  error={this.state.errors["otp5"]}
                  inputProps={{ maxLength: 1 }}
                  // onInput={this.validateNumber}
                  inputRef={this.state.focus.otp5}
                />
              </li>
              <li>
                <TextField
                  value={this.state.data["otp6"]}
                  variant="outlined"
                  margin="normal"
                  id="otp6"
                  name="otp6"
                  onChange={this.handleChangeOtp}
                  error={this.state.errors["otp6"]}
                  inputProps={{ maxLength: 1 }}
                  // onInput={this.validateNumber}
                  inputRef={this.state.focus.otp6}
                />
              </li>
            </ul>
                </div>
              </div>
            </DialogContent>
            <div style={{textAlign:"right"}}> 
              <Button onClick={()=>this.continueBtn()} className="continueBtn">
                Continue
              </Button>
            </div>
        </Dialog>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
