export const getCouponQuery = `
query allCoupons($where:JSON){
  allCoupons(where:$where){
    totalCount
    Coupons{
      id
      active
      couponname
      description
      couponstitle
      categoryid
      couponcategoryidmaprel{
        Couponcategorymasters{
          categoryname
        }
      }
      couponlocationmappingCouponidMap(where:{active : 1}){
        Couponlocationmappings{
        active
         couponlocationmappinglocationidmaprel{
            Locations{
              locationname
            }
          }
          id
          couponid
          locationid
        }
      }
      couponcategoryidmaprel{
        Couponcategorymasters{
          id
          categoryname
        }
      }
      recentamount
      discountedamount
      discountedpercentage
      startdate
      enddate
      locationid
      couponlocationidmaprel{
        Locations{
          locationname
          id
        }
      }
    }
  }
}
`;

export const saveCouponAPI = `
mutation saveCoupon($obj: CouponInput!){
    saveCoupon(obj: $obj){
      id
    }
  }
`;

export const saveCouponMapAPI = `
mutation saveCouponlocationmapping($obj:CouponlocationmappingInput!){
  saveCouponlocationmapping(obj: $obj){
    id
  }
}
`;

export const getCouponById = (id) => `
query allCoupons{
    allCoupons(where:{id:${id},active:1}){
      Coupons{
        id
        active
        couponname
        description
        couponstitle
        couponlogo
        categoryid
        recentamount
        discountedamount
        discountedpercentage
        startdate
        enddate
        starttime
        endtime
        locationid
        couponlocationmappingCouponidMap(where: {active:1}){
          Couponlocationmappings{
            id
            couponid
            locationid
            couponlocationmappinglocationidmaprel{
              Locations{
                id
                locationname
              }
            }
          }
        }
        couponlocationidmaprel{
          Locations{
            locationname
            id
          }
        }
      }
    }
  }
`;