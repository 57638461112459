
import React, { useEffect, useRef, useState } from "react";
import { Search, GpsFixed } from "@material-ui/icons";
// import "./autoCompleteCitySearch.css";
import { apiKey, geocodeJson, mapApiJs, ENV_Key } from "../Config";
// const ENV_Key = "AIzaSyD4pmDReyz6px3lTCv1S-p9HZU1ccDg7-I&v=3.exp";
// const mapApiJs = "https://maps.googleapis.com/maps/api/js";
// const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function loadAsyncScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src,
        });
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    });
}

const extractAddress = (place, palceLatLong) => {
    const address = {
        placeLat: palceLatLong?.lat,
        placeLng: palceLatLong?.long,
        streetNumber: "",
        route: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        plain() {
            const city = this.city ? this.city + ", " : "";
            const zip = this.zip ? this.zip + ", " : "";
            const state = this.state ? this.state + ", " : "";
            return city + zip + state + this.country;
        },
        fullAddress() {
            const streetNumber = this.streetNumber ? this.streetNumber + " " : "";
            const route = this.route ? this.route + ", " : "";
            const street = this.street ? this.street + ", " : "";
            const city = this.city ? this.city + ", " : "";
            const zip = this.zip ? this.zip + ", " : "";
            const state = this.state ? this.state + ", " : "";
            return streetNumber + route + street + city + zip + state + this.country;
        },
    };

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach((component) => {
        const types = component.types;
        const value = component.long_name;
        if (types.includes("street_number")) {
            address.streetNumber = value;
        }
        if (types.includes("route")) {
            address.route = value;
        }
        if (types.includes("sublocality")) {
            address.street = value;
        }
        if (types.includes("locality")) {
            address.city = value;
        }
        if (types.includes("locality")) {
            address.city = value;
        }
        const short = component.short_name;

        if (types.includes("administrative_area_level_1")) {
            address.state = value;
            address.state = short;
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = value;
        }
    });

    return address;
};

function AutoCompleteCitySearch({addressDetails, cityName, disabled, idx, value, isEdit, isReset}) {
    const searchInput = useRef(null);
    const [address, setAddress] = useState(null);
    const [latlong, setLatLong] = useState({});
    const [isActive, setIsActive] = useState(false);
    const [googleAddress, setGoogleAddress] = useState("");
    const [reset, setreset] = useState(false)
    // init gmap script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${ENV_Key}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    };

    // do something on address change
    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        let palceLatLong = {
            lat: place.geometry.location.lat(),
            long: place.geometry.location.lng(),
        };
        setAddress(extractAddress(place, palceLatLong));
        setLatLong(palceLatLong);
    };

    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInput.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(
            searchInput.current
        );
        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () =>
            onChangeAddress(autocomplete)
        );
    };

    const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
        const url = `${geocodeJson}?key=${ENV_Key}&latlng=${lat},${lng}`;
        searchInput.current.value = "Getting your location...";
        fetch(url)
            .then((response) => response.json())
            .then((location) => {
                const place = location.results[0];
                const _address = extractAddress(place);
                setAddress(_address);
                searchInput.current.value = _address.plain();
            });
    };

    const findMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                reverseGeocode(position.coords);
            });
        }
    };

    // load map script after mounted
    useEffect(() => {
        initMapScript().then(() => initAutocomplete());
    }, []);
    useEffect(()=>{
        setIsActive(false)
    },[isReset])

    useEffect(() => {
        if(address){
            addressDetails(address, idx);
        }else {
            setGoogleAddress(value)
            setIsActive(false)
        }
    }, [address, value]);

    return (
        <div className="App">
            <div>
                <div className="search">
          {/*<span className={"searchIcon"}>*/}
          {/*  <Search />*/}
          {/*</span>*/}
                    {isEdit && isActive == false ?
                        <input
                            value={googleAddress}
                            style={{width: "100%"}}
                            ref={searchInput}
                            onClick={(e)=>{setIsActive(true)}}
                            type="text"
                            placeholder="Search location...."
                            disabled={disabled}
                            defaultValue={cityName ? cityName : ""}
                        />
                        :
                    <input
                        style={{width: "100%"}}
                        ref={searchInput}
                        // onClick={(e)=>{setIsActive(true)}}
                        type="text"
                        placeholder="Search location...."
                        disabled={disabled}
                        defaultValue={cityName ? cityName : ""}
                    />
                    }
                        {/*<input*/}
                        {/*    value={googleAddress}*/}
                        {/*    style={{width:"100%"}}*/}
                        {/*    ref={searchInput}*/}
                        {/*    onClick={(e)=>{setIsActive(true)}}*/}
                        {/*    type="text"*/}
                        {/*    placeholder="Search location...."*/}
                        {/*    disabled={disabled}*/}
                        {/*    defaultValue={cityName ? cityName : ""}*/}
                        {/*/>*/}

                    {/*<button className="locationLock" onClick={findMyLocation}>*/}
                    {/*    <GpsFixed />*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    );
}

export default AutoCompleteCitySearch;

