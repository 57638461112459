import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import swal from "sweetalert";
import moment from 'moment'
export const  exportData = (fileData, type) => {
        let filedata = setDataWithHeader(fileData, type);
        let fileName = `${type}_${moment().milliseconds()}`;
        try {
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const ws = XLSX.utils.json_to_sheet(filedata);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } catch (e) {
            swal("Downloading Error", { icon: "error" });
        }
    }
const  setDataWithHeader = (fileData, type) => {
   // console.log("fileData",fileData)
    var filedate = []
    if(type === "Analytic"){
        fileData.length !=0
            ? fileData.map((item, index) => {
                return filedate.push({
                    "Total User Count":item?.totalUserCount || 0,
                    "Total Order Count":item?.totalOrderCount || 0,
                    "Total Coupon count":item?.totalCouponCount || 0
                })
            })
            : filedate.push({
                "Total User Count":"",
                "Total Order Count":"",
                "Total Coupon count":""
            });
    }
    else if(type === 'Activity'){
        fileData.length !=0
        ? fileData.map((item, index) => {
            return filedate.push({
                "Created On":item?.createdOn || 0,
                "Total Notification Count":item?.count || 0,
            })
        })
        : filedate.push({
            "Created On":"",
            "Total Notification Count":""
        });
    }
    else if(type === "userTable"){
        fileData.map((item, index) => {
            return filedate.push({
                "FirstName":item.firstName || "",
                "LastName":item.lastName || "",
                "Mobile Number":item.phoneNumber || "",
                "Type":item.role || "",
                "EmailID":item.email || "",
                "CreatedOn":item.createdOn ? moment(item.createdOn).format('YYYY-MM-DD') : "",
                "Address":item.address || ""
            })
        })
    }
    else if(type === "userEmail"){
        fileData.map((item, index) => {
            return filedate.push({
                "Email Id":item.email|| "",
                "User Status":item.active ? item.active == 1 ? "Active" :"Inactive" : ""
            })
        })
    }
    return filedate
}
