import React, { Component } from 'react';
import "./manageLocation.css";
import swal from "sweetalert";
import gql from "graphql-tag";
import {mutationQueries} from "../../MutationMethod";
import {fetchMethod} from "../../FetchMethod";
import { Button } from '@material-ui/core';
import {ImageUploadURL, ENV_Key} from "../../Config";
import {getLocationDetail} from "./ManageLocationQuery";
import AutoCompleteCitySearch from '../../common/googleAutoComplete'
import { connect } from "react-redux";
import MapContainer from '../../common/googleMap'
import SearchInputFile from "./SearchInputFile"
import Autocomplete from "react-google-autocomplete";
import {API} from "../../service";
import moment from "moment";
import {parse} from "graphql";
class AddManageLocation extends Component{
    constructor(props){
        super(props);
        this.state={
            errorUpdateMsg: {},
            errorMsg: [],
            role:localStorage.getItem('role'),
            loader:false,
            isEdit:false,
            webID:"",
            isReset:0,
            isFileUpload:false,
            addManageLocation: [
                {
                    id: 0,
                    locationname: "",
                    address: "",
                    filename: "",
                    file: null,
                    lat:"",
                    lng :"",
                    meanuurl:"",
                    ordernowurl:"",
                    specialurl:"",
                    facebookurl:"",
                    googlereviewurl:"",
                    phoneno:"",
                    city:"",
                    state:"",
                    zipcode:"",
                    country:"",
                    active:1
                }
            ]
        }
    }

    componentDidMount(){
      if(this.props.location && this.props.location.state){
          this.getWebURLData(this.props.location.state)
          this.getManageLocationData(this.props.location.state);
      }
      this.props.sideBarPath("/");
    }

    getManageLocationData = async (id) => {
        const res = await API.getManageLocationDetail(parseInt(id))
           if(res){
            let data = res.data.allLocations.Locations[0];
            this.setState({addManageLocation: [
              {
                  active:data.active,
                  id: data.id,
                  lat:data.lat,
                  lng:data.lng,
                  city:data.city || "",
                  state:data.state || "",
                  zipcode:data.zipcode || "",
                  country:data.country || "",
                  locationname: data.locationname,
                  googlereviewurl:data.googlereviewurl || "",
                  phoneno:data.phoneno || "",
                  facebookurl:data.facebookurl || "",
                  address: data.locationaddress,
                  filename: data.locationlogo,
                  file: new File(["file"], data.locationlogo, {
                    type: "text/plain",
                  }),
              }
          ]});
          }else{
            swal({
              title: 'Server Error,Try Again',
              icon: "warning"
            });
          };
    }
    //func for calling webURL data
    getWebURLData = async id =>{
        const objWeb ={
            where:{
                active:1,
                locationid: id
            }
        }
      const resWeb = await API.getWebURLByLocationID(objWeb)
        if(resWeb?.status === 200){
            const cloneState = [...this.state.addManageLocation];
                // cloneState[0]['webID'] = resWeb?.data?.data?.allWebviewurls.Webviewurls[0]?.id || ''
                cloneState[0]['meanuurl'] = resWeb?.data?.data?.allWebviewurls.Webviewurls[0]?.meanuurl || ''
                cloneState[0]['ordernowurl'] = resWeb?.data?.data?.allWebviewurls.Webviewurls[0]?.ordernowurl || ''
                cloneState[0]['specialurl'] = resWeb?.data?.data?.allWebviewurls.Webviewurls[0]?.specialurl || ''
           await this.setState({
                addManageLocation : cloneState,
               webID:resWeb?.data?.data?.allWebviewurls.Webviewurls[0]?.id,
               isEdit:true
            })
        }else{
            swal({
                title: "Server Error,Try Again",
                icon: "error"
            })
        }
    }

    onChangeFileHandler = async (e, value) => {
        let file = new File(
            [e.target.files[0]],
            `${Date.now()}.${e.target.files[0].name.split('.')[1]}`,
            {type:e.target.files[0].type}
            );
        const { name } = e.target;
        let { addManageLocation } = this.state;
        addManageLocation[value][name] = file.name;
        addManageLocation[value].file = file;
        this.setState({ addManageLocation , isFileUpload: true});
        this.validateInputArr(name, file, value);
    }

    validateInputArr = (name, value, index) => {
    let { errorMsg } = this.state;
    let tempErr = { ...errorMsg[index] };
    if (name === "locationname") {
        let re = new RegExp(/[a-zA-Z]+([\s][a-zA-Z]+)*/);
      tempErr["locationname"] = !value ? !re.test(value) ? "Please enter location name in alphabets" : "Please enter location name" : "";
    }
     else if(name === "address") {
         tempErr['address'] = value === "" ? "Enter The Address" : ""
        // let re = new RegExp(/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/);
        // tempErr["address"] = !value ? !re.test(value) ? "Please enter valid address" : "Please enter address" : "";
      }
    else if(name === "ordernowurl"){
        tempErr['ordernowurl'] = value === "" ? "Please Enter Order URL" : ""
    }
    else if(name === "meanuurl"){
        tempErr['meanuurl'] = value === "" ? "Please Enter Menu URL" : ""
    }
    else if(name === "specialurl"){
        tempErr['specialurl'] = value === "" ? "Please Enter Special URL" : ""
    }
    else if(name === "filename"){
        tempErr['filename'] = value === "" ? "Please Upload Image" : ""
    }
    else if(name === "googlereviewurl"){
        tempErr['googlereviewurl'] = value === "" ? "Please Enter Google URL" : ""
    }
    else if(name === "facebookurl"){
        tempErr['facebookurl'] = value === "" ? "Please Enter Facebook URL" : ""
    }
    else if(name === "phoneno"){
        tempErr['phoneno'] = value === "" ? "Please Enter Phone Number" : ""
    }
    errorMsg[index] = tempErr;
    this.setState({
      errorMsg,
    });
    }
    formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + '-' + match[2] + '-' + match[3]
        }else{
            return str
        }
    };

    onHandleChange = (event, index, placeID) => {
        let { addManageLocation } = this.state;
        if(placeID === "city"){
            var name = "address",
                value = event.target.value
        }
        else if(placeID === "placeId"){
           var name = 'address',
               value = event.formatted_address,
               lat = event.geometry.location.lat(),
               lng = event.geometry.location.lng()
            addManageLocation[index]['lat'] = lat;
            addManageLocation[index]['lng'] = lng;
        }else if(event.target.name == "phoneno" && event.target.value.length > 15){
            return swal({
                title: 'Phone Number Must Be 10 Digit',
                icon: "warning"
            });
        }
        else if(event.target.name == "phoneno" && event.target.value.length <= 10){
            var   name = event.target.name,
                  value = this.formatPhoneNumber((event.target.value))
            console.log("cloneState====>>>", value)
        }
        else{
            var { name, value } = event.target;
        }
        this.validateInputArr(name, value, index);
        addManageLocation[index][name] = value;
        this.setState({ addManageLocation });
    }

    validateOnSubmit = (data) => {
        let { errorMsg, addManageLocation } = data;
        let finalTempArr = null;
        addManageLocation.map((data, index) => {
          let tempErr = {};
          for (const [name, value] of Object.entries(data)) {
                if (name === "locationname") {
                    let re = new RegExp(/[a-zA-Z]+([\s][a-zA-Z]+)*/);
                  tempErr["locationname"] = value ? !re.test(value.trim()) 
                  ? "Please enter location name in alphabets" : "" : "Please enter location name";
                }
                else if(name === "address") {
                    tempErr['address'] = value === "" ? "Enter The Address" : ""
                    // let re = new RegExp(/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/);
                    // tempErr["address"] = value ? !re.test(value.trim()) ? "Please enter valid address" : "" : "Please enter address";
                  }
               else if(name === "ordernowurl"){
                    tempErr['ordernowurl'] = value === "" ? "Please Enter Order URL" : ""
                }
                else if(name === "meanuurl"){
                    tempErr['meanuurl'] = value === "" ? "Please Enter Menu URL" : ""
                }
                else if(name === "specialurl"){
                    tempErr['specialurl'] = value === "" ? "Please Enter Special URL" : ""
                }
                else if(name === "filename"){
                    tempErr['filename'] = value === "" ? "Please Upload Image" : ""
                }
                else if(name === "googlereviewurl"){
                    tempErr['googlereviewurl'] = value === "" ? "Please Enter Google URL" : ""
                }
                else if(name === "facebookurl"){
                    tempErr['facebookurl'] = value === "" ? "Please Enter Facebook URL" : ""
                }
                else if(name === "phoneno"){
                    tempErr['phoneno'] = value === "" ? "Please Enter Phone Number" : ""
                }
          }
          errorMsg[index] = tempErr;
          finalTempArr = tempErr
        });
        this.setState({
          errorMsg,
        });
        return finalTempArr;
      };

    addMoreLocation = () => {
    const submitError = this.validateOnSubmit(this.state);
    const error = submitError.locationname
      ? submitError.locationname
      : submitError.address
        ? submitError.address
        : "";
    if (!error) {
      let { addManageLocation } = this.state;
      addManageLocation = [
        ...addManageLocation,
        {
          id: 0,
          locationname: "",
          address: "",
          filename: "",
          file: "",
          lat:"",
          lng :"",
          meanuurl:"",
          ordernowurl:"",
          specialurl:"",
        },
      ];
      this.setState({ addManageLocation });
    }
    }

    removeLocationArray = (index) => {
            let { addManageLocation } = this.state;
            addManageLocation.splice(index, 1);
            this.setState({ addManageLocation });
    }

    uploadImage = (item) => {
        let formData = new FormData();
        let name = `${item.file.name}`;
        var newFile = new File([item.file], name, {
          type: item.file.type
        });
    
        formData.append("file", newFile);
        let xhr = new XMLHttpRequest();
    
        xhr.open("POST", ImageUploadURL.uploadFile);
    
        xhr.send(formData);
    
        let fileName = "";
        xhr.onreadystatechange = function() {
          if (xhr.readyState == XMLHttpRequest.DONE && xhr.status === 200) {
            fileName = JSON.parse(xhr.responseText).result.files.file;
          }
        };
    }

    saveBtn = async () => {
        const submitError = this.validateOnSubmit(this.state);
        // const error = submitError.locationname ==="" && submitError.address ==="" && submitError.filename ===""
        if (submitError.locationname ==="" && submitError.address ==="" && submitError.filename ==="") {
            this.setState({ loader: true })
            let query
            this.state.addManageLocation.map(async (item, index) => {
                { this.state.isFileUpload && await this.uploadImage(item) }
                this.props.location && this.props.location.state ? item.id ?
                    query = {
                        obj: {
                            id: item.id,
                            active: parseInt(item.active),
                            locationname: item.locationname,
                            lat: item.lat.toString(),
                            lng : item.lng.toString(),
                            locationaddress: item.address,
                            locationlogo: item.filename,
                            googlereviewurl:item.googlereviewurl,
                            phoneno:item.phoneno,
                            facebookurl:item.facebookurl,
                            city:item.city,
                            state:item.state,
                            zipcode:item.zipcode,
                            country:item.country,
                            createdby: parseFloat(localStorage.getItem("id")),
                            updatedby: parseFloat(localStorage.getItem("id"))
                        }
                    }
                    :
                    query = {
                        obj: {
                            city:item.city,
                            state:item.state,
                            zipcode:item.zipcode,
                            country:item.country,
                            locationname: item.locationname,
                            locationaddress: item.address,
                            locationlogo: item.filename,
                            googlereviewurl:item.googlereviewurl,
                            phoneno:item.phoneno,
                            facebookurl:item.facebookurl,
                            lat: item.lat.toString(),
                            lng : item.lng.toString(),
                            createdby: parseFloat(localStorage.getItem("id")),
                            updatedby: parseFloat(localStorage.getItem("id"))
                        }
                    }
                    :
                    query = {
                        obj: {
                            city:item.city,
                            state:item.state,
                            zipcode:item.zipcode,
                            country:item.country,
                            locationname: item.locationname,
                            locationaddress: item.address,
                            locationlogo: item.filename,
                            googlereviewurl:item.googlereviewurl,
                            phoneno:item.phoneno,
                            facebookurl:item.facebookurl,
                            lat: item.lat.toString(),
                            lng : item.lng.toString(),
                            createdby: parseFloat(localStorage.getItem("id")),
                            updatedby: parseFloat(localStorage.getItem("id"))
                        }
                    }
                    console.log("query image =====>>>>", query)
                const res = await API.saveManageLocation(query)
                if (res) {
                    if (!!this.props.location && !!this.props.location.state) {
                        const locationId = res?.data?.saveLocation?.id,
                            webURL ={
                                obj:{
                                    id:this.state.webID,
                                    active: 1,
                                    createdby: parseFloat(localStorage.getItem("id")),
                                    updatedby: parseFloat(localStorage.getItem("id")),
                                    createdon: moment(new Date()).format('YYYY-MM-DD'),
                                    updatedon: moment(new Date()).format('YYYY-MM-DD'),
                                    locationid: locationId,
                                    meanuurl: item?.meanuurl,
                                    ordernowurl: item?.ordernowurl,
                                    specialurl: item?.specialurl,
                                }
                            }
                        const resWeb = await API.saveWebURLByLocationID(webURL)
                        if(resWeb?.status === 200){
                            swal({
                                title: res.data ? "Updated successfully" : "Error saving",
                                icon: "success"
                            }).then(() => {
                                window.location = "/";
                            });
                        }else{
                            swal({
                                title: 'saveWebURL Server Error,Try Again',
                                icon: "warning"
                            });
                        }
                    } else {
                        const locationId = res?.data?.saveLocation?.id,
                            webURL ={
                                obj:{
                                    active: 1,
                                    createdby: parseFloat(localStorage.getItem("id")),
                                    updatedby: parseFloat(localStorage.getItem("id")),
                                    createdon: moment(new Date()).format('YYYY-MM-DD'),
                                    updatedon: moment(new Date()).format('YYYY-MM-DD'),
                                    locationid: locationId,
                                    meanuurl: item?.meanuurl,
                                    ordernowurl: item?.ordernowurl,
                                    specialurl: item?.specialurl,
                                }
                            }
                        const resWeb = await API.saveWebURLByLocationID(webURL)
                        if(resWeb?.status === 200){
                            swal({
                                title: res.data ? "Saved successfully" : "Error saving",
                                icon: "success"
                            }).then(() => {
                                window.location = "/";
                            });
                        }else{
                            swal({
                                title: 'saveWebURL Server Error,Try Again',
                                icon: "warning"
                            });
                        }
                    }
                } else {
                    swal({
                        title: 'Server Error,Try Again',
                        icon: "warning"
                    });
                    // if (e.message === "Network error: Failed to fetch") {
                    //     swal({
                    //         title: e.message.toString().split(":")[1],
                    //         icon: "warning"
                    //     });
                    // } else if (e.message === 'GraphQL error: {"error":"Not authorized to save data"}') {
                    //     swal({
                    //         title: "Not authorized to save data",
                    //         icon: "error"
                    //     });
                    // } else {
                    //     swal({
                    //         title: e.message.toString().split(":")[2],
                    //         icon: "warning"
                    //     });
                    // }
                }
            })
            this.setState({loader : false})
        }else{
                swal({
                    title: "You Missed Required field",
                    icon: "warning"
                });
        }
    }
    getAddress = (value, idx) => {
             if( typeof value == "object"){
                 debugger
                 const cloneLocation = [...this.state.addManageLocation];
                 cloneLocation[idx].address = `${value.streetNumber || ""} ${value.route || ""}${(value.streetNumber || value.route) && ","}${value.city || "N/A"}, ${value.state || "N/A"}`
                 cloneLocation[idx].lat = value?.placeLat
                 cloneLocation[idx].lng = value?.placeLng
                 cloneLocation[idx].city = value.city || ""
                 cloneLocation[idx].state = value.state || ""
                 cloneLocation[idx].zipcode = value.zip || ""
                 cloneLocation[idx].country = value.country || ""
                 this.setState({addManageLocation : cloneLocation})
                 this.validateInputArr("address",value?.city ?? "" , idx)
             }
    };
    reset = async type =>{
    if (type === "Add") return this.props.history.push('/')
        const cloneState = [...this.state.addManageLocation]
        await this.setState({isReset : this.state.isReset + 1,
            addManageLocation: [
                {
                    id: cloneState[0]?.id,
                    locationname: "",
                    address: "",
                    filename: "",
                    file: null,
                    lat:"",
                    lng :"",
                    meanuurl:"",
                    ordernowurl:"",
                    specialurl:"",
                    active:1,
                    city:"",
                    state:"",
                    zipcode:"",
                    country:"",
                    webID:cloneState[0]?.webID
                }
            ]
        })

    }
    render(){
        return(
            <div className="addManageLocation">
               <h3>Manage Location</h3>
                <div className="item">
                    <div className="addLocation">
                        {this.props.location && this.props.location.state ? <span>Edit Location</span> : <span>Add Location</span>}
                        <div className="addLocationBtn">
                            {/*<span>Add</span>*/}
                            {/*<span onClick={()=>this.addMoreLocation()}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg></span>*/}
                        </div>
                    </div>
                    <div className="inner-boxwrap">
                    {this.state.addManageLocation && this.state.addManageLocation.length ?this.state.addManageLocation.map((item, index)=>{ 
                    return <div className="removeLocationButton">
                        <span>Location
                            {/*{index + 1}*/}
                        </span>
                        <div className="removeButton">
                            {/*<span>Remove</span>*/}
                            {/*<span onClick={()=>{this.state.addManageLocation.length > 1 && this.removeLocationArray(index)}}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg></span>*/}
                        </div>
        </div> }): ""}
                   {this.state.addManageLocation && this.state.addManageLocation.length ? this.state.addManageLocation.map((item,index)=>{
                    var GoogleAddress = this.state.addManageLocation[index].address
                   return index === this.state.addManageLocation.length -1 ?
                       <div className="formBox">
                   <div className="form-group">
                        <label>Location Name</label>
                        <input
                            placeholder={"Enter Location Name"}
                            value={item.locationname}
                            type={"text"}
                            name={"locationname"}
                            onChange={(e)=>this.onHandleChange(e, index)}
                            />
                          <div
                          className="error"
                            style={{
                              textAlign: "start",
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            {this.state.errorMsg[this.state.errorMsg.length - 1]
                              ? this.state.errorMsg[this.state.errorMsg.length - 1].locationname
                                ? this.state.errorMsg[this.state.errorMsg.length - 1].locationname
                                : ""
                              : ""}
                          </div>
                    </div>
                               <div className="form-group">
                                   <label>Order URL</label>
                                   <input
                                       placeholder={"Enter Order URL"}
                                       value={item.ordernowurl}
                                       type={"text"}
                                       name={"ordernowurl"}
                                       onChange={(e) => this.onHandleChange(e, index)}
                                   />
                                   <div
                                       className="error"
                                       style={{
                                           textAlign: "start",
                                           color: "red",
                                           fontSize: "12px",
                                       }}
                                   >
                                       {this.state.errorMsg[this.state.errorMsg.length - 1]
                                           ? this.state.errorMsg[this.state.errorMsg.length - 1].ordernowurl
                                               ? this.state.errorMsg[this.state.errorMsg.length - 1].ordernowurl
                                               : ""
                                           : ""}
                                   </div>
                               </div>
                               <div className="form-group">
                                   <label>Menu URL</label>
                                   <input
                                       placeholder={"Enter Menu URL"}
                                       value={item.meanuurl}
                                       type={"text"}
                                       name={"meanuurl"}
                                       onChange={(e) => this.onHandleChange(e, index)}
                                   />
                                   <div
                                       className="error"
                                       style={{
                                           textAlign: "start",
                                           color: "red",
                                           fontSize: "12px",
                                       }}
                                   >
                                       {this.state.errorMsg[this.state.errorMsg.length - 1]
                                           ? this.state.errorMsg[this.state.errorMsg.length - 1].meanuurl
                                               ? this.state.errorMsg[this.state.errorMsg.length - 1].meanuurl
                                               : ""
                                           : ""}
                                   </div>
                               </div>
                               <div className="form-group">
                                   <label>Specials URL</label>
                                   <input
                                       placeholder={"Enter Special URL"}
                                       value={item.specialurl}
                                       type={"text"}
                                       name={"specialurl"}
                                       onChange={(e) => this.onHandleChange(e, index)}
                                   />
                                   <div
                                       className="error"
                                       style={{
                                           textAlign: "start",
                                           color: "red",
                                           fontSize: "12px",
                                       }}
                                   >
                                       {this.state.errorMsg[this.state.errorMsg.length - 1]
                                           ? this.state.errorMsg[this.state.errorMsg.length - 1].specialurl
                                               ? this.state.errorMsg[this.state.errorMsg.length - 1].specialurl
                                               : ""
                                           : ""}
                                   </div>
                               </div>
                           <div className="form-group">
                               <label>Google URL</label>
                               <input
                                   placeholder={"Enter Google URL"}
                                   value={item.googlereviewurl}
                                   type={"text"}
                                   name={"googlereviewurl"}
                                   onChange={(e) => this.onHandleChange(e, index)}
                               />
                               <div
                                   className="error"
                                   style={{
                                       textAlign: "start",
                                       color: "red",
                                       fontSize: "12px",
                                   }}
                               >
                                   {this.state.errorMsg[this.state.errorMsg.length - 1]
                                       ? this.state.errorMsg[this.state.errorMsg.length - 1].googlereviewurl
                                           ? this.state.errorMsg[this.state.errorMsg.length - 1].googlereviewurl
                                           : ""
                                       : ""}
                               </div>
                           </div>
                           <div className="form-group">
                               <label>Facebook URL</label>
                               <input
                                   placeholder={"Enter Facebook URL"}
                                   value={item.facebookurl}
                                   type={"text"}
                                   name={"facebookurl"}
                                   onChange={(e) => this.onHandleChange(e, index)}
                               />
                               <div
                                   className="error"
                                   style={{
                                       textAlign: "start",
                                       color: "red",
                                       fontSize: "12px",
                                   }}
                               >
                                   {this.state.errorMsg[this.state.errorMsg.length - 1]
                                       ? this.state.errorMsg[this.state.errorMsg.length - 1].facebookurl
                                           ? this.state.errorMsg[this.state.errorMsg.length - 1].facebookurl
                                           : ""
                                       : ""}
                               </div>
                           </div>
                           <div className="form-group">
                               <label>Phone Number</label>
                               <input
                                   placeholder={"Enter Order URL"}
                                   value={item.phoneno}
                                   type={"text"}
                                   name={"phoneno"}
                                   onChange={(e) => this.onHandleChange(e, index)}
                               />
                               <div
                                   className="error"
                                   style={{
                                       textAlign: "start",
                                       color: "red",
                                       fontSize: "12px",
                                   }}
                               >
                                   {this.state.errorMsg[this.state.errorMsg.length - 1]
                                       ? this.state.errorMsg[this.state.errorMsg.length - 1].phoneno
                                           ? this.state.errorMsg[this.state.errorMsg.length - 1].phoneno
                                           : ""
                                       : ""}
                               </div>
                           </div>
                    <div className="form-group">
                        <label>Address</label>
                        {/*<input*/}
                        {/*    placeholder={"Enter Address"}*/}
                        {/*    value={item.address}*/}
                        {/*    type={"text"}*/}
                        {/*    name={"address"}*/}
                        {/*    onChange={(e)=>this.onHandleChange(e,index)}*/}
                        {/*     />*/}
                              {/*<SearchInputFile/>*/}
                        <AutoCompleteCitySearch
                            addressDetails={this.getAddress}
                            value ={item.address}
                            idx={index}
                            isEdit = {!!this.props.location.state}
                            isReset={this.state.isReset}
                        />
                        {/*<Autocomplete*/}

                        {/*    apiKey={ENV_Key}*/}
                        {/*    placeholder={'Enter Address'}*/}
                        {/*    style={{width:"50%"}}*/}
                        {/*    onChange={(e)=>this.onHandleChange(e, index, "city")}*/}
                        {/*    onPlaceSelected={(place) =>this.onHandleChange(place, index, 'placeId')}*/}
                        {/*    value={item.address}*/}
                        {/*    // value={item.address}*/}
                        {/*/>*/}
                             <div
                              className="error"
                            style={{
                              textAlign: "start",
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            {this.state.errorMsg[this.state.errorMsg.length - 1]
                              ? this.state.errorMsg[this.state.errorMsg.length - 1].address
                                ? this.state.errorMsg[this.state.errorMsg.length - 1].address
                                : ""
                              : ""}
                          </div>
                    </div>
                           <div className="form-group">
                               <label>City</label>
                               <input
                                   disabled={true}
                                   placeholder={"City"}
                                   value={item.city}
                                   type={"text"}
                                   name={"city"}
                                   onChange={(e) => this.onHandleChange(e, index)}
                               />
                               <div
                                   className="error"
                                   style={{
                                       textAlign: "start",
                                       color: "red",
                                       fontSize: "12px",
                                   }}
                               >
                               </div>
                           </div>
                           <div className="form-group">
                               <label>State</label>
                               <input
                                   disabled={true}
                                   placeholder={"State"}
                                   value={item.state}
                                   type={"text"}
                                   name={"state"}
                                   onChange={(e) => this.onHandleChange(e, index)}
                               />
                               <div
                                   className="error"
                                   style={{
                                       textAlign: "start",
                                       color: "red",
                                       fontSize: "12px",
                                   }}
                               >
                               </div>
                           </div>
                           <div className="form-group">
                               <label>Zipcode</label>
                               <input
                                   disabled={true}
                                   placeholder={"Zipcode"}
                                   value={item.zipcode}
                                   type={"text"}
                                   name={"zipcode"}
                                   onChange={(e) => this.onHandleChange(e, index)}
                               />
                               <div
                                   className="error"
                                   style={{
                                       textAlign: "start",
                                       color: "red",
                                       fontSize: "12px",
                                   }}
                               >
                               </div>
                           </div>
                           <div className="form-group">
                               <label>Country</label>
                               <input
                                   disabled={true}
                                   placeholder={"Country"}
                                   value={item.country}
                                   type={"text"}
                                   name={"country"}
                                   onChange={(e) => this.onHandleChange(e, index)}
                               />
                               <div
                                   className="error"
                                   style={{
                                       textAlign: "start",
                                       color: "red",
                                       fontSize: "12px",
                                   }}
                               >
                               </div>
                           </div>
                       {this.props.location && this.props.location.state &&
                       <div className="form-group">
                           <label>Status</label>
                           <select
                               value={item.active}
                               name={"active"}
                               onChange={(e) => this.onHandleChange(e, index)}
                           >
                               <option value = {1} >Active</option>
                               <option value = {0} >InActive</option>
                           </select>
                           {/* <SearchInputFile /> */}
                           <div
                               className="error"
                               style={{
                                   textAlign: "start",
                                   color: "red",
                                   fontSize: "12px",
                               }}
                           >
                               {this.state.errorMsg[this.state.errorMsg.length - 1]
                                   ? this.state.errorMsg[this.state.errorMsg.length - 1].active
                                       ? this.state.errorMsg[this.state.errorMsg.length - 1].active
                                       : ""
                                   : ""}
                           </div>
                       </div>
                       }
                    <div className="form-group">
                    <label className="btn chooseImage">Upload Logo</label>
                    <div className="fileupload-Btn">
                        <input
                            name='filename'
                            accept="image/png, image/gif, image/jpeg"
                            type="file"
                            onChange={(e)=>this.onChangeFileHandler(e,index)}
                            id='uploadFile'/>
                            <label for="uploadFile">
                            File Upload
                            </label>
                            <span className="fileName">
                                {item.filename !== "" &&
                                (`${item?.filename.split(".")[0].slice(0,28)}.${item?.filename.split(".")[1]}` ?? "No Image")
                                }
                            </span>
                    </div>
                        <div
                            className="error"
                            style={{
                                textAlign: "start",
                                color: "red",
                                fontSize: "12px",
                            }}
                        >
                            {this.state.errorMsg[this.state.errorMsg.length - 1]
                                ? this.state.errorMsg[this.state.errorMsg.length - 1].filename
                                    ? this.state.errorMsg[this.state.errorMsg.length - 1].filename
                                    : ""
                                : ""}
                        </div>
                    </div>
                           <div className={'row'}>
                               {item.lat !== "" && item.lng !== "" &&
                               <MapContainer
                                   center={{lat: 41.850033, lng: -87.6500523}}
                                   data={item}
                               />
                               }
                           </div>
        </div> : ""}) : ""}
        <div className="saveData">
                    <Button className="saveBtn" onClick={()=>{this.saveBtn()}}>{this.state.loader ? "Loading...":"Save"}</Button>
            <Button style={{marginLeft:"20px"}} className="saveBtn" onClick={()=>{this.reset(!!this.props?.location?.state ? "Edit":"Add")}}>Cancel</Button>

        </div>
                </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    sideBarPath: route =>
      dispatch({ type: "Show_Sidebar_Route", payload: route })
  };
};

export default connect(null,mapDispatchToProps)(AddManageLocation);