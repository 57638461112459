export const userData = `
query allUserdata($where:JSON){
  allUserdata(where:$where){
    totalCount
  }
}
`
export const userOrder = `
query allOrders($where:JSON){
  allOrders(where:$where){
    totalCount
  }
}
`
export const userCoupon = `
query allCoupons($where:JSON){
  allCoupons(where:$where){
    totalCount
  }
}
`