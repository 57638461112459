import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import "./notification.css";
import swal from "sweetalert";
import {connect} from "react-redux";
import {API} from "../../service";
import moment from 'moment'
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {KeyboardDateTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@mui/material/Checkbox';
import {
    Input,
    Select,
    FormControl
} from "@material-ui/core";
import {el} from "react-date-range/dist/locale";

class PushNotification extends Component{
    constructor(props){
        super(props);
        this.state={
            startdate:new Date(),
            isEndDate: false,
            isNever:false,
            selectedLocation:[],
            selectedLocName :[],
            isSearch:false,
            userList:[],
            locationid:[],
            loader:false,
        }
    }
    componentDidMount(){
        this.props.sideBarPath("/pushNotification");
        //api call for getting location
        this.getManageLocationData()
        //api call for getting actions
        this.getActions()
        //api call for getting TargetNotifications
        this.getTargetNotifications()
        //api call for getting Recurrences
        this.getRecurrences()
        //api for edit data
        !!this.props?.location?.state?.id && this.getAll(this.props.location.state.id)
        // console.log("props data===>>>>>>",this.props)
    }
    //fuc for when edit push notification ,api call for notification and notification map
    getAll = async id => {
        var status = true
        const obj={
            where:{
                id:id,
            }
        }
        const res = await API.getNotificationById(obj)
        if(res?.data?.allNotifications?.Notifications.length > 0 ){
            const notify = res?.data?.allNotifications?.Notifications?.[0]
            this.setState(
                {
                    // locationId : notify?.locationid,
                    callToActionId:notify?.actionid,
                    msg:notify?.notificationmsg,
                    neverend:notify?.neverend,
                    targetMsgId:notify?.targetmsgsendid,
                    startDate:notify?.startdate,
                    recurenceId:notify?.recurrenceid,
                    endDate:notify?.enddate,
                    startTime:notify?.starttime,
                    isEndDate:notify?.neverend === 1 ? true : false,
                    isNever:notify?.neverend === 0 ? true : false
                })
            status = true
        }else{
            status = false
        }
        if(status){
            const objMap={where : { notificationid : id, active:1 }}
            const resMap = await API.getNotificationMappingById(objMap)
            if(resMap?.data?.allNotificationlocationmappings?.Notificationlocationmappings?.length > 0){
                const resMapData = resMap?.data?.allNotificationlocationmappings?.Notificationlocationmappings,
                    clonestate = [...this.state.locationid],
                    newArray = [];
                resMapData.map(ed => clonestate.push(ed?.locationid))
                resMapData.map(el => newArray.push(el?.notificationlocationmappinglocationidmaprel?.Locations[0]?.locationname))
                this.setState({locationid: clonestate, selectedLocation: resMapData, selectedLocName : newArray})
            }else{
                swal({
                    title: 'No Location Mapping Found',
                    icon: "error"
                })
            }
        }else{
            swal({
                title: 'No Location Mapping Done Found',
                icon: "error"
            })
        }
    }
    //func for api call of actions
    getActions = async () =>{
        const res = await API.allActions()
        if(res?.status === 200){
            this.setState({actions : res?.data?.data?.allCalltoactions?.Calltoactions ?? []})
        }else{
            this.setState({actions : []})
            swal({
                title: "Fetch Failed",
                icon: "error"
            });
        }
    }
    //func for api call of target notification
    getTargetNotifications = async () =>{
        const res = await API.allTargetNotification()
        if(res?.status === 200){
            this.setState({targetNotifications : res?.data?.data?.allTargetuserforsentthenotificatons?.Targetuserforsentthenotificatons ?? []})
        }else {
            this.setState({targetNotifications:[]})
            swal({
                title: "Fetch Failed",
                icon: "error"
            });
        }
    }
    //func for api call of recurrence
    getRecurrences = async () =>{
        const res = await API.allRecurrences()
        if(res?.status === 200){
            this.setState({recurrence : res?.data?.data?.allRecurrences?.Recurrences ?? []})
        }else{
            this.setState({recurrence : []})
            swal({
                title: "Fetch Failed",
                icon: "error"
            });
        }
    }
    //func for api call of locations
    getManageLocationData = async () => {
        const obj={
            where: {
                active:1,
            }
        }
        localStorage.getItem('role') === "LOCATIONMANAGER" && (obj.where['and'] = [
            {
                "and": [{ "id": {"inq":JSON.parse(localStorage.getItem('locationid'))} }]
            }
        ])
        const res = await API.getLocation(obj)
              if(res?.status === 200){
                  const isEdit = JSON.parse(localStorage.getItem('locationid')),
                        locMap  = JSON.parse(JSON.stringify(res?.data?.data?.allLocations?.Locations ?? []));
                  this.setState({ listDataMap : locMap })
                  const selectAll = { id : 100 , locationname : "Select All" },
                        locList = res?.data?.data?.allLocations?.Locations ?? [];
                        locList.unshift(selectAll)
                  // if(isEdit.length > 0){
                  //     var isArrayLoc = []
                  //     for (var i = 0; i <= isEdit.length-1 ; i++){
                  //         const filterData = locList.filter(el => el.id === isEdit[i])
                  //         isArrayLoc.push(filterData[0])
                  //     }
                  //     this.setState({
                  //         listData: isArrayLoc,
                  //         count: isArrayLoc?.length ?? 0
                  //     })
                  // }else{
                      this.setState({
                          listData: locList,
                          count: res?.data?.data?.allLocations?.totalCount ?? 0
                      })
                  // }
              }else{
                      swal({
                        title: "Server Location Error,Try Again",
                        icon: "error"
                      });
                      this.setState({ listData: [], count:0 })
              }
    }
    //func use for getting user data as per location id graphQL
    getUser = async () =>{
        const query = {
            where: {
                order: "id desc",
                active: 1,
                role: "CUSTOMER",
            }
        }
        const res = await API.getUser(query, null, null)
        if (res?.status === 200) {
            const user = res?.data?.data?.allUserdata?.Userdata,
                total = res?.data?.data?.allUserdata.totalCount,
                targetmsgUserId = []
            user.length > 0 && user.map(itm =>targetmsgUserId.push(itm.id))
            this.setState({userList : user, isSearch : true, targetmsgUserId})
        } else {
            this.setState({userList : []})
            swal({
                icon: "warning",
                title: "Server Error,Try Agian",
                className: "loginSwal",
            })
        }
    }
    //func use for getting user data as per location id but this is rest api
    getUserRest = async (offset, perPage, searchText) => {
        const locationid = JSON.parse(localStorage.getItem("locationid"));
        const query = {
            // where: {
            // order: "id desc",
            // active: 1,
            offset: offset,
            limit: perPage,
            role: "CUSTOMER",
            locationId: locationid && locationid.length ? locationid : [],
            name: searchText,
            emailId: searchText,
            phoneNumber: searchText,
            // }
        };
        const res = await API.getUserRest(query);
        if (res?.status === 200) {
            // const user = res?.data?.data?.allUserdata?.Userdata,
            //     total = res?.data?.data?.allUserdata.totalCount
            const user = res?.data?.response?.data,
                  total = res?.data?.response?.totalcount, // need to be done by backend
                  targetmsgUserId = [];
            user.length > 0 && user.map(itm =>targetmsgUserId.push(itm.id))
            this.setState({userList : user, isSearch : true, targetmsgUserId})
        } else {
            swal({
                icon: "warning",
                title: "Server Error,Try Agian",
                className: "loginSwal",
            });
        }
    }
    //func for handle input change
    onHandleChange = async (e, type) =>{
        if(e.target.name === "locationid" && type === "add"){
            //this code is not for making chips and it is working fine
            // const  locationmap = [...this.state.locationid],
            //        filterData = this.state.listData.filter(ed => ed?.id === parseInt(e.target.value)),
            //        newData =  this.state.selectedLocation.concat(filterData);
            //        locationmap.push(parseInt(e.target.value))
            // this.setState({selectedLocation : newData, locationid:locationmap})
        }else if(e.target.name === "targetMsgId"){
            this.setState({[e.target.name] : parseInt(e.target.value)})
            localStorage.getItem('role') === "LOCATIONMANAGER" ? await this.getUserRest(0, 10, "null") :await this.getUser()
        }
        // else if(e.target.name === "locationid" && type === "edit"){
        //     const check = this.state.locationid.includes(parseInt(e.target.value))
        //         if(check) return swal("Location Already Exits", { icon: "error" });
        //         const cloneLocation = [...this.state.locationid]
        //              cloneLocation.push(parseInt(e.target.value))
        //                 this.setState({locationid : cloneLocation})
        // }
        else{
            this.setState({[e.target.name] : e.target.value})
        }
    }
    //func both for add and edit push notification
    onSubmit = async type =>{
        if(!!this.state?.msg && !!this.state.selectedLocName.length > 0 &&
            !!this.state?.callToActionId &&
            // !!this.state?.targetMsgId &&
        !!this.state?.startdate && !!this.state.startTime
            // && !!this.state.recurenceId &&
            // (!!this.state.endDate || !!this.state.neverend)
        ){
            const locationid = JSON.parse(localStorage.getItem("locationid"));
            const finalobj={
                    id:this.props?.location?.state?.id || 0,
                    callToActionId: parseInt(this.state.callToActionId),
                    startDate:moment(this.state.startDate).format('YYYY-MM-DD').toString(),
                    // endDate: this.state?.neverend !== 1 ? moment(this.state.endDate).format('YYYY-MM-DD').toString() : "null",
                    startTime: this.state.startTime,
                    // endTime: "11:22",
                    locationId: this.getLocID(this.state.selectedLocName),
                    msg: this.state.msg,
                    // targetmsgUserId:this.state.targetmsgUserId || [],
                    // neverEnd: this.state.neverend || 0,
                    // recurenceId: parseInt(this.state.recurenceId),
                    // targetMsgId: parseInt(this.state.targetMsgId),
                    locationIdOfLocationManager: locationid[0]
            }
           // console.log("finalObj", finalobj)
            this.setState({loader : true})
            const res = await API.saveRestNotification(finalobj)
            if(res?.status === 200){
                const res_alert = await swal({
                    title: 'Sent Successfully',
                    icon: "success"
                })
                this.setState({loader : false})
                if(res_alert) return this.props.history.push('/viewNotification')
            }else{
                this.setState({loader : false})
                swal({
                    title: 'SomeThing Went Wrong,Try Again',
                    icon: "error"
                })
            }
        }else{
            swal({
                title: 'Please Enter All Required(*) Field',
                icon: "error"
            })
        }
    }
    //func for delete map notification
    delLocations =async (idx, type, data) =>{
        if(this.state.locationid.length > 0){
            const newArray = [],
                  cloneState = [...this.state.selectedLocation];
                  cloneState.splice(idx,1)
            cloneState.map(el => newArray.push(el?.locationid || 0))
            this.setState({selectedLocation : cloneState, locationid : newArray})

        }
    }
    //func for search user rest api
    searchUserRest = async e =>{
        const {target :{ value }} = e
        if(value.length > 2)return await this.getUserRest(0, 1 , value)
        this.getUserRest(0, 1, "null")
    }
    //func used for searching
    searchUser = async (e) => {
        if (e.target.value.length > 2) {
            const query = {
                where: {
                    order: "id DESC",
                    active: 1,
                    role: localStorage.getItem('role') === 'SUPERADMIN' ? "CUSTOMER" : "LOCATIONMANAGER",
                    and: [{
                        or: [
                            {email: {like: `%${e.target.value}%`}},
                            // { phonenumber: { like: `%${e.target.value}%` } },
                            {firstname: {like: `%${e.target.value}%`}}
                        ]
                    }]
                }
            }
            localStorage.getItem('role') === 'LOCATIONMANAGER' && (query.where['and'] = [
                {
                    "and": [{ "locationid": {"inq":JSON.parse(localStorage.getItem('locationid'))} }]
                }
            ])
            const res = await API.getUser(query, null, null)
            if (res?.status === 200) {
                const user = res?.data?.data?.allUserdata?.Userdata,
                    total = res?.data?.data?.allUserdata.totalCount,
                    targetmsgUserId = []
                    user.length > 0 && user.map(itm =>targetmsgUserId.push(itm.id))
                this.setState({userList: user, targetmsgUserId})
            } else {
                this.setState({userList: []})
                swal({
                    icon: "warning",
                    title: "Server Error,Try Agian",
                    className: "loginSwal",
                })
            }
        } else {
            this.getUser()
        }
    }
    //func for handle location
    onChangeLoc = e =>{
        const {target: { value },} = e;
        const prevstate = this.state.selectedLocName.filter(el => el === "Select All")
        const filterValue = value.filter(el =>el === "Select All")
        if (prevstate.length > 0){
            this.setState({selectedLocName : []})
        }
        else if(filterValue.length > 0 ){
            let selData = []
            this.state.listData.map(ed => selData.push(ed.locationname))
            this.setState({selectedLocName : selData})
        }else{
            this.setState({"selectedLocName" : typeof value === "string" ? value.split(',') : value })
        }
    }
    //func for getting location id
    getLocID = value =>{
        debugger
        var isArray = []
    for (var i = 0; i <= value.length-1 ; i++){
        const filterData = this.state.listDataMap.filter(el => el.locationname === value[i])
        if(filterData.length > 0){
            isArray.push(filterData[0].id)
        }
    }
    console.log("isArray", isArray)
    return isArray
   }
    render(){
        return(
            <div className={'row'}>
                <div className={'col-md-12'}>
            <div className="pushNotification">
               <h3>Push Notification</h3>
               <div>
                   <div className=" ">
                       <div className={'row'}>
                           <div className={'col-md-12'}>
                               <div className={'col-md-6'}>
                                   <div className={'row'}>
                                       <div className={'col-md-4'}><label>Select Location <span style={{color:"red"}}>*</span></label></div>
                                       <div className={'col-md-8'} style={{paddingLeft:"0"}}>
                                           <FormControl  style={{width:"100%"}}>
                                               <InputLabel id="demo-multiple-checkbox-label">Location</InputLabel>
                                               <Select
                                                   labelId="demo-multiple-checkbox-label"
                                                   id="demo-multiple-checkbox"
                                                   multiple
                                                   placeholder={"Select Location"}
                                                   name={'selectedLocName'}
                                                   value={ this.state.selectedLocName }
                                                   onChange={(e)=>this.onChangeLoc(e)}
                                                   input={<Input label="Tag" />}
                                                   renderValue={(selected) => selected.join(', ')}
                                                   // MenuProps = {{
                                                   //     PaperProps: {
                                                   //         style: {
                                                   //             maxHeight: 48 * 4.5 + 8,
                                                   //             width: 250,
                                                   //         },
                                                   //     }
                                                   // }}
                                               >
                                                   {/*<MenuItem key = {0} value = {"selectAll"}>*/}
                                                   {/*    <Checkbox checked={this.state.selectedLocName.indexOf("selectAll") > -1} />*/}
                                                   {/*    <ListItemText primary = {"Select All"} />*/}
                                                   {/*</MenuItem>*/}
                                                   {this.state.listData && this.state.listData.map((name, indx) => (
                                                       <MenuItem key = { indx } value = {name.locationname}>
                                                           <Checkbox checked={this.state.selectedLocName.indexOf(name.locationname) > -1} />
                                                           <ListItemText primary = {name.locationname} />
                                                       </MenuItem>
                                                   ))}
                                               </Select>
                                           </FormControl>
                                           {/*<select*/}
                                           {/*    style={{width:"100%",padding:"10px"}}*/}
                                           {/*    value={this.state.locationid}*/}
                                           {/*    name={"locationid"}*/}
                                           {/*    onChange={(e)=>this.onHandleChange(e, "add")}*/}
                                           {/*>*/}
                                           {/*    <option value={'default'}>Select Location</option>*/}
                                           {/*    {this.state.listData && this.state.listData.map(el =><option value={el?.id}>{el?.locationname}</option>)}*/}
                                           {/*</select>*/}
                                       </div>
                                   </div>
                               </div>
                               {/*commented as it is not it use but ref is taken from it*/}
                               <div className={'col-md-6'}>
                                   {/*{this.state.selectedLocation.length > 0 &&*/}
                                   {/*<div className={'row'}>*/}
                                   {/*    <div className={'col-md-12'}>*/}
                                   {/*        <p>{this.state?.selectedLocation?.length} Locations is selected`</p>*/}
                                   {/*        {this.state.selectedLocation.map((el,ex) => <div className={'col-md-2'} style={{margin:"5px"}}>*/}
                                   {/*            {!!this.props?.location?.state?.id ?*/}
                                   {/*                <span style={ {display: "contents", fontSize: "10px"} }>*/}
                                   {/*                         <span style={{*/}
                                   {/*                             background: "grey",*/}
                                   {/*                             padding: "5px",*/}
                                   {/*                             color: "white"*/}
                                   {/*                         }}>{el?.locationname?.trim()?.length > 3 ??  el?.notificationlocationmappinglocationidmaprel?.Locations?.[0]?.locationname.trim().length > 3 ?*/}
                                   {/*                             el?.locationname?.slice(0, 4) + ".." ?? el?.notificationlocationmappinglocationidmaprel?.Locations?.[0]?.locationname.slice(0, 4) + ".." :*/}
                                   {/*                             el?.locationname ?? el?.notificationlocationmappinglocationidmaprel?.Locations?.[0]?.locationname.trim()}*/}
                                   {/*                             <span*/}
                                   {/*                                 onClick={() => this.delLocations(ex, !!this.props?.location?.state?.id ? "edit" : "add", el)}*/}
                                   {/*                                 style={{*/}
                                   {/*                                     marginLeft: "5px",*/}
                                   {/*                                     cursor: "pointer"*/}
                                   {/*                                 }}>X</span>*/}
                                   {/*                        </span>*/}
                                   {/*                    </span>*/}
                                   {/*                :*/}
                                   {/*                <span style={{display: "contents", fontSize: "10px"}}>*/}
                                   {/*                        <span style={{*/}
                                   {/*                            background: "grey",*/}
                                   {/*                            padding: "5px",*/}
                                   {/*                            color: "white"*/}
                                   {/*                        }}>{el?.locationname?.trim()?.length > 3 ? el?.locationname.trim().slice(0, 4) + ".." : el?.locationname.trim()}*/}
                                   {/*                            <span*/}
                                   {/*                                onClick={() => this.delLocations(ex, !!this.props?.location?.state?.id ? "edit" : "add", el)}*/}
                                   {/*                                style={{*/}
                                   {/*                                    marginLeft: "5px",*/}
                                   {/*                                    cursor: "pointer"*/}
                                   {/*                                }}>X</span>*/}
                                   {/*                        </span>*/}
                                   {/*                    </span>*/}
                                   {/*            }*/}
                                   {/*        </div>)}*/}
                                   {/*    </div>*/}
                                   {/*</div>*/}
                                   {/*}*/}
                               </div>
                           </div>
                       </div>
                   </div>


                   <div className="wrap">
                        <label>Call to action <span style={{color:"red"}}>*</span></label>
                        <select
                            value={this.state.callToActionId}
                            name={"callToActionId"}
                           onChange={(e)=>this.onHandleChange(e)}
                            >
                            <option value={0}>Select Action</option>
                            {this.state.actions && this.state.actions.map(ed => <option value={ed?.id}>{ed?.name ?? "Error"}</option>)}
                        </select>
                   </div>
                   <div className="wrap">
                    <label>Type message <span style={{color:"red"}}>*</span></label>
                    <textarea
                        value={this.state.msg}
                        name={"msg"}
                        onChange={(e)=>this.onHandleChange(e)}
                        placeholder="Enter Message here."
                        >
                    </textarea>
                   </div>
                   <div>
                       <div className={'row'}>
                           {/*<div className={'col-md-12'}>*/}
                           {/*    <div className={'col-md-6'} style={{paddingLeft:"0"}}>*/}
                           {/*        <div className="wrap">*/}
                           {/*            <label style={{width:"40%"}}>Target your message <span style={{color:"red"}}>*</span></label>*/}
                           {/*            <select className="equal"*/}
                           {/*                    value={this.state.targetMsgId}*/}
                           {/*                    name={"targetMsgId"}*/}
                           {/*                    style={{width:"40%"}}*/}
                           {/*                    onChange={(e)=>this.onHandleChange(e)}*/}
                           {/*            >*/}
                           {/*                <option>Select</option>*/}
                           {/*                {this.state.targetNotifications && this.state.targetNotifications.map(ed =><option value={ed?.id}>{ed?.name ?? "Error"}</option>)}*/}
                           {/*            </select>*/}
                           {/*        </div>*/}
                           {/*    </div>*/}
                           {/*    <div className={'col-md-6'} style={{marginTop:"50px"}}>*/}
                           {/*        <div className={'row'}>*/}
                           {/*            <div className={'col-md-6'}><input onChange={(e)=>localStorage.getItem('role') === "LOCATIONMANAGER" ? this.searchUserRest(e) : this.searchUser(e)} placeholder={'Enter Name'}/></div>*/}
                           {/*            <div className={'col-md-6'}><input onChange={(e)=>localStorage.getItem('role') === "LOCATIONMANAGER" ? this.searchUserRest(e) : this.searchUser(e)} placeholder={'Enter Email'}/></div>*/}
                           {/*        </div>*/}
                           {/*    </div>*/}
                           {/*</div>*/}
                       </div>
                   </div>
               </div>
                <div className={"row"}>
                    <div className={'col-md-12'}>
                        {/*{this.state.isSearch &&*/}
                        {/*<table className={"table table-striped"}>*/}
                        {/*    <thead>*/}
                        {/*    <tr>*/}
                        {/*        <th style={{borderRight:"none"}}>ID</th>*/}
                        {/*        <th style={{borderRight:"none"}}>Name</th>*/}
                        {/*        <th style={{borderRight:"none"}}>Email</th>*/}
                        {/*        <th style={{borderRight:"none"}}>PhoneNumber</th>*/}
                        {/*    </tr>*/}
                        {/*    </thead>*/}
                        {/*    <tbody>*/}
                        {/*    {this.state.userList && this.state.userList.map((ed, ex) => {*/}
                        {/*        return (*/}
                        {/*            <tr>*/}
                        {/*                <td>{ed?.id}</td>*/}
                        {/*                <td>{ed?.firstname || ed?.firstName}</td>*/}
                        {/*                <td>{ed?.email}</td>*/}
                        {/*                <td>{ed?.phonenumber || ed?.phoneNumber}</td>*/}
                        {/*            </tr>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}
                        {/*}*/}
                    </div>
                </div>
               <div className={'row'}>
                   <h4 style={{marginLeft:"15px"}}>Select Timeframe <span style={{color:"red"}}>*</span></h4>
                   <div className={'col-md-6'}>
                       <div className={'row'}>
                           <div className={'col-md-6'}>
                               <MuiPickersUtilsProvider
                                   libInstance={moment}
                                   utils={DateFnsUtils}
                               >
                                   <Grid container justifyContent="space-around">
                                       <KeyboardDateTimePicker
                                           variant="inline"
                                           format="MM/dd/yyyy"
                                           margin="normal"
                                           name="startdate"
                                           minDate={!!this.props?.location?.state?.id ? null :  new Date()}
                                           placeholder="Enter start date"
                                           label= "Select Notification Start Date"
                                           value={ this.state.startDate }
                                           onChange={(date)=>this.setState({startDate : date,
                                            //    startTime: `${moment(date).format("YYYY-MM-DD hh:mm A").toString().split(" ")[1]}`
                                               startTime: `${moment(date, "YYYY-MM-DD hh:mm A").format("HH:mm").toString()}`
                                            })}
                                           KeyboardButtonProps={{
                                               "aria-label": "change date"
                                           }}
                                           keyboardIcon={
                                               <svg
                                                   xmlns="http://www.w3.org/2000/svg"
                                                   width="20.5"
                                                   height="20"
                                                   viewBox="0 0 31.5 36"
                                               >
                                                   <path
                                                       id="Icon_awesome-calendar-alt"
                                                       data-name="Icon awesome-calendar-alt"
                                                       d="M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0ZM22.5,18.844A.846.846,0,0,1,23.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,23.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,14.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,14.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,5.344,18H8.156A.846.846,0,0,1,9,18.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,5.344,27H8.156A.846.846,0,0,1,9,27.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z"
                                                   />
                                               </svg>
                                           }
                                       />
                                   </Grid>
                               </MuiPickersUtilsProvider>
                           </div>
                           <div className={'col-md-6'} style={{marginTop:"35px"}}><input value={this.state.startTime || "Select Time"}/></div>
                       </div>
                   </div>
               </div>
                <div className={'row'} style={{display:"none"}}>
                    <div className={'col-md-12'}>
                        <div className={'col-md-6'}>
                            <div className="radionotice">
                                <div className="wrap radio">
                                    {/*<label>Select Recurrence</label> <br />*/}
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            {/*{this.state.recurrence && this.state.recurrence.map((itm, idx)=>{*/}
                                            {/*    return(*/}
                                            {/*        <div className={'row'}>*/}
                                            {/*            <div className={'col-md-1'}>*/}
                                            {/*                <input type="radio" name={'recurenceId'} id="none"*/}
                                            {/*                       style={{marginLeft:"0px"}}*/}
                                            {/*                       checked={this.state?.recurenceId === itm?.id ? 1 : 0}*/}
                                            {/*                       value={this.state.recurenceId}*/}
                                            {/*                       onChange={()=>this.setState({recurenceId : itm.id, recurName :itm.name })} />*/}
                                            {/*            </div>*/}
                                            {/*            <div className={'col-md-6'} style={{paddingLeft:"0px"}}>*/}
                                            {/*                <label htmlFor="none">{itm.name}</label><br/>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*})}*/}
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        {/*<div className={'col-md-6'}><p style={{position:"absolute",top:200}}>`The Notificaton Will Be Send {this.state.recurName || "Select Recurrence Type"} at <span style={{fontWeight:"bold"}}>{this.state.startTime || "Select StartTime"}</span>`</p></div>*/}
                    </div>
                </div>

                <div className="mainwrapper borderBottom" style={{display:"none"}}>
                    <div className="endDatefield">
                            <div className={'row'}>
                                        <div className={'row'}>
                                            <div className={'col-md-4'}>
                                                {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                                {/*    <Grid container justifyContent="space-around">*/}
                                                {/*        <KeyboardDatePicker*/}
                                                {/*            label={"Select Notification End Date"}*/}
                                                {/*            variant="inline"*/}
                                                {/*            format="dd/MM/yyyy"*/}
                                                {/*            margin="normal"*/}
                                                {/*            name="enddate"*/}
                                                {/*            minDate={this.state.startdate}*/}
                                                {/*            placeholder="Enter End date"*/}
                                                {/*            // label= "Select Notification End Date"*/}
                                                {/*            value={ this.state.endDate || null}*/}
                                                {/*            disabled={this.state.isEndDate}*/}
                                                {/*            onChange={(date)=>this.setState({endDate : moment(date).format('YYYY-MM-DD'), isDisabled : false, isNever: true})}*/}
                                                {/*            KeyboardButtonProps={{*/}
                                                {/*                "aria-label": "change date"*/}
                                                {/*            }}*/}
                                                {/*            keyboardIcon={*/}
                                                {/*                <svg*/}
                                                {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                                                {/*                    width="20.5"*/}
                                                {/*                    height="20"*/}
                                                {/*                    viewBox="0 0 31.5 36"*/}
                                                {/*                >*/}
                                                {/*                    <path*/}
                                                {/*                        id="Icon_awesome-calendar-alt"*/}
                                                {/*                        data-name="Icon awesome-calendar-alt"*/}
                                                {/*                        d="M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0ZM22.5,18.844A.846.846,0,0,1,23.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,23.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,14.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,14.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,5.344,18H8.156A.846.846,0,0,1,9,18.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,5.344,27H8.156A.846.846,0,0,1,9,27.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z"*/}
                                                {/*                    />*/}
                                                {/*                </svg>*/}
                                                {/*            }*/}
                                                {/*        />*/}
                                                {/*    </Grid>*/}
                                                {/*</MuiPickersUtilsProvider>*/}
                                            </div>
                                </div>
                                <div className={'col-md-12'}>
                                    {/*<div className={'col-md-1'} style={{width:"0"}}>*/}
                                    {/*    <input type="checkbox"  name="neverend"*/}
                                    {/*           style={{marginLeft:"0px"}}*/}
                                    {/*           checked={this.state?.neverend}*/}
                                    {/*        // value={this.state.neverend}*/}
                                    {/*            disabled={this.state.isNever}*/}
                                    {/*           onChange={(e)=>this.setState({neverend : e.target.checked ? 1 : 0 , isEndDate : !this.state.isEndDate} )} />*/}
                                    {/*</div>*/}
                                    {/*<div className={'col-md-6'} >*/}
                                    {/*    <label htmlFor="neverend">Never Ends</label>*/}
                                    {/*</div>*/}
                                </div>
                        </div>
                    </div>
                </div>
                <div className="notificationButton" style={{textAlign:"center"}}>
                    <Button type={'button'} onClick={()=>this.onSubmit(!!this.props.location.state?.id ? "edit":"add")}>{this.state.loader ? "Loading..." : "Send"}</Button>
                </div>
                <div className={'notificationButton'} style={{marginLeft:"20px"}}>
                    <Button type={'button'} onClick={()=>this.props.history.push('/')}>Cancel</Button>
                </div>
            </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
      sideBarPath: route =>
        dispatch({ type: "Show_Sidebar_Route", payload: route })
    };
  };

export default connect(null,mapDispatchToProps)(PushNotification);