import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import Modal from "../../Form/Modal";
import {API} from "../../service";
import "./coupon.css";
import  swal from "sweetalert";
import { connect } from 'react-redux';
import {API_URL} from "../../Config";
import moment from 'moment';
import {Loader} from "../../common/Loader";
class Coupon extends Component{
    constructor(props){
        super(props);
        this.state={
            openModal: false,
            expiredListData:[],
            listData:[],
            show:false,
            editableData: [],
            selected: {},
            selectAll: false,
            selectedArray: [],
            filter: {
                order: "id desc",
                active: 1
            },
            loader: true
        }
    }

    componentDidMount(){
      this.props.sideBarPath("/coupon");
      if(localStorage.getItem('role') === 'LOCATIONMANAGER'){
        this.getCouponLocationMap();
      }
      else{
        this.getLocation()
        this.getCouponData();
      }
    }
getLocation =async () =>{
  const obj={
    where: {
      active:1,
    }
  }
  localStorage.getItem('role') === "LOCATIONMANAGER" && (obj.where['and'] = [
    {
      "and": [{ "id": {"inq":JSON.parse(localStorage.getItem('locationid'))} }]
    }
  ])
  const resLoc = await API.getLocation(obj)
  if(resLoc && resLoc.status === 200){
      this.setState({locCount : resLoc?.data?.data?.allLocations?.totalCount ?? 0})
  }else{
    this.setState({locCount : 0})
      swal({
      icon: "warning",
      title: "Server Error,Try Agian",
      className: "loginSwal",
    });
  }
 }
    getCouponLocationMap = async () => {
      this.setState({loader : true})     
      const res = await API.getCouponLocationMapAPI({
        'locationId': JSON.parse(localStorage.getItem('locationid')),
    });
      if(res?.status === 200){
        let listData = [];
        let expiredData = [];
        res.data.response.data.map(item => {
          item["category"] = item.categoryname;
          item["location"] = item.locationname;
          if(new Date() >= new Date(item.enddate)){
            expiredData.push(item);
          }
          else{
            listData.push(item);
          }
          item.startdate = moment(item.startdate).format("YYYY-MM-DD");
          item.enddate = moment(item.enddate).format("YYYY-MM-DD");
          });
          this.setState({
          listData,
          expiredListData: expiredData,
          loader: false
        });
      }
      else{
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
      });
      this.setState({listData:[], expiredListData:[], loader: false}) 
    } 
    }

    getCouponData = async () => {
      this.setState({loader : true});
      const res = await API.getCouponAPI({
        where: this.state.filter
    });
      if(res?.status === 200){
        let listData = [];
        let expiredData = [];
        res.data.data.allCoupons.Coupons.map(item => {
          item['couponmapid'] = item.couponlocationmappingCouponidMap && 
                             item.couponlocationmappingCouponidMap.Couponlocationmappings && 
                             item.couponlocationmappingCouponidMap.Couponlocationmappings.length 
                             ? item.couponlocationmappingCouponidMap.Couponlocationmappings[0].id: "";
          item["category"] = item.couponcategoryidmaprel && 
                             item.couponcategoryidmaprel.Couponcategorymasters && 
                             item.couponcategoryidmaprel.Couponcategorymasters.length 
                             ? item.couponcategoryidmaprel.Couponcategorymasters.categoryname : "";
          item["location"] = item.couponlocationidmaprel && 
          item.couponlocationidmaprel.Locations && 
          item.couponlocationidmaprel.Locations.length
          ? item.couponlocationidmaprel.Locations.locationname : "";
          if(new Date() >= new Date(item.enddate)){
            expiredData.push(item);
          }
          else{
            listData.push(item);
          }
          item.startdate = moment(item.startdate).format("YYYY-MM-DD");
          item.enddate = moment(item.enddate).format("YYYY-MM-DD");
          });
          this.setState({
          listData,
          expiredListData: expiredData,
          loader: false
        });
      }
      else{
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
      });
      this.setState({listData:[], expiredListData:[], loader: false}) 
    }    
    }

    openModalData = () => {
        this.setState({openModal: true});
    }

    handleDelteItem = (item) => {
        if (item.active === 1 || item.active === "1") {
          if(localStorage.getItem('role') === 'LOCATIONMANAGER'){
            swal({
              title: "Are you sure you want to delete this record?",
              icon: "warning",
              buttons: true,
              dangerMode: true
            }).then(async willDelete => {
              if (willDelete) {
                const testData = {
                  id: item.id,
                  active: 0
                }
                const resData = await API.saveCouponMapData({obj: testData});
                if(resData?.status === 200){
                  const test = {
                    id: item.couponid,
                    active: 0
                  };
                  const res = await API.saveCouponData({obj: test})
                  if (res?.status == 200) {
                    swal({
                      title: "Deleted successfully",
                      icon: "success"
                    });
                  } else {
                    swal({
                      icon: "warning",
                      title: "Server Error,Try Agian",
                      className: "loginSwal",
                  });
                  }
                  swal({
                    title: "Deleted successfully",
                    icon: "success"
                  });
                    this.getCouponLocationMap();
                }
                else {
                  swal({
                    icon: "warning",
                    title: "Server Error,Try Agian",
                    className: "loginSwal",
                });
                }
              }
            });    
          }
          else{
          swal({
            title: "Are you sure you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true
          }).then(async willDelete => {
            if (willDelete) {
              if(item.couponmapid){
              const testData = {
                id: item.couponmapid,
                active: 0
              }
              const resData = await API.saveCouponMapData({obj: testData});
              if(resData?.status === 200){
                const test = {
                  id: item.id,
                  active: 0
                };
                const res = await API.saveCouponData({obj: test})
                if (res?.status == 200) {
                  swal({
                    title: "Deleted successfully",
                    icon: "success"
                  });
                } else {
                  swal({
                    icon: "warning",
                    title: "Server Error,Try Agian",
                    className: "loginSwal",
                });
                }
                swal({
                  title: "Deleted successfully",
                  icon: "success"
                });
                  this.getCouponData();
            }
          }
        }
          });
        }
        }
      }

      negateShow = () => {
        this.setState({ show: false});
      };

      handleEditModal = async item => {
        const res = await API.getCouponDataByID(item.id);
        if (res?.status == 200) {
          let data = res.data.data.allCoupons.Coupons[0],
              isArray = [],
              locationmap = data.couponlocationmappingCouponidMap.Couponlocationmappings
              locationmap.map(el => {
                isArray.push({locationmapid: el.id, name: el?.couponlocationmappinglocationidmaprel?.Locations?.length > 0 ? el?.couponlocationmappinglocationidmaprel?.Locations[0].locationname : "", id: el.locationid})
              })
            data['locationid'] = isArray
            this.setState({
              show: true,
              editableData: data
            });
        } else {
          swal({
            icon: "warning",
            title: "Server Error,Try Agian",
            className: "loginSwal",
        });
        this.setState({
          show: false,
          editableData: []
        });
          }
      };
    
      editList = () => {
        return (
          <Modal
            showButton={false}
            editableData={this.state.editableData}
            className="addCouponsButton"
            modalTitle="Edit Coupons"
            modalData= "addModalCoupon"
            dialogClass="addModalCouponModal"
            resetModal={this.negateShow}
            // query={saveCouponQuery}
            updateData={()=>{
              if(localStorage.getItem('role') === 'LOCATIONMANAGER'){
                this.getCouponLocationMap();
              }
              else{
                this.getCouponData();
              }}}
          />
        );
      }; 

  gettingAllCouponIds = () => {
    let selectedArr = [];
    let consolidateArray = [];
    this.state.listData.map(item => {
            consolidateArray[item.id] = true;
            selectedArr.push({ id: item.id });
        });
      this.setState({
          selectAll: true,
          selected: consolidateArray,
          selectedArray: selectedArr
       });
  }

  onRowSelected = sarray => {
    if (sarray.length === this.state.listData.length) {
      this.setState({ selectAll: true });
    } else {
      this.setState({ selectAll: false });
    }
  };

  onHandleCheckBox = (id, rowInfo) => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id] = !this.state.selected[id];
    this.setState({
      selected: newSelected
    });
    const { selectedArray } = this.state;
    let arr = selectedArray.filter(val => val.id !== id);
    if (!arr.length) {
      this.setState(
        {
          selectedArray: []
        },
        () => {
          this.onRowSelected(this.state.selectedArray);
        }
      );
    }
    if (arr.length === selectedArray.length) {
      this.setState(
        {
          selectedArray: [...selectedArray, rowInfo]
        },
        () => {
          this.onRowSelected(this.state.selectedArray);
        }
      );
    } else {
      this.setState(
        {
          selectedArray: arr
        },
        () => {
          this.onRowSelected(this.state.selectedArray);
        }
      );
    }
  };
      
  onHandleAllCheckBox = (isChecked) => {
    if (isChecked) {
      this.gettingAllCouponIds();
    } else {
      this.setState({
        selectAll: false,
        selectedArray: [],
        selected: {}
      });
    }
  }

  deleteAllBtn = () => {
    let deleteArray=[];
    this.state.selectedArray.map(item=>{
      deleteArray.push(item.id)
    });
    swal({
      title: "Are you sure you want to delete selected record?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
      const resData = await API.deleteAllQuery({
        deleteCouponArray: deleteArray,
        userId: parseFloat(localStorage.getItem("id"))
      });
      if(resData?.data?.response?.status === 200){
        swal({
          title: "Selected coupon deleted successfully",
          icon: "success"
        });
          if(localStorage.getItem('role') === 'LOCATIONMANAGER'){
            this.getCouponLocationMap();
          }
          else{
            this.getCouponData();
          }
        this.setState({
          selectAll: false,
          selectedArray: [],
          selected: {}
        });
      }
      else{
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
      });
      }    
    }
  });
  }
    
    render(){
        const listData = this.state.listData;
        return(
            <div className="coupon">
               <h3>Coupon</h3>
                {this.state.loader ? <Loader /> :
                <div>
                <div className="couponBox">
                {this.state.show ? this.editList() : ""}
                    <div className="couponButton">
                        <Button className="deleteAllBtn" disabled={this.state.selectedArray.length ? false : true} onClick={()=>this.deleteAllBtn()}>Delete</Button>
                        <Modal
                            showButton={true}
                            buttonTitle="Add Coupon"
                            className="addCouponsButton"
                            modalTitle="Add Coupon"
                            modalData= "addModalCoupon"
                            dialogClass="addModalCouponModal"
                            // query={saveCouponQuery}
                            updateData={()=>{
                              if(localStorage.getItem('role') === 'LOCATIONMANAGER'){
                                this.getCouponLocationMap();
                              }
                              else{
                                this.getCouponData();
                              }}}
                            />
                    </div>
                    <table className="couponTable">
            <tr>
                <th><input
                    className="PrivateSwitchBase-input-20"
                    name="checkedId"
                    type="checkbox"
                    checked={this.state.selectAll}
                    onChange={(e)=>this.onHandleAllCheckBox(e.target.checked)}
                    data-indeterminate="false"
                    value="" /></th>
                <th><span>Name</span></th>
                <th><span>Description</span></th>
                {/*<th><span>Title</span></th>*/}
                {/*<th><span>Category</span></th>*/}
                {/*<th><span>Amount</span></th>*/}
                {/*<th><span>Discount Amount</span></th>*/}
                {/*<th><span>Discount Percentage</span></th>*/}
                <th><span>Coupon Start Date</span></th>
                <th><span>Coupon End Date</span></th>
                <th><span>Location</span></th>
                <th><span>Edit</span></th>
                <th><span>Delete</span></th>
            </tr>
            {listData?.length > 0 ? listData?.map((ed, ex) => {
                    return (
                        <tr key={ex}>
                    <td><input
                    className="PrivateSwitchBase-input-20"
                    name="checkedId"
                    type="checkbox"
                    checked={this.state.selected[ed.id] === true}
                    onChange={(e)=>this.onHandleCheckBox(ed.id, ed)}
                    data-indeterminate="false"
                    value="" /></td>
                            <td>{ed?.couponname ?? "N/A"}</td>
                            <td>{ed?.description ?? "N/A"}</td>
                            {/*<td>{ed?.couponstitle ?? "N/A"}</td>*/}
                            {/*<td>{ed?.couponcategoryidmaprel?.Couponcategorymasters[0]?.categoryname ?? "N/A"}</td>*/}
                            {/*<td>{ed?.recentamount ?? "N/A"}</td>*/}
                            {/*<td>{ed?.discountedamount ?? "N/A"}</td>*/}
                            {/*<td>{ed?.discountedpercentage ?? "N/A"}</td>*/}
                            <td>{ed?.startdate ? moment(ed.startdate).format('MM/DD/YYYY') : "N/A"}</td>
                            <td>{ed?.enddate ? moment(ed.enddate).format('MM/DD/YYYY') : "N/A"}</td>
                            <td>{ed?.couponlocationmappingCouponidMap?.Couponlocationmappings?.length > 0 ?
                                ed?.couponlocationmappingCouponidMap?.Couponlocationmappings?.length == this.state.locCount ? "All Locations"
                                    :
                                ed?.couponlocationmappingCouponidMap?.Couponlocationmappings.map(locName =>`${locName.couponlocationmappinglocationidmaprel?.Locations[0]?.locationname},`)
                                :"N/A"}</td>
                            <td className="editIcon" onClick={()=>this.handleEditModal(ed)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
                            </td>
                            <td className="deleteIcon" onClick={()=>this.handleDelteItem(ed)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                            </td>
                        </tr>
                    )
                })
                :<div style={{paddingLeft:"10px",fontSize: "15px", fontWeight: "700"}}>
                No Data Found
            </div>
            }
        </table>
                </div>
                <div className="couponBox">
                <h3>Expired Coupons</h3>
                <table className="couponTable">
        <tr>
            <th><span>Name</span></th>
            <th><span>Description</span></th>
            {/*<th><span>Title</span></th>*/}
            {/*<th><span>Category</span></th>*/}
            {/*<th><span>Amount</span></th>*/}
            {/*<th><span>Discount Amount</span></th>*/}
            {/*<th><span>Discount Percentage</span></th>*/}
            <th><span>Coupon Start Date</span></th>
            <th><span>Coupon End Date</span></th>
            <th><span>Location</span></th>
            <th><span>Edit</span></th>
            <th><span>Delete</span></th>
        </tr>
        {this.state.expiredListData?.length > 0 ? this.state.expiredListData?.map((ed, ex) => {
                return (
                    <tr key={ex}>
                        <td>{ed?.couponname ?? "N/A"}</td>
                        <td>{ed?.description ?? "N/A"}</td>
                        {/*<td>{ed?.couponstitle ?? "N/A"}</td>*/}
                        {/*<td>{ed?.category ?? "N/A"}</td>*/}
                        {/*<td>{ed?.recentamount ?? "N/A"}</td>*/}
                        {/*<td>{ed?.discountedamount ?? "N/A"}</td>*/}
                        {/*<td>{ed?.discountedpercentage ?? "N/A"}</td>*/}
                      <td>{ed?.startdate ? moment(ed.startdate).format('MM/DD/YYYY') : "N/A"}</td>
                      <td>{ed?.enddate ? moment(ed.enddate).format('MM/DD/YYYY') : "N/A"}</td>
                        <td>{ed?.couponlocationmappingCouponidMap?.Couponlocationmappings?.length > 0 ?
                            ed?.couponlocationmappingCouponidMap?.Couponlocationmappings?.length == this.state.locCount ? "All Locations"
                                :
                                ed?.couponlocationmappingCouponidMap?.Couponlocationmappings.map(locName =>`${locName.couponlocationmappinglocationidmaprel?.Locations[0]?.locationname},`)
                            :"N/A"}</td>
                        <td className="editIcon" onClick={()=>this.handleEditModal(ed)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
                    </td>
                    <td className="deleteIcon" onClick={()=>this.handleDelteItem(ed)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                    </td>
                    </tr>
                )
            })
            :<div style={{paddingLeft:"10px",fontSize: "15px", fontWeight: "700"}}>
            No Data Found
        </div>
        }
    </table>
    </div>
      </div>
  }
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    sideBarPath: route =>
      dispatch({ type: "Show_Sidebar_Route", payload: route })
  };
};

export default connect(null,mapDispatchToProps)(withRouter(Coupon));