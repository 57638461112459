import gql from "graphql-tag";

export const saveLocationQuery = gql`
mutation saveLocation($obj: LocationInput!){
  saveLocation(obj:$obj){
    id
  }
}
`; 

export const manageLocationQuery = (offset, limit) => `
query{
    allLocations(where:{order:"id desc"},first:${offset || null}, last:${limit || null}){
      totalCount
      Locations{
        active
        locationlogo
        id
        locationname
        locationaddress
      }
    }
  }
`;

export const getLocationDetail = id => `
query{
  allLocations(where:{id: ${id}}){
    Locations{
      active
      locationlogo
      id
      lat
      lng
      city
      state
      zipcode
      country
      locationname
      locationaddress
      googlereviewurl
        facebookurl
        phoneno
    }
  }
}
`;
export const saveWebURLQuery =`
mutation saveWebviewurl($obj:WebviewurlInput!){
  saveWebviewurl(obj:$obj){
    id
  }
}
`;
export const getWebURLByLocationID = `
query allWebviewurls($where:JSON){
  allWebviewurls(where:$where){
     Webviewurls{
      id
      locationid
      meanuurl
      ordernowurl
      specialurl
    }
  }
}
`;