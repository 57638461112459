import React, { Component } from "react";
import { Button, TextField, Link, Grid, Container, InputAdornment, IconButton } from "@material-ui/core";
import "./login.css";
import {API_URL} from "../Config";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import {
  VisibilityOutlined,
  VisibilityOffOutlined
} from "@material-ui/icons";
import {API} from "../service";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      resetEmail: localStorage.getItem('resetEmail') || "error",
      errors:{
        newPassword:"",
        confirmPassword:""
      },
      showConfirmPassword: false,
      showPassword: false
    };
  }
    handleChange = (e) => {
    if (e.target.id === "newPassword") {
      this.setState({ newPassword: e.target.value });
    }

    if (e.target.id === "confirmPassword") {
      this.setState({ confirmPassword: e.target.value });
    }
  };
  validateForm = (newPassword, confirmPassword) => {
    const { errors } = this.state;
    const validNewPasswordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,32}$/);
    const validConfirmPasswordRegwx = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,32}$/);
    errors.newPassword = validNewPasswordRegex.test(newPassword) ? "" : "New password should contain one uppercase, one lowercase, one special character, min 6 char & max 32 chars";
    errors.confirmPassword = newPassword !== confirmPassword ? "New Password and Confirm Password did not match." : 
    !validConfirmPasswordRegwx.test(confirmPassword) ? "Confirm password should contain one uppercase, one lowercase, one special character, min 6 char & max 32 chars" : ""
    this.setState({ errors });
    return this.state.errors.newPassword === "New password should contain one uppercase, one lowercase, one special character, min 6 char & max 32 chars" ||
      this.state.errors.confirmPassword ===
      "Confirm password should contain one uppercase, one lowercase, one special character, min 5 char & max 32 chars" ||
      this.state.errors.confirmPassword === "New password and Confirm password did not match."
      ? false
      : true;
  };
checkInput = () =>{
    var status = false,
        errorMsg = ""
    if(!!this.state.newPassword && !!this.state?.confirmPassword){
        if(this.state?.newPassword === this.state?.confirmPassword){
            const newPass = this.state?.newPassword ?? null,
                conPass = this.state?.confirmPassword ?? null
            if(this.validateForm(newPass, conPass)){
                status = true
                errorMsg = ""
            }else{
                status = false
                errorMsg = "validation failed"
            }
        }else{
         errorMsg = "New And Confirm Password Didn't Matched"
         status = false
        }
    }else{
        errorMsg  = "Please Enter New And Confirm Password"
        status = false
    }
    return {status,  errorMsg}
}
  onSubmit = async (e) => {
    e.preventDefault();
    const statusCheck = this.checkInput()
    if(statusCheck.status){
        debugger
      // swal({
      //   icon: "warning",
      //   title: "Please enter new password"
      // });
          const body = {
            "emailId" :this.state.resetEmail || "error",
            "newPassword" : this.state.newPassword
          }
        const res = await API.resetPassword(body)
            if(res){
                debugger
          this.setState({connectionError: ""});
          if(res.response.statusCode === 200){
            swal({
                  icon: "success",
                  title: res.response.msg
                 }).then(()=>{
                 window.location = "/";
              });
          }
          else {
              swal({
                    icon: "warning",
                    title:res.response.msg
                    });
                  }
        }else{
          //$$$$
                swal({
                    icon: "warning",
                    title:"Server Error"
                });
            }
          // this.setState({
          //   connectionError: e.toString().substring(11, e.length),
          // });
    }else{
        swal({
            icon: "warning",
            title: statusCheck.errorMsg || "Error"
        });
    }
  };

  
  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  handleClickShowNewPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
   };

   handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
   };

  render() {
      console.log("propss===>>>",this.props)
    return (
      <div className="login loginFormOuterContainer">
        <div className="loginFromContainer">
          <Container className="loginContainerwhite">
            <div>
              <div className="logo">
                {/* <img src={logo} alt="Chanticlear"></img> */}
              </div>
              <div className="forgotMidSection">
                <h4>Reset Your Password</h4>
              </div>
              <form noValidate onSubmit={this.onSubmit}>
                <TextField
                  value={this.state.resetEmail || "error"}
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  autoComplete="email"
                  disabled
                  autoFocus
                />
                <TextField
                  className="newPassword"
                  margin="normal"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter new password"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.handleClickShowNewPassword()}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOutlined />
                          ) : (
                            <VisibilityOffOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    this.state.errors.newPassword ? this.state.errors.newPassword : ""
                  }
                />
                <TextField
                  className="confirmPassword"
                  margin="normal"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Enter confirm password"
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.handleClickShowConfirmPassword()}
                        >
                          {this.state.showConfirmPassword ? (
                            <VisibilityOutlined />
                          ) : (
                            <VisibilityOffOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    this.state.errors.confirmPassword ? this.state.errors.confirmPassword : ""
                  }
                />
                {this.state.connectionError ? (
                  <div className="connectionError">
                    <p>{this.state.connectionError}</p>
                  </div>
                ) : (
                  ""
                )}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                 Reset Password
                </Button>
                <Grid container className="backtologin">
                <Grid item xs>
                  <span> Don't have an account?</span>
                  <Link href="/" variant="body2">
                    Sign In
                  </Link>
                </Grid>
              </Grid>
              </form>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
