export const API_URL = "https://app.chanticlearpizza.com:8430";
export const ENV_Key = 'AIzaSyDLZrmJAX0Yf87HKcN4Uf80j-uKUU5yZyA'
export const URLDATA = {
  url: API_URL + "/graphql"
};
export const ImageUploadURL = {
  uploadFile: API_URL + "/api/Containers/images/upload",
  download: API_URL + "/api/Containers/images/download"
};
export const mapApiJs = "https://maps.googleapis.com/maps/api/js";
export const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";