import { Button, Container, Grid, TextField, InputAdornment, IconButton } from "@material-ui/core";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import swal from "sweetalert";
import Sidebar from "../SideBar/Sidebar";
import { withRouter } from 'react-router-dom'
import logo  from '../assets/ChanticlearLogo.png'
import "./login.css";
import {
  VisibilityOutlined,
  VisibilityOffOutlined
} from "@material-ui/icons";
import { LoginDropDown } from '../utils/utils'
import {API} from "../service";
import { Link } from 'react-router-dom';
import App from "../App";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      errors: {
        email: "",
        password: ""
      },
      dropdown: LoginDropDown,
      isActive: false
    };
  }

  componentWillUnmount(){
    this.setState({isActive: false})
  }

  componentDidMount(){
    const storageToken = localStorage.getItem("token");
    if (storageToken && storageToken !== "null") {
      return <Route component={App} path="/" />;
    }
  }

  handleChange = (e) => {
    if (e.target.id === "email") {
      this.setState({ email: e.target.value });
    }

    if (e.target.id === "password") {
      this.setState({ password: e.target.value });
    }
    if (e.target.name === "loginType") return this.setState({ [e.target.name]: e.target.value })
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  validateLogin = (email, password) => {
    const { errors } = this.state;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    // const validPasswordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,32}$/);
    errors.email = validEmailRegex.test(email.trim())
      ? ""
      : "Email is not valid!";
    // errors.password =
    //   validPasswordRegex.test(password.trim())
    //     ? ""
    //     : "Password should contain one uppercase, one lowercase, one special character, min 6 char & max 32 chars";
    this.setState({ errors });
    return this.state.errors.email === "Email is not valid!" 
      // this.state.errors.password ===
      // "Password should contain one uppercase, one lowercase, one special character, min 5 char & max 32 chars"
      ? false
      : true;
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isActive: true })
    if (this.state.email === "" || this.state.password === "" || !!this.state.loginType) {
      swal({
        icon: "warning",
        title: "Please fill credentials",
        className: "loginSwal",
      });
      this.setState({ isActive: false })
    } else if (this.validateLogin(this.state.email, this.state.password)) {
      var res = await API.login(this.state.email, this.state.password)
      debugger
      const error = !!res?.data?.error
        if(res?.status === 200 && error === false){
          if (res.data.token) {
            //redirecting to the user as it is location manager
            if (res?.data?.ispassword === 0){
              localStorage.setItem("resetEmail", this.state.email || "error")
              this.props.history.push({
                pathname: "/resetPassword",
                state: { email: this.state.email }
              })
            }
            else if (res?.data?.ispassword) {
              let isArray = [];
              res.data.LocationId.length > 0 && res.data.LocationId.map(el => isArray.push(el?.locationid || 0))
              localStorage.setItem("profileimage", res?.data?.profileimage);
              localStorage.setItem("token", res?.data?.token);
              localStorage.setItem("id", res?.data?.id);
              localStorage.setItem("role", res?.data?.role);
              localStorage.setItem("ispassword", res?.data?.ispassword);
              localStorage.setItem("locationid", JSON.stringify(isArray));
              localStorage.setItem(
                "name",
                `${res?.data?.firstname}` +
                `${res?.data?.lastname == null ? "" : " " + res?.data?.lastname}`
              );
              localStorage.setItem("email", res?.data?.email);
              if(res?.data?.role === "LOCATIONMANAGER"){
                this.props.history.replace("/pushNotification")
                window.location.reload()
              }
              else{
                this.props.history.replace("/pushNotification")
                window.location.reload()
                // this.props.history.push({
                //   pathname: '/pushNotification'
                // })
                // window.location.reload()
                // window.location = "/";
              }
              this.setState({ isActive: false })
            }
            else {
              this.props.history.push({
                pathname: "/resetPassword",
                state: { email: this.state.email },
              });
              this.setState({ isActive: false })
            }
          } else {
            this.setState({
              connectionError: res?.data?.displayMessage,
              isActive: false
            });
          }
        }else{
          this.setState({ isActive: false })
              swal({
                  title: "Invalid User",
                  icon: "error"
              });
        }
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    return (
      <div className="loginFormOuterContainer">
        <div className="loginFromContainer">
          <Container className="loginContainerwhite">
            <div className="logo" style={{textAlign:"center"}}>
               <img src={logo} alt="Chanticlear"></img>
            </div>
            <div>
              {/*<h2 className="title">Great Crust, Great Ingredients, Great Pizza!</h2>*/}
              <h4 className="heading">SIGN IN</h4>

              <form noValidate onSubmit={this.onSubmit}>
                <TextField
                  value={this.state.email}
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  autoComplete="off"
                  autoFocus
                  onChange={this.handleChange}
                  helperText={
                    this.state.errors.email ? this.state.errors.email : ""
                  }
                />

                <TextField
                  className="loginPassword"
                  margin="normal"
                  id="password"
                  name="password"
                  placeholder="Password"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.handleClickShowPassword()}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOutlined />
                          ) : (
                            <VisibilityOffOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  // helperText={
                  //   this.state.errors.password ? this.state.errors.password : ""
                  // }
                />

                {this.state.connectionError ? (
                  <div className="connectionError">
                    <p>{this.state.connectionError}</p>
                  </div>
                ) : (
                  ""
                )}
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    {/*<SelectDropDown*/}
                    {/*    style={{width:"100%",padding:"5px 0 5px 0"}}*/}
                    {/*    options={this.state.dropdown}*/}
                    {/*    value={this.state?.loginType}*/}
                    {/*    onChange={this.handleChange}*/}
                    {/*    name={'loginType'}*/}
                    {/*/>*/}
                  </div>
                  <div className={'col-md-6'} style={{ paddingRight: "0" }}>
                    <Grid container className="rememberpart">
                      <Grid item xs>
                        <Link to="/forgotpassword" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                    </Grid></div>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="loginbtn"
                >
                  {this.state.isActive ? "Loading..." : "Login"}
                </Button>
              </form>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
