import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import "./profileSetting.css";
import swal from "sweetalert";
import {API} from "../../service";
import { connect } from 'react-redux';
import {Loader} from "../../common/Loader";

class ProfileSetting extends Component{
    constructor(props){
        super(props);
        this.state={
            profileListData:[],
            phonenumber: "",
            loader: true,
            errors: {
              phonenumber: ""
            }
        }
    }

    componentDidMount(){
      this.props.sideBarPath("/profileSetting");
        this.getProfileData();
    }

    getProfileData = async () => {
      this.setState({loader : true})
      const res = await API.getUserCredential(parseFloat(localStorage.getItem("id")));
      if (res?.status == 200) {
        const responseData = res.data.data.allUserdata.Userdata.map(item=>{
          item.role = item.role === "LOCATIONMANAGER" ? "Location Manager" : item.role === "CUSTOMER" ? "Customer" : item.role === "SUPERADMIN" ? "Super Admin" : "";
          item["locationname"] = item?.userlocationmappingUseridMap?.Userlocationmappings.length > 0 ?
              item?.userlocationmappingUseridMap?.Userlocationmappings.map(el =>`${el?.userlocationmappinglocationidmaprel?.Locations[0]?.locationname || ""},`) : []
          return item;
      })
      this.setState({
          profileListData: responseData,
          firstname:responseData && responseData.length ? responseData[0]?.firstname ?? "error" : "error",
          lastname:responseData && responseData.length ? responseData[0]?.lastname ?? "error" : "error",
          phonenumber: responseData && responseData.length ? responseData[0].phonenumber : "",
          loader: false
      })
      } else {
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
      });
      this.setState({
        phonenumber: "",
        profileListData: [],
        loader: false
      });
      }
    }

    onHandleChange = event => {
        if(event.target.value?.length <= 10) return this.setState({[event.target.name]: event.target.value})
        swal({
            icon: "warning",
            title: "Enter Only 10 Digit",
            className: "loginSwal",
        });
    }

    validateForm = (phonenumber, firstName, lastName) => {
        debugger
      let error = this.state.errors,
             num = new RegExp(/^[0-9]*$/),
             char = new RegExp(/^[A-Za-z]+$/)
      error.phonenumber = !phonenumber ? "Please Enter phone number" : !num.test(phonenumber) ? "Please enter valid phone number" : "";
        error.firstname = !firstName ? "Please Enter First Name" : !char.test(firstName) ? "Please enter Only Character" : "";
        error.lastname = !lastName ? "Please Enter Last Name" : !char.test(lastName) ? "Please enter Only Character" : "";
        this.setState({errors: error});
      return this.state.errors.phonenumber === "Please enter phone number" || this.state.errors.phonenumber === "Please enter valid phone number" ||
             this.state.errors.firstname === "Please Enter First Name" || this.state.errors.firstname === "Please enter Only Character" ||
             this.state.errors.lastname === "Please Enter Last Name" || this.state.errors.lastname === "Please enter Only Character"
             ? false : true;
    }

    saveBtn = async () => {
        const status =  this.validateForm(this.state.phonenumber, this.state.firstname, this.state.lastname)
      if(status){
        let obj={
            id: this.state.profileListData && this.state.profileListData.length ?
                this.state.profileListData[0].id : parseFloat(localStorage.getItem("id")),
            phonenumber: this.state.phonenumber,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            updatedby: parseFloat(localStorage.getItem("id")),
            active: 1
        };
        const res = await API.saveUserData({obj});
        if (res?.status == 200) {
          this.setState({errors:{phonenumber: ""}})
          swal({
            title: "Profile updated successfully",
            icon: "success"
          });
          this.getProfileData();
        } else {
          swal({
            icon: "warning",
            title: "Server Error,Try Agian",
            className: "loginSwal",
        });
        this.setState({
          phonenumber: "",
          profileListData: []
        });
        }
      }
    }

    render(){
        const profileListData = this.state.profileListData;
        return(
            <div className="profileSetting">
               <h3>Profile Setting</h3>
               {this.state.loader ? <Loader /> : <div className="profileGroup">
                   <div className="form-group">
                        <label>First Name</label>
                        <input
                            value={this.state.firstname || ""}
                            type={"text"}
                            name={"firstname"}
                            disabled={false}
                            onChange={(e)=>this.onHandleChange(e)}

                        />
                       {this.state.errors && this.state.errors.firstname ? <span style={{color: "red", fontSize : "14px"}}>{this.state.errors.firstname}</span> : ""}
                   </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input
                            value={this.state.lastname || ""}
                            type={"text"}
                            name={"lastname"}
                            disabled={false}
                            onChange={(e)=>this.onHandleChange(e)}
                        />
                        {this.state.errors && this.state.errors.lastname ? <span style={{color: "red", fontSize : "14px"}}>{this.state.errors.lastname}</span> : ""}
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            value={profileListData && profileListData.length ? profileListData[0].email : ""}
                            type={"text"}
                            name={"email"}
                            disabled
                            />
                    </div>
                    <div className="form-group">
                        <label>Mobile Number</label>
                        <input
                            value={this.state.phonenumber ? this.state.phonenumber : ""}
                            type="text"
                            name="phonenumber"
                            id="phonenumber"
                            onChange={(e)=>this.onHandleChange(e)}
                         />
                         {this.state.errors && this.state.errors.phonenumber ? <span style={{color: "red", fontSize : "14px"}}>{this.state.errors.phonenumber}</span> : ""}
                    </div>
                    <div className="form-group">
                        <label>User Type</label>
                        <input
                            value={profileListData && profileListData.length ? profileListData[0].role : ""}
                            type={"text"}
                            name={"type"}
                            disabled
                             />
                    </div>
                    <div className="form-group">
                        <label>Location</label>
                        <input
                            value={profileListData && profileListData.length ? profileListData[0].locationname : ""}
                            type={"text"}
                            name={"location"}
                            disabled
                            />
                    </div>
                        <Button className="saveBtn" onClick={()=>this.saveBtn()}>Save</Button>
                        <Button style={{marginLeft:"10px"}} className="saveBtn" onClick={()=>this.props.history.push('/')}>Cancel</Button>
               </div> }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    sideBarPath: route =>
      dispatch({ type: "Show_Sidebar_Route", payload: route })
  };
};

export default connect(null,mapDispatchToProps)(ProfileSetting);