import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import {ImageUploadURL} from "../../Config"
import "react-alice-carousel/lib/alice-carousel.css";
import swal from "sweetalert";
import "./coverScreen.css";
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import {API} from "../../service";
import {Loader} from "../../common/Loader";

class CoverScreen extends Component{
    constructor(props){
        super(props);
        this.state={
            locationData: [],
            locationid: undefined,
            fileLength: 10,
            fileUploaded: false,
            index: 0,
            file: [],
            loader: true,
            dragStatus : false,
            dragAndDrop:{
                draggedFrom: null,
                draggedTo: null,
                isDragging: false,
                originalOrder: [],
                updatedOrder: []
            }
        }
    }

    componentDidMount(){
        this.props.sideBarPath("/coverScreen");
        this.getLocationData();
    }

    getLocationData = async () => {
      this.setState({loader: true})
          const test={
      where: {
        order: "locationname desc",
        active: 1,
      }
    }
    localStorage.getItem('role') === "LOCATIONMANAGER" && (test.where['and'] = [
      {
          "and": [{ "id": {"inq":JSON.parse(localStorage.getItem('locationid'))} }]
      }
  ])
    const resData = await API.getLocation(test);
    if(resData?.status === 200){
      let data = resData.data.data.allLocations.Locations.map(item => {
        return { id: item.id, name: item.locationname }
      })
      this.setState({locationData: data, loader: false});
    }
    else{
      swal({
        icon: "warning",
        title: "Server Error,Try Again",
        className: "loginSwal",
    });
    this.setState({locationData:[], loader: false}) 
    }
      }

      getImageQuery = async (value) => {
        const test={
          where: {
            locationid: parseFloat(value ? value : this.state.locationid),
            active: 1,
            order: "rankvlue"
          }
        }
        this.setState({loader: true})
        const resData = await API.imageDataQuery(test);
        if(resData?.status === 200){
          let data = resData.data.data.allCovers.Covers.map(item=>{
            return {id: item.id,
                name: item.covername,
                setmenuurl : item.setmenuurl,
                setorderurl : item.setorderurl,
                setspecialurl : item.setspecialurl,
                setsignupurl: item.setsignupurl
            }
          });
          this.setState({
            file: data,
            fileUploaded: false
          },()=>{
            this.setState({index: data.length ? 1 : 0, loader: false})
          });
        }
        else{
          swal({
            icon: "warning",
            title: "Server Error,Try Again",
            className: "loginSwal",
        });
        this.setState({ file: [], loader: false });
      }
}

      onHandleChange = (e) => {
        const {name, value} = e.target;
        if(value){
          this.getImageQuery(value);
        }
        this.setState({[name]: value})
      }

      onChangeFileHandler = (e)=> {
        if(this.state.locationid){
        if(e.target.files.length + this.state.file.length <= 10){
            var reader = new FileReader(),
                files = e.target.files
            reader.readAsDataURL(e.target.files[0])
            reader.onload =  (event) => {
                var image = new Image();
                image.src = event.target.result;
                image.onload = () => {
                    var height = image.height,
                        width = image.width
                    console.log("height width", height, width, files)
                        if(height > 440 && width > 660) {
                            for (let [key, value] of Object.entries(files)) {
                              value["preview"] = URL.createObjectURL(value);
                            }
                            this.setState({ file: [...this.state.file, ...files] , index: 1, fileUploaded: true })
                        }else{
                            swal({ title: "Please Upload The Image With Dimension Greater Than 440*660", icon: "warning" });
                        }
        }
            }
        }
        else{
            swal({ title: "Please select 10 image only", icon: "warning" });
        }
      }
      else{
        swal({ title: "Please select location", icon: "warning" });
      }
      }

  onSlideChanged = (e)=> {
    this.setState({index: e.item + 1});
  }

  getUploadedImage = (item, filename) => {
    let formData = new FormData();
    // let name = filename;
    // var newFile = new File([item], name, {
    //   type: item.type
    // });

    formData.append("file", item);
    let xhr = new XMLHttpRequest();

    xhr.open("POST", ImageUploadURL.uploadFile);

    xhr.send(formData);

    let fileName = "";
    xhr.onreadystatechange = function() {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status === 200) {
        fileName = JSON.parse(xhr.responseText).result.files.file;
      }
    };
  }

  saveBtn = async () => {
    var newFile = [...this.state.file];
    this.state.file.map(async (item,index)=> {
            if(!item?.id){
                let newFileName = new File([item], `${Date.now()}.${item.name.split(".")[1]}` , {
                    type: item.type
                });
                newFile[index] = newFileName
                this.getUploadedImage(newFileName, null);
            }
    })
      console.log("new filee===>>>", newFile)
        const InitialObj = [...newFile],
              finalobj=[]
        InitialObj.map((ex,edx) =>finalobj.push(
            {id:ex?.id || 0, imageName: ex?.name ?? "error", rank : edx}
            ))
        const test={
            locationId: parseInt(this.state.locationid),
            imageRankDetails : finalobj
        }
        const res = await API.saveCoverRest(test);
        if (res?.status == 200 && res?.statusText == "OK") {
            swal({
                title: "Image saved successfully",
                icon: "success"
            });
        }
        else {
            swal({
                icon: "warning",
                title: "Server Error,Try Again",
                className: "loginSwal",
            });
        }
  }

  handleDelete = () => {
    this.state.file.map((item,index)=> {
      if(parseFloat(index) === parseFloat(this.state.index) - 1){
        if(item.id){
    swal({
        title: "Are you sure you want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(async willDelete => {
        if(willDelete) {
          const test = {
            id: item.id,
            active: 0,
            locationid : parseInt(this.state.locationid)
          };
          const res = await API.saveCoverScreen({obj: test});
          if (res?.status == 200 && res?.data?.data?.saveCover?.id) {
            swal({
              title: "Image deleted successfully",
              icon: "success"
            });
            this.getImageQuery();
          } else {
            swal({
              icon: "warning",
              title: "Server Error,Try Again",
              className: "loginSwal",
          });
        }
      }
      })
    }
    else{
      swal({
        title: "Are you sure you want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if(willDelete) {
          this.state.file.splice(index, 1);
          this.setState({index: this.state.file.length})
        }
      });
    }
    }
  })
  }

    // onDragStart fires when an element
    // starts being dragged
    onDragStart = (event) => {
        const initialPosition = Number(event.currentTarget.dataset.position);
        const initial = {
                   ...this.state.dragAndDrop,
                    draggedFrom: initialPosition,
                    isDragging: true,
                    originalOrder: this.state.file
        }
        //if the comp is funcnal than this obj has to be set in set func
        this.setState({dragAndDrop : initial})


        // Note: this is only for Firefox.
        // Without it, the DnD won't work.
        // But we are not using it.
        event.dataTransfer.setData("text/html", '');
    }

    // onDragOver fires when an element being dragged
    // enters a droppable area.
    // In this case, any of the items on the list
    onDragOver = (event) => {

        // in order for the onDrop
        // event to fire, we have
        // to cancel out this one
        event.preventDefault();

        let newList = this.state.dragAndDrop.originalOrder;

        // index of the item being dragged
        const draggedFrom = this.state.dragAndDrop.draggedFrom;

        // index of the droppable area being hovered
        const draggedTo = Number(event.currentTarget.dataset.position);

        const itemDragged = newList[draggedFrom];
        const remainingItems = newList.filter((item, index) => index !== draggedFrom);

        newList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
        ];

        if (draggedTo !== this.state.dragAndDrop.draggedTo){
            const initial = {
                    ...this.state.dragAndDrop,
                    updatedOrder: newList,
                    draggedTo: draggedTo
            }
            //if the comp is funcnal than this obj has to be set in set func
            this.setState({dragAndDrop : initial})
            // setDragAndDrop({
            // })
        }

    }

    onDrop = async (event) => {
            this.setState({file : this.state.dragAndDrop.updatedOrder})
        // setList(dragAndDrop.updatedOrder);
            const initial = {
                    ...this.state.dragAndDrop,
                    draggedFrom: null,
                    draggedTo: null,
                    isDragging: false
            }
        //if the comp is funcnal than this obj has to be set in set func
            this.setState({dragAndDrop : initial})
        // setDragAndDrop({
        // });
            this.setState({dragStatus : true})
            const cloneDrag =[...initial.updatedOrder]
            cloneDrag.map(async (item,index)=> {
                if(!item?.id){
                    let newFileName = new File([item], `${Date.now()}.${item.name.split(".")[1]}` , {
                        type: item.type
                    });
                    cloneDrag[index] = newFileName
                    this.getUploadedImage(newFileName, null);
                }
            })
            console.log("new filee===>>>", cloneDrag)
            const InitialObj = [...cloneDrag],
                finalobj=[]
            InitialObj.map((ex,edx) =>finalobj.push(
                {id:ex?.id || 0, imageName: ex?.name ?? "error", rank : edx}
            ))
            const test={
                locationId: parseInt(this.state.locationid),
                imageRankDetails : finalobj
            }
            const res = await API.saveCoverRest(test);
            if (res?.status == 200 && res?.statusText == "OK") {
                this.setState({dragStatus : false})
                swal({
                    title: "image drag saved successfully",
                    icon: "success"
                });
            }
            else {
                swal({
                    icon: "warning",
                    title: "Server Error,Try Again",
                    className: "loginSwal",
                });
            }
    }


    onDragLeave = () => {
        const initial = {
            ...this.state.dragAndDrop,
            draggedTo: null
        }
        //if the comp is funcnal than this obj has to be set in set func
        this.setState({dragAndDrop : initial})

    }
    setUrl = async (e, value) =>{
        if(!value?.id) return swal({title: "Save the image first", icon: "warning", dangerMode: true})
        var name = e.target.value
        const status = await swal({
            title: "Are you sure you want to set the url?",
            icon: "warning",
            buttons: true,
            dangerMode: true
        })
        if(status) {
            const test = {
                id: value.id,
                setmenuurl : name === "menu" ? 1 : 0,
                setorderurl : name === "order" ? 1 : 0,
                setsignupurl : name === "signup" ? 1 : 0,
                setspecialurl: name === "special" ? 1 : 0
                // locationid : parseInt(this.state.locationid)
            };
            const res = await API.saveCoverScreen({obj: test});
            if (res?.status == 200 && res?.data?.data?.saveCover?.id) {
                swal({
                    title: "url set successfully",
                    icon: "success"
                });
                this.getImageQuery();
            } else {
                swal({
                    icon: "warning",
                    title: "Server Error,Try Again",
                    className: "loginSwal",
                });
            }
        }
    }
    render(){
        return(
            <div className="userManagement">
                <h3>Cover Screens</h3>
                {this.state.loader ? <Loader /> : <div className="cover-form-wrapper">
                    <div className="block-left">
                        <div className="form-group">
                            <label>Select Location</label>
                            <select
                              name="locationid"
                              value={this.state.locationid ? this.state.locationid : ""} 
                              onChange = {e=> this.onHandleChange(e)}>
                                <option value="">Select</option>
                                {this.state.locationData?.length > 0 ? this.state.locationData?.map((ed,ex)=>{
                                    return <option value={ed.id} key={ex}>{ed.name}</option>
                                 }):<option value="">No Result</option>}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="btn chooseImage">Upload</label>
                            <div className="fileupload-Btn">
                                <input name='filename' multiple accept="image/png, image/gif, image/jpeg" type="file"
                                onChange={(e)=>this.onChangeFileHandler(e)}
                                id='uploadFile'/>
                                <div className="flexBox">
                                    <label for="uploadFile">Choose File</label> {this.state.fileUploaded ? <span>File uploaded successfully</span> : <span>No File Choosen</span>}
                                </div>
                                <span></span>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                {this.state.file.length > 0 &&
                                <h3 style={{textAlign:"center"}}>Drag Vertically To Adjust The Cover Screen</h3>
                                }
                                <ul style={{listStyleType:"none"}}>
                                {this.state.file.map((item, index) => {
                                        {console.log("itemmm===>>>>", item)}
                                        return (
                                            <div className={'col-md-12'}>
                                                <li
                                                    key={index}
                                                    data-position={index}
                                                    draggable
                                                    onDragStart={this.onDragStart}
                                                    onDragOver={this.onDragOver}
                                                    onDrop={this.onDrop}
                                                    onDragLeave={this.onDragLeave}
                                                    className={this.dragAndDrop && this.dragAndDrop.draggedTo === Number(index) ? "dropArea" : ""}
                                                >
                                                    <div className={'row'}>
                                                    <div className={'col-md-6'}>
                                                    <div className="img-card iCard-style1">
                                                        <div className="card-content">
                                                            <div className="card-image">
                                                                {/*<span className="card-title">Cloud Beauty</span>*/}
                                                                <img
                                                                    style={{width:"100%", height:"50%", cursor:"move"}}
                                                                    src={item && item.id ? ImageUploadURL.download + "/" + item.name : item.preview}
                                                                    // src="https://www.dropbox.com/s/u330jm6faybxrvb/fog-3461451_640.jpg?raw=1"
                                                                />
                                                            </div>
                                                            {/*<div className="card-text">*/}
                                                            {/*    <p>*/}
                                                            {/*        Lorem Ipsum is simply dummy text of the printing and*/}
                                                            {/*        typesetting industry. Image by <a*/}
                                                            {/*        href="https://pixabay.com/users/Hans-2/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3461451"*/}
                                                            {/*        style="color:#795548">Hans Braxmeier</a> from <a*/}
                                                            {/*        href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3461451"*/}
                                                            {/*        style="color:#795548">Pixabay</a>*/}
                                                            {/*    </p>*/}
                                                            {/*</div>*/}

                                                        </div>
                                                        {/*<div className="card-link">*/}
                                                        {/*    <a href="#" title="Read Full"><span>Read Full</span></a>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    </div>
                                                        <div className={'col-md-6'} style={{textAlign:"center"}}>
                                                            <div className="form-group-checkbox">
                                                                {/*<h2></h2>*/}
                                                                <div className={'col-md-12'}>
                                                                    <input checked={item.setmenuurl} onClick={(e)=>this.setUrl(e, item)} name={`url${Math.random()}`} value={'menu'}   type="radio"  />
                                                                    <label  >Set Menu URL</label>
                                                                </div>
                                                                <div className={'col-md-12'}>
                                                                    <input checked={item.setorderurl}  onClick={(e)=>this.setUrl(e, item)} name={`url${Math.random()}`} value={'order'}   type="radio" />
                                                                    <label  >Set Order URL</label>
                                                                </div>
                                                                <div className={'col-md-12'}>
                                                                    <input style={{marginLeft:"8px"}} checked={item.setspecialurl}  onClick={(e)=>this.setUrl(e, item)} name={`url${Math.random()}`} value={'special'}   type="radio" />
                                                                    <label  >Set Special URL</label>
                                                                </div>
                                                                <div className={'col-md-12'}>
                                                                    <input style={{marginLeft:"37px"}} checked={item.setsignupurl}  onClick={(e)=>this.setUrl(e, item)} name={`url${Math.random()}`} value={'signup'}  type="radio" />
                                                                    <label  >Set Registration URL</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            {/*<li*/}
                                            {/*    style={{listStyleType:"none"}}*/}
                                            {/*    key={index}*/}
                                            {/*    data-position={index}*/}
                                            {/*    draggable*/}
                                            {/*    onDragStart={this.onDragStart}*/}
                                            {/*    onDragOver={this.onDragOver}*/}
                                            {/*    onDrop={this.onDrop}*/}
                                            {/*    onDragLeave={this.onDragLeave}*/}
                                            {/*    className={this.dragAndDrop && this.dragAndDrop.draggedTo === Number(index) ? "dropArea" : ""}*/}
                                            {/*>*/}
                                                {/*<div className={'col-md-12'}>*/}
                                                {/*    <img*/}
                                                {/*    style={{width:"100%", height:"50%", cursor:"move"}}*/}
                                                {/*    src={item && item.id ? ImageUploadURL.download + "/" + item.name : item.preview}*/}
                                                {/*/>*/}
                                                {/*</div>*/}
                                                {/*<div className={'col-md-6'}><p>{item.name}</p></div>*/}
                                                {/*<i className="fas fa-arrows-alt-v"></i>*/}
                                            {/*</li>*/}
                                            </div>
                                        )
                                }
                                        )
                                }
                                </ul>
                            </div>

                            {/*{this.state.file.map((item,index)=>{*/}
                            {/*    return(*/}
                            {/*        <div className={'col-md-12'} style={{border:"1px solid grey",marginBottom:"10px"}}>*/}
                            {/*            <div className={'col-md-4'}>*/}
                            {/*                <img style={{width:"50%",height:"50%"}} src={item && item.id ? ImageUploadURL.download + "/" + item.name : item.preview} />*/}
                            {/*            </div>*/}
                            {/*            <div className={'col-md-8'} style={{paddingTop:"20px"}}><h2>{item?.name.length > 10 ? item?.name.slice(0,10) + "..." : item.name}</h2></div>*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*})}*/}
                        </div>

                    </div>
                    <div className="block-right">
                        <h2>Images</h2>
                        <div className="imageslide-box">
                            <AliceCarousel 
                             onSlideChanged={this.onSlideChanged}
                            >
                              {this.state.file.map((item,index)=>{
                                return <img src={item && item.id ? ImageUploadURL.download + "/" + item.name : item.preview} className="sliderimg"/>
                              })}
                            </AliceCarousel>
                        </div>
                        <div className="bottom-detail">
                            <h4>{this.state.index} out of {this.state.fileLength}</h4>
                            <div className="button-box">
                                <Button className="btn" disabled={this.state.file && this.state.file.length ? false : true} onClick={()=>this.saveBtn()}>Save Image</Button>
                                <Button className="btn" disabled={this.state.file && this.state.file.length ? false : true} onClick={()=>this.handleDelete()}>Delete</Button>
                            </div>
                        </div>
                    </div>
                            </div> }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
      sideBarPath: route =>
        dispatch({ type: "Show_Sidebar_Route", payload: route })
    };
  };

export default connect(null,mapDispatchToProps)(withRouter(CoverScreen));