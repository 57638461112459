import axios from 'axios'
import {API_URL, ImageUploadURL, URLDATA} from './Config'
import * as User from  './Query/User/Query'
import * as Location from './Query/Location/Query'
import * as Notifications from './Query/Notification/Query'
import * as ManageLocation from './Components/ManageLocation/ManageLocationQuery'
import * as ProfileSetting from './Query/ProfileSetting/Query'
import * as CovererScreen from './Query/CoverScreen/Query'
import * as Coupon from './Query/Coupon/Query'
import {manageLocationQuery, saveLocationQuery} from "./Components/ManageLocation/ManageLocationQuery";
import * as Analytics from "./Query/Analytics/Query";
import * as Activity from "./Query/Activity/Query";
//function for post request with authToken
async function postData(url, body) {
    try {
        let _r = await axios.post(url, body, {
            headers: {
                access_token: localStorage.getItem("token"),
            },
        });
        return _r;
    } catch (e) { }
}
//function for post request with authToken
async function postDataFetch(url, body) {
    try {
        let _r = await axios.post(url, body, {
            headers: {
                access_token: localStorage.getItem("token"),
            },
        });
        return _r.data;
    } catch (e) { }
}
export const API = {
    //api call for login user
    login: async (email, password) => {
        const url =
            API_URL +  `/login?email=${email}&password=${password}`;
        let r = await postData(url);
        return r;
    },
    //api call for forgotPassword user
    forgotPassword: async (body) => {
        const url =
            API_URL +  `/api/Userdata/forgotPassword`;
        let r = await postData(url, body);
        return r;
    },
    //api call for matchPassword user
    matchPassword: async (body) => {
        const url =
            API_URL +  `/api/Userdata/matchPasscord`;
        let r = await postDataFetch(url, body);
        return r;
    },
    //api call for resetPassword user
    resetPassword: async (body) => {
        const url =
            API_URL +  `/api/Userdata/resetPassword`;
        let r = await postDataFetch(url, body);
        return r;
    },
    //api call for creating new user
    createUser: async (body) => {
             const url =
                 API_URL +  `/api/Userdata/createUser`;
             let r = await postData(url, body);
             return r;
         },
    //api call for uploading image
    ImageUpload: async (body) => {
        const url = ImageUploadURL.uploadFile;
        let r = await postData(url, body);
        return r;
    },
    //api call for deleting the selected coupon
    deleteAllQuery: async (body) => {
        const url =
            API_URL +  `/api/Coupons/deleteCoupon`;
        let r = await postData(url, body);
        return r;
    },
    //api call for saving notification
    saveRestNotification: async (body) => {
        const url =
            API_URL +  `/api/Notifications/saveNotoficationDetails`;
        let r = await postData(url, body);
        return r;
    },
    //api call for analyytics
    getAnalytics: async (body) => {
        const url =
            API_URL + `/api/Orders/allAnalyticData`;
        let r = await postData(url, body);
        return r;
    },
    //api call for analyytics
    getNotificationRest: async (body) => {
        const url =
            API_URL + `/api/Notifications/allNotificationdata`;
        let r = await postData(url, body);
        return r;
    },
    //api call for analyytics
    getUserRest: async (body) => {
        const url =
            API_URL + `/api/Userdata/allCustomerdata`;
        let r = await postData(url, body);
        return r;
    },
    //api call for saving Cover Screen Image
    saveCoverRest: async (body) => {
        const url =
            API_URL + `/api/Covers/saveCoverDetails`;
        let r = await postData(url, body);
        return r;
    },
    //api calls for coupon location mapping
    getCouponLocationMapAPI: async ( body ) => {
        const url =
        API_URL + `/api/Coupons/allCouponData`;
        let r = await postData(url, body);
        return r;
    },
    //api call for user
        getUser: async (body, offset, limit) => {
            const url = URLDATA.url,
                  query = {
                query: User.getAllUserQuery(offset, limit),
                variables: body
            };
            let r = await postData(url, query);
        return r;
    },
    //api for editing user
    saveUserData: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: User.saveUserData,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //api for  editing the usermap location data
    saveUserMapData: async (queryApi) => {
        const url = URLDATA.url,
        query = {
            query: queryApi,
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //function for getting user by id
    getUserDataByID: async (id) => {
    const url = URLDATA.url;
    const query = {
      query: User.getUserByID(id),
    };
    let r = await postData(url, query);
    return r;
    },
    //function for getting login details
    getUserCredential: async (id) => {
        const url = URLDATA.url;
        const query = {
          query: ProfileSetting.getUserDetails(id),
        };
        let r = await postData(url, query);
        return r;
    },
    //api call for location
    getLocation: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Location.locationDataQuery,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //api call for Actions
    allActions: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.getAllActions,
                variables: null
            };
        let r = await postData(url, query);
        return r;
    },
    //api call for Actions
    allTargetNotification: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.getTargetNotifications,
                variables: null
            };
        let r = await postData(url, query);
        return r;
    },
    //api call for Recurrences
    allRecurrences: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.getRecurrences,
                variables: null
            };
        let r = await postData(url, query);
        return r;
    },
    //api call for Recurrences
    saveNotification: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.saveNotification,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //api call for Recurrences
    saveNotificationMap: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.saveNotificationWithMap,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //Fetch to Axios

    //api call for location
    getManageLocation: async ( body,offset, limit ) => {
        const url = URLDATA.url,
            query = {
                query: ManageLocation.manageLocationQuery(offset, limit),
                variables: null
            };
        let r = await postDataFetch(url, query);
        return r;
    },
    //api call for saveLocation
    saveManageLocation: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: ManageLocation.saveLocationQuery,
                variables: body
            };
        let r = await postDataFetch(url, query);
        return r;
    },
    //api call for get Location
    getManageLocationDetail: async ( id ) => {
        const url = URLDATA.url,
            query = {
                query: ManageLocation.getLocationDetail(id),
                variables: null
            };
        let r = await postDataFetch(url, query);
        return r;
    },
    //api call for get notification by id
    getNotificationById: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.getNotification,
                variables: body
            };
        let r = await postDataFetch(url, query);
        return r;
    },
    //api call for get notification mapping by id
    getNotificationMappingById: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.getNotificationMapping,
                variables: body
            };
        let r = await postDataFetch(url, query);
        return r;
    },
    //function for getting the images in cover screen
    imageDataQuery: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: CovererScreen.getImageDataQuery,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //function for getting the notification in view notification screen
    getNotificationAPI: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Notifications.viewNotificationQuery,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //function used for getting the coupon API
    getCouponAPI: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Coupon.getCouponQuery ,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
      //api call for get user data by  user id
      getUserData: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Analytics.userData ,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //function for saving the cover screen data
    saveCoverScreen: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: CovererScreen.saveCoverScreen,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
     //api call for get user order by  user id
     getUserOrder: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Analytics.userOrder ,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //api call for get user coupon by  user id
    getUserCoupon: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Analytics.userCoupon ,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //function for saving the coupon data
    saveCouponData: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Coupon.saveCouponAPI,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //function for saving the coupon data
    saveCouponMapData: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Coupon.saveCouponMapAPI,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //function for saving the coupon location mapping
    saveCouponLocationData: async ( queryApi ) => {
        const url = URLDATA.url,
            query = {
                query: queryApi,
                variables: null
            };
        let r = await postData(url, query);
        return r;
    },
    //function for getting the data from id
    getCouponDataByID: async id => {
        const url = URLDATA.url;
        const query = {
          query: Coupon.getCouponById(id),
        };
        let r = await postData(url, query);
        return r;
    },
     //api call for get activity data
     getActivityData: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: Activity.saveActivityQuery ,
                variables: body
            };
        let r = await postData(url, query);
        return r;
    },
    //function for saving WebURL by Location id
    saveWebURLByLocationID: async body => {
        const url = URLDATA.url;
        const query = {
            query: ManageLocation.saveWebURLQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    //function for get WebURL by Location id
    getWebURLByLocationID: async body => {
        const url = URLDATA.url;
        const query = {
            query: ManageLocation.getWebURLByLocationID,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    //api call for location
    getUserEmail: async ( body ) => {
        const url = URLDATA.url,
            query = {
                query: User.getCustomerEmail("CUSTOMER"),
            };
        let r = await postData(url, query);
        return r;
    },
}