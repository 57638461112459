import { Component } from 'react';
import {connect} from "react-redux";

class Logout extends Component{
    componentDidMount() {
        this.props.sideBarPath("/logout");
        localStorage.clear()
        window.location = "/";
      }
    render(){
        return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
      sideBarPath: route =>
        dispatch({ type: "Show_Sidebar_Route", payload: route })
    };
  };

export default connect(null,mapDispatchToProps)(Logout);