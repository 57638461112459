import React from "react";
import FormComponent from "./FormComponent";
import { Dialog } from "@material-ui/core";
import { withStyles, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CouponAddEdit from "../Components/Coupon/CouponAddEdit";
import "./Modal.css";
import propTypes from "prop-types";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: { }
}))(MuiDialogContent);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(
    props.showButton === false ? true : false
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    //to reset show to false upon close with transition
    setTimeout(() => {
      return props.resetModal ? props.resetModal() : null;
    }, 300);
  };

  const renderModalContent = props => {
    switch (props.modalData) {
      case "addModalCoupon" :
          return (
            <CouponAddEdit
              formValidation={props.formValidation}
              editableData={props.editableData}
              query={props.query}
              modalCloseCallback={handleClose}
              updateData={props.updateData}
               />
          );

      default:
        return (
          <FormComponent
            params={props.params}
            formConfig={props.formConfig}
            formValidation={props.formValidation}
            showContentOnForm={props.showContentOnForm}
            editableData={props.editableData}
            selectedData={props.selectedData}
            query={props.query}
            modalCloseCallback={handleClose}
            updateData={props.updateData}
            callBack={props.callBack}
            preSubmitChanges={props.preSubmitChanges}
            mutationParams={props.mutationParams}
            saveButtonTitle={props.saveButtonTitle}
            addMore={props.addMore}
            addMoreButtonTitle={props.addMoreButtonTitle}
          />
        );
    }
  };

  return (
    <div className={props.className}>
      {props.showButton ? (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
          startIcon={props.startIcon}
          disabled={props.disabled}
          endIcon={props.icon}
        >
          {props.buttonTitle}
        </Button>
      ) : null}

      <div>
        <Dialog
          className={
            props.dialogClass
              ? `${props.dialogClass} modalDialog`
              : "modalDialog"
          }
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <div className="Modal">
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <div className="ModalTitle">{props.modalTitle}</div>
            </DialogTitle>
            <DialogContent dividers>{renderModalContent(props)}</DialogContent>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

CustomizedDialogs.propTypes = {
  modalTitle: propTypes.string.isRequired,
  updateData: propTypes.func,
  preSubmitChanges: propTypes.func
};
