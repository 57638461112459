import React, {useEffect} from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  List,
  AppBar,
  Drawer,
} from "@material-ui/core";
import Advertisement from "../Advertisement/Advertisement";
import {
  routeConfigList,
  Routes
} from "./RouteContent";
import "./Sidebar.css";
import {ImageUploadURL} from "../Config"
import ChanticlearLogo from "../assets/ChanticlearLogo.png";
import { HashRouter as Router, Route, Link, Switch} from "react-router-dom";
import defaultImage from "../assets/defaultImage.jpg";
import {API} from "../service";
import swal from "sweetalert";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  sectionDesktop: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  headerShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    // width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingLeft: "20px",
    // padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar,
  },
  title: {
    flexGrow: 0,
    // padding: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
}));

function MiniDrawer(props) {
  const classes = useStyles();
  const [open, ] = React.useState(true);
  const [customClass, ] = React.useState(null);
  const [route, setRoute] = React.useState(routeConfigList)
  const [profileimage, setProfileImage] = React.useState(localStorage.getItem("profileimage"))
  useEffect(()=>{
   routeRestricted()
  },[])
  const routeRestricted = () =>{
    const type = localStorage.getItem('role')
    if(type === "LOCATIONMANAGER"){
      const route = routeConfigList.filter((el => (el?.role !== "LOCATIONMANAGER")))
      setRoute(route)
    }
  },
  upload = async (e, value) => {
    var status = false
    if(value == "add"){
      const file  = new File([e.target.files[0]],
          `${Date.now()}.${e.target.files[0].name.split(".")[1]}`,
          {type: e.target.files[0].type}),
          formData = new FormData()
      formData.append('file', file)
      const res = await API.ImageUpload(formData)
      if(res && res?.status === 200, res?.statusText == "OK"){
        status = true
        var imgProfile = res?.data?.result?.files?.file[0]?.name ?? null
      }else{
        status = false
        setProfileImage(null)
        swal({
          icon: 'warning',
          title: "Server Error,Try Again",
          className: "loginSwal",
        })
      }
    }else {
      status = true
    }
        if(status){
          debugger
          const  obj={
            id: parseInt(localStorage.getItem('id')),
            profileimage: imgProfile || null
          },
          res = await API.saveUserData({obj})
          if(res && res?.status == 200, res?.statusText == "OK"){
            setProfileImage(imgProfile || null)
            localStorage.setItem('profileimage',imgProfile || null)
            swal({
              icon: 'success',
              title: "Profile Updated Successfully",
              className: "loginSwal",
            })
          }else{
            swal({
              icon: 'error',
              title: "Error While Updating",
              className: "loginSwal",
            })
          }
        }
  }
  return (
    <Router>
      <div className={classes.root}>
        {/*<CssBaseline />*/}
        <div className="header">
          <AppBar
            position="fixed"
            className={
              (clsx(
                classes.appBar,
                {
                  [classes.appBarShift]: open,
                }
              ),
              open ? "headerShift" : "headerUnshift")
            }
          >
              {/*<Advertisement />*/}
          <div className="subHeadBanner">
             <img src={ChanticlearLogo} />
          </div>
          </AppBar>
        </div>
        <div className="Sidebar">
          <Drawer
            variant="persistent"
            // className={clsx(classes.drawer, {
            //   [classes.drawerOpen]: open,
            //   [classes.drawerClose]: !open,
            // })}
            // classes={{
            //   paper: clsx({
            //     [classes.drawerOpen]: open,
            //     [classes.drawerClose]: !open,
            //   }),
            // }}
            open={open}
            anchor="left"
          >
            <div className={`${classes.toolbar} profileflexBox`}>
                <div className="imgBox">
                <img src={profileimage && profileimage !== "null" ? ImageUploadURL.download +"/"+ profileimage : defaultImage} alt="No Image Found" />
                </div>
               <div className="data">
                <h5>{localStorage.getItem("name")}</h5>
                <span>{localStorage.getItem("email")}</span>
                </div>
            </div>
<div className={'row'}>
  <div className={'col-md-12'}>
    <div className={'col-md-4'} style={{ position: "relative", overflow: "hidden"}}>
      <button type={'button'}>upload</button>
     <input type="file" onChange={(e)=>upload(e, "add")} name="file" style={{position: "absolute",fontSize: "50px", opacity: 0, right: 0, top: 0}} /></div>
    <div className={'col-md-6'}><button onClick={(e)=>upload(e, "remove")} type={'button'}>Remove</button></div>
  </div>
</div>
            <div className="outerDivForLists">
              <List className="lists">
                {route.map((items, index) => (
                  <div key={index}>
                      <div
                        className={
                          props.sidebarRoute === items.path
                            ? "sidebarColor"
                            : ""
                        }
                      >
                        <span key={index}>
                          <ListItem
                            button
                            key={items.title}
                            component={Link}
                            to={`${items.path}`}
                          >
                            <ListItemIcon>
                              <span className="icons">{items.image} </span>
                            </ListItemIcon>
                            <ListItemText primary={items.title} />
                          </ListItem>
                        </span>
                      </div>
                  </div>
                ))}
              </List>
            </div>
          </Drawer>
          </div>
        </div>
        {/*  */}
        <div className={customClass ? customClass : "drawerOpenClass"}>
          <div>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {/* <div className={classes.toolbar} /> */}
              <Switch>
                {Routes.map((route, index, match) =>
                  route.type === "subListType" ? (
                    route.item.map((subitem, index) => (
                      <Route
                        key={index}
                        path={subitem.path}
                        exact={subitem.exact}
                        // component={subitem.main}
                        render={(e) => subitem.main(e, props)}
                      />
                    ))
                  ) : (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      // component={route.main}
                      render={(e) => route.main(e, props)}
                    />
                  )
                )}
              </Switch>
            </main>
          </div>
        </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    sidebarRoute: state.sidebarRoute
  };
};

export default connect(mapStateToProps,null)(MiniDrawer);
