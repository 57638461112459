import React, { Component } from "react";
import {
  TextField,
  Button,
  Input,
  Select,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import swal from "sweetalert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { API } from "../../service";
import './coupon.css';
import { ImageUploadURL } from "../../Config";
import moment from 'moment';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
class CouponAddEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponname: "",
      couponstitle: "Dummy Text",
      // recentamount: "",
      // discount: 0,
      // discountedamount: "",
      // discountedpercentage: "",
      startdate: null,
      enddate: null,
      description: "",
      isCopPicEdit: false,
      locationid: [],
      editLocationId: [],
      categoryid: 1,
      couponlogo: "",
      locationData: [],
      file: [],
      errorMessages: {
        startdate: "",
        enddate: "",
        couponname: "",
        couponstitle: "",
        recentamount: "",
        discountedamount: "",
        discountedpercentage: "",
        description: "",
        locationid: "",
        couponlogo: "",
        starttime:"",
        endtime:""
      },
      selectedLocName: [],//used for multiple checkbox dropdown
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.editableData !== undefined) {
      this.initializeForm();
    }
    this.getLocationData();
  }

  getLocationData = async () => {
    const test = localStorage.getItem("role") === "LOCATIONMANAGER" ?
      {
        where: {
          order: "locationname desc",
          active: 1,
          'and': [
            {
              "and": [{ "id": { "inq": JSON.parse(localStorage.getItem('locationid')) } }]
            }
          ]
        }
      } : {
        where: {
          order: "locationname desc",
          active: 1,
        }
      }
    const resData = await API.getLocation(test);
    if (resData?.status === 200) {
      let data = resData.data.data.allLocations.Locations.map(item => {
        return { id: item.id, name: item.locationname }
      })
      const addSel = { id: 100, name: "Select All" }
      data.unshift(addSel)
      this.setState({ locationData: data });
    }
    else {
      swal({
        icon: "warning",
        title: "Server Error,Try Agian",
        className: "loginSwal",
      });
      this.setState({ locationData: [] })
    }
  }

  initializeForm = () => {
    this.setState({
      couponname: this.props.editableData.couponname,
      // couponstitle: this.props.editableData.couponstitle,//commented as not required
      couponstitle: "Dry Text",
      // recentamount: this.props.editableData.recentamount,
      // discount: this.props.editableData.discountedamount ? 0 : 1,
      // discountedamount: this.props.editableData.discountedamount,
      // discountedpercentage: this.props.editableData.discountedpercentage,
      startdate: this.props.editableData.startdate,
      enddate: this.props.editableData.enddate,
      starttime: this.props.editableData.starttime,
      endtime: this.props.editableData.endtime,
      description: this.props.editableData.description,
      locationid: this.props.editableData.locationid,
      editLocationId: this.props.editableData.locationid,
      categoryid: this.props.editableData.categoryid,
      couponlogo: this.props.editableData.couponlogo,
      file: new File(["file"], this.props.editableData.couponlogo, {
        type: "text/plain",
      })
    });
  };

  handleChange = event => {
    this.validateChangeHandler(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDateChange = (name, time,date) => {
    debugger
    console.log('hhhhhhhhhhhhh',name, date,time)
    this.validateChangeHandler(name, date)
    this.setState({ [name]: date,
      [time]: `${moment(date).format("YYYY-MM-DD HH:MM A").toString().split(" ")[1]}`
    });
  };

  handleFilter = e => {
    const {
      target: { name, value }
    } = e;
    this.validateChangeHandler(name, value)
    this.setState({
      [name]: value
    });
  };
  calculateSize = (img, maxWidth, maxHeight) => {
    debugger
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  }
  readableBytes = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      display = (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    console.log("display compress image", display)
  }

  onChangeFileHandler = (e) => {
    let name = new Date().getTime() + e.target.files[0].name
    this.setState({ [e.target.name]: name })
    this.readableBytes(e.target.files[0].size)
    const blobURL = window.URL.createObjectURL(e.target.files[0]),
      img = new Image();
    img.src = blobURL;
    img.onerror = function () {
      URL.revokeObjectURL(this.src);
      // Handle the failure properly
      console.log("Cannot load image");
    };
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      const MAX_WIDTH = 320
      const MAX_HEIGHT = 180
      debugger
      const [newWidth, newHeight] = this.calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          // Handle the compressed image. es. upload or save in local state
          this.readableBytes(blob.size);
          // this.setState({[e.target.name]: name, file: e.target.files[0], isCopPicEdit : true})//earlier it was when image is not compressed
          this.setState({ file: blob, isCopPicEdit: true })
        },
        // MIME_TYPE,
        // QUALITY
      );
      // document.getElementById("root").append(canvas);
    };
  }

  validateChangeHandler = (name, value) => {
    const { errorMessages, discount } = this.state;
    if (name === 'couponname') {
      errorMessages.couponname = value.length == 0 ? "Please Enter Coupon Name." : ""
      // let re = new RegExp(/^[a-zA-Z0-9!@#$&^"{}()%-\\-`.+,/\_ ]*$/);
      // errorMessages.couponname = !re.test(value) ? "Coupon name should be alphanumeric only or special character too." : "";
    }
    else if (name === 'couponstitle') {
      let re = new RegExp(/^[0-9a-zA-Z!@#$&()\\-`.+,/\_ ]+$/);
      errorMessages.couponstitle = !re.test(value) ? "Coupon title should be alphanumeric only or special character too." : "";
    }
    else if (name === "description") {
      errorMessages.description = value.length == 0 ? "Please Enter Description." : ""
    }
    //     else if(name === "description"){
    //         errorMessages.description = value.length > 15 ?  "Description Cannot Be Greater Than 15 Character." : ""
    // } ===>>>>  remove as per client request
    // else if(name === 'recentamount'){
    // let numberregex = new RegExp(/^\d+(\.\d{1,2})?$/);
    // errorMessages.recentamount = !numberregex.test(value) ? "Recent amount should be numeric float only." : "";
    // }
    // else if(name === 'discountedamount' && discount === 0){
    // let numberregex = new RegExp(/^\d+(\.\d{1,2})?$/);
    // errorMessages.discountedamount = !numberregex.test(value) ? "Discounted amount should be numeric float only." : "";
    // }
    // else if(name === 'discountedpercentage' && discount === 1){
    //   let numberregex = new RegExp(/^\d+(\.\d{1,2})?$/);
    // errorMessages.discountedpercentage = !numberregex.test(value) ? "Discounted percentage should be numeric float only." : "";
    // }
    else if (name === 'startdate') {
      errorMessages.startdate = value ? "" : "Please enter start date.";
    }
    else if (name === 'enddate') {
      errorMessages.enddate = value ? "" : "Please enter end date.";
    }
    else if (name === 'locationid')
      errorMessages.locationid = value ? "" : "Please select location.";
    this.setState({ errorMessages });
    return this.state.errorMessages.couponname === "Please Enter Coupon Name."
      || this.state.errorMessages.locationid === "Please select location."
      || this.state.errorMessages.enddate === 'Please enter end date.' ||
      this.state.errorMessages.startdate === 'Please enter start date.'
      || this.state.errorMessages.discountedamount === "Please enter discounted amount."
      || this.state.errorMessages.discountedamount === "Discounted amount should be numeric float only." || this.state.errorMessages.discountedpercentage === 'Please enter discounted percentage.' || this.state.errorMessages.recentamount === 'Recent amount should be numeric float only.' ||
      this.state.errorMessages.recentamount === 'Please enter recent amount.' || this.state.errorMessages.couponstitle === 'Coupon title should be alphanumeric only or special character too.' || this.state.errorMessages.couponstitle === 'Please enter coupon title.'
      || this.state.errorMessages.description === "Please Enter Description."
      // || this.state.errorMessages.description === 'Description Cannot Be Greater Than 15 Character.' ===>>> remove as per client request
      ? false
      : true;
  }

  validationSubmit = () => {
    const { errorMessages, discount, couponname, couponstitle, description, recentamount, discountedamount, discountedpercentage, startdate, enddate, locationid } = this.state;
    errorMessages.couponname = !couponname ? "Please enter coupon name." : "";
    errorMessages.couponstitle = !couponstitle ? "Please enter coupon title." : "";
    errorMessages.description = !description ? "Please enter coupon description." :
      // description.length > 15 ? "Description cannot be greater than 15 character." : ===>>>> change it as per client request
      "";
    // errorMessages.recentamount = !recentamount ? "Please enter recent amount." : "";
    // if(discount === 0){
    // errorMessages.discountedamount = !discountedamount ? "Please enter discounted amount." : (parseInt(discountedamount) >  parseInt(recentamount)) ? "Discount amount cannot be greater than Amount." : "" ;
    // }
    // else if(discount === 1){
    //   errorMessages.discountedpercentage = !discountedpercentage ? "Please enter discounted percentage." : discountedpercentage > 100 ? "Discount Percentage Cannot Be Greater Than 100." :"" ;
    // }
    errorMessages.startdate = startdate ? "" : "Please enter start date.";
    errorMessages.enddate = enddate ? "" : "Please enter end date.";
    errorMessages.locationid = locationid ? "" : "Please select location.";
    this.setState({ errorMessages });
    return this.state.errorMessages.couponname === "Please enter coupon name." || this.state.errorMessages.locationid === "Please select location." ||
      this.state.errorMessages.enddate === 'Please enter end date.' || this.state.errorMessages.startdate === 'Please enter start date.' || this.state.errorMessages.discountedamount === "Please enter discounted amount."
      || this.state.errorMessages.discountedpercentage === 'Please enter discounted percentage.' || this.state.errorMessages.recentamount === 'Please enter recent amount.' || this.state.errorMessages.couponstitle === 'Please enter coupon title.'
      || this.state.errorMessages.discountedpercentage === 'Discount Percentage Cannot Be Greater Than 100.'
      || this.state.errorMessages.discountedamount === "Discount amount cannot be greater than Amount."
      || this.state.errorMessages.description === "Please enter coupon description."
      // || this.state.errorMessages.description === "Description cannot be greater than 15 character."// ===>>> not in use as client change it
      ? false
      : true;
  }

  getUploadImage = (item, itemName) => {
    let formData = new FormData();
    let name = itemName;
    var newFile = new File([item], name, {
      type: item.type
    });

    formData.append("file", newFile);
    let xhr = new XMLHttpRequest();

    xhr.open("POST", ImageUploadURL.uploadFile);

    xhr.send(formData);

    let fileName = "";
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status === 200) {
        fileName = JSON.parse(xhr.responseText).result.files.file;
      }
    };
  }

  saveCouponBtn = async () => {
    if (this.validationSubmit()) {
      debugger
      const test = this.props.editableData && this.props.editableData.id ? {
        "id": this.props.editableData.id,
        "couponname": this.state.couponname,
        "couponstitle": this.state.couponstitle,
        // "recentamount": parseFloat(this.state.recentamount),
        // "discountedamount": this.state.discountedamount ? parseFloat(this.state.discountedamount) : 0,
        // "discountedpercentage": this.state.discountedpercentage ? parseFloat(this.state.discountedpercentage) : 0,
        "startdate": this.state.startdate ? moment(this.state.startdate).format('YYYY-MM-DD') : "",
        "enddate": this.state.enddate ? moment(this.state.enddate).format('YYYY-MM-DD') : "",
        "starttime": this.state.starttime || "",
            // ? moment(this.state.starttime).format("YYYY-MM-DD HH:MM a").toString().split(" ")[1] : "",//not in use as it is already parse in onHandle
        "endtime": this.state.endtime || "",
            // ? moment(this.state.endtime).format("YYYY-MM-DD HH:MM a").toString().split(" ")[1] : "",//not in use as it is already parse in onHandle
        "description": this.state.description ? this.state.description : "",
        // "categoryid": this.state.categoryid ? parseFloat(this.state.categoryid) : 0,
        "categoryid": 1,
        "couponlogo": this.state.couponlogo ? this.state.couponlogo : "",
        "updatedby": parseFloat(localStorage.getItem("id"))
      } : {
        "couponname": this.state.couponname,
        // "couponstitle": this.state.couponstitle,//commented as no required
        couponstitle: "Dry Text",
        // "recentamount": parseFloat(this.state.recentamount),
        // "discountedamount": this.state.discountedamount ? parseFloat(this.state.discountedamount) : 0,
        // "discountedpercentage": this.state.discountedpercentage ? parseFloat(this.state.discountedpercentage) : 0,
        "startdate": this.state.startdate ? moment(this.state.startdate).format('YYYY-MM-DD') : "",
        "enddate": this.state.enddate ? moment(this.state.enddate).format('YYYY-MM-DD') : "",
        "starttime": this.state.starttime ? this.state.starttime : "",
        "endtime": this.state.endtime ? this.state.endtime : "",
        "description": this.state.description ? this.state.description : "",
        // "categoryid": this.state.categoryid ? parseFloat(this.state.categoryid) : 0,
        "categoryid": 1,
        "couponlogo": this.state.couponlogo ? this.state.couponlogo : "",
        "createdby": parseFloat(localStorage.getItem("id")),
        "updatedby": parseFloat(localStorage.getItem("id"))
      }
      this.state.isCopPicEdit && this.getUploadImage(this.state.file, this.state.couponlogo);
      const res = await API.saveCouponData({ obj: test })
      if (res?.status === 200 && res?.data?.data?.saveCoupon) {
        let query = '';
        let query1 = '';
        if (this.props.editableData && this.props.editableData.id) {
          if (this.state.editLocationId && this.state.editLocationId.length && res?.status) {
            this.state.editLocationId.map((item, index) => {
              return item && item.locationmapid
                ? (query1 += `id${index}: saveCouponlocationmapping(
                    obj: {
                    active:${0}
                    id:${item.locationmapid}    
                      }
                    ){id}`)
                : "";
            })
            const resData = await API.saveCouponLocationData(`mutation {
                  ${query1}
                }`);
            if (resData?.status === 200) {
              if (this.state.locationid && this.state.locationid.length && resData?.status === 200) {
                this.state.locationid.map((item, index) => {
                  item.locationmapid
                    ? (query += `id${index}: saveCouponlocationmapping(
                          obj: {
                          locationid:${parseFloat(item.id)}
                          couponid: ${parseFloat(this.props.editableData.id)}
                          active:${1}
                          id:${item.locationmapid}    
                            }
                          ){id}`)
                    : (query += `id${index}: saveCouponlocationmapping(
                          obj: {
                          locationid:${parseFloat(item.id)}
                          couponid: ${parseFloat(this.props.editableData.id)}
                          active:${1}  
                            }
                          ){id}`);
                });
                const resData = await API.saveCouponLocationData(`mutation {
                      ${query}
                    }`);
                if (resData?.status === 200) {
                  swal({
                    title: "Updated successfully",
                    icon: "success"
                  });
                }
              }
            }
          } else {
            if (this.state.locationid && this.state.locationid.length) {
              this.state.locationid.map((item, index) => {
                item.locationmapid
                  ? (query += `id${index}: saveCouponlocationmapping(
                        obj: {
                        locationid:${parseFloat(item.id)}
                        couponid: ${parseFloat(this.props.editableData.id)}
                        active:${1}
                        id:${item.locationmapid}    
                          }
                        ){id}`)
                  : (query += `id${index}: saveCouponlocationmapping(
                        obj: {
                        locationid:${parseFloat(item.id)}
                        couponid: ${parseFloat(this.props.editableData.id)}
                        active:${1}  
                          }
                        ){id}`);
              });
              const resData = await API.saveCouponLocationData(`mutation {
                    ${query}
                  }`);
              if (resData?.status === 200) {
                swal({
                  title: "Updated successfully",
                  icon: "success"
                });
              }
            }
          }
          swal({
            title: "Updated successfully",
            icon: "success"
          });
        } else {
          if (this.state.locationid && this.state.locationid.length) {
            let query = "";
            let id = res?.data?.data?.saveCoupon?.id;
            this.state.locationid.map((item, index) => {
              query += `id${index}: saveCouponlocationmapping(
                    obj: {
                      couponid: ${id}
                      locationid: ${parseFloat(item.id)}
                      active:${1}               
                    }
                  ){id}`;
            })
            const resData = await API.saveCouponLocationData(`
                mutation {
                  ${query}
                }
              `)
            if (resData?.status === 200) {
              swal({
                title: "Saved successfully",
                icon: "success"
              });
            }
          }
          swal({
            title: "Saved successfully",
            icon: "success"
          });
        }
        this.props.updateData();
        this.props.modalCloseCallback();
      } else {
        swal({
          icon: "warning",
          title: "Server Error,Try Agian",
          className: "loginSwal",
        });
      }
    }
  }

  handleCheck = (event, e) => {
    debugger
    if (event.target.checked && e.name === "Select All") {
      const locationid = []
      this.state.locationData.length > 0 &&
        this.state.locationData.map(el => locationid.push(el))
      this.setState({ locationid })
    }
    else if (!event.target.checked && e.name === "Select All") {
      const locationid = []
      this.setState({ locationid })
    }
    else if (event.target.checked) {
      const locationid = [...this.state.locationid];
      locationid.push(e);
      this.setState({ locationid });
    } else {
      const temp = [...this.state.locationid];
      const locationid = temp.filter(item => {
        return item ? item.id !== e.id : "";
      });
      this.setState({ locationid });
    }
  };

  render() {
    return (
      <div className="addModalContentBox">
        <div className={'col-md-12'}>
          <form noValidate autoComplete="off" className="row couponForm">
            <div className={'row'}>
              <div className={'col-md-6'}>
                    <TextField
                        name="couponname"
                        label="Coupon Name *"
                        value={this.state.couponname ? this.state.couponname : ""}
                        onChange={this.handleChange}
                        margin="normal"
                        multiline
                        helperText={
                          this.state.errorMessages && this.state.errorMessages.couponname
                              ? this.state.errorMessages.couponname
                              : ""
                        }
                    />
              </div>
              <div className={'col-md-6'}>

                <TextField
                    style={{ lineHeight: 1.45 }}
                    label="Coupon Code *"
                    placeholder="Enter description here"
                    name="description"
                    onChange={this.handleChange}
                    multiline
                    margin="normal"
                    value={
                      this.state.description
                          ? this.state.description
                          : ""
                    }
                    helperText={
                      this.state.errorMessages && this.state.errorMessages.description
                          ? this.state.errorMessages.description
                          : ""
                    }
                />
              </div>
            </div>
            {/*<div className="flexBox">*/}
            {/*  <div className="item">*/}
                {/*<TextField*/}
                {/*  name="couponname"*/}
                {/*  label="Coupon Name *"*/}
                {/*  value={this.state.couponname ? this.state.couponname : ""}*/}
                {/*  onChange={this.handleChange}*/}
                {/*  margin="normal"*/}
                {/*  multiline*/}
                {/*  helperText={*/}
                {/*    this.state.errorMessages && this.state.errorMessages.couponname*/}
                {/*      ? this.state.errorMessages.couponname*/}
                {/*      : ""*/}
                {/*  }*/}
                {/*/>*/}
              {/*</div>*/}
              {/*<div className="item">*/}
              {/*  <TextField*/}
              {/*    name="couponstitle"*/}
              {/*    label="Coupon Title *"*/}
              {/*    value={this.state.couponstitle ? this.state.couponstitle : ""}*/}
              {/*    onChange={this.handleChange}*/}
              {/*    helperText={*/}
              {/*      this.state.errorMessages && this.state.errorMessages.couponstitle*/}
              {/*      ? this.state.errorMessages.couponstitle*/}
              {/*      : ""*/}
              {/*  }*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="item">*/}
                {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                {/*  <Grid container justifyContent="space-around">*/}
                {/*    <KeyboardDateTimePicker*/}
                {/*        variant="inline"*/}
                {/*        format="MM/dd/yyyy"*/}
                {/*        margin="normal"*/}
                {/*        name="startdate"*/}
                {/*        placeholder="Enter start date"*/}
                {/*        label="Coupon Start Date *"*/}
                {/*        value={*/}
                {/*          this.state.startdate*/}
                {/*              ? this.state.startdate*/}
                {/*              : null*/}
                {/*        }*/}
                {/*        onChange={(date)=>this.setState({startdate : date,*/}
                {/*          starttime: `${moment(date).format("YYYY-MM-DD hh:mm A").toString().split(" ")[1]}`})}*/}
                {/*        // onChange={this.handleDateChange.bind(this, "startdate","starttime")}*/}
                {/*        KeyboardButtonProps={{*/}
                {/*          "aria-label": "change date"*/}
                {/*        }}*/}
                {/*        keyboardIcon={*/}
                {/*          <svg*/}
                {/*              xmlns="http://www.w3.org/2000/svg"*/}
                {/*              width="20.5"*/}
                {/*              height="20"*/}
                {/*              viewBox="0 0 31.5 36"*/}
                {/*          >*/}
                {/*            <path*/}
                {/*                id="Icon_awesome-calendar-alt"*/}
                {/*                data-name="Icon awesome-calendar-alt"*/}
                {/*                d="M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0ZM22.5,18.844A.846.846,0,0,1,23.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,23.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,14.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,14.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,5.344,18H8.156A.846.846,0,0,1,9,18.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,5.344,27H8.156A.846.846,0,0,1,9,27.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z"*/}
                {/*            />*/}
                {/*          </svg>*/}
                {/*        }*/}
                {/*        helperText={*/}
                {/*          this.state.errorMessages && this.state.errorMessages.startdate*/}
                {/*              ? this.state.errorMessages.startdate*/}
                {/*              : ""*/}
                {/*        }*/}
                {/*    />*/}
                {/*  </Grid>*/}
                {/*</MuiPickersUtilsProvider>*/}
                {/*<TextField*/}
                {/*  name="recentamount"*/}
                {/*  label="Amount *"*/}
                {/*  value={this.state.recentamount ? this.state.recentamount : ""}*/}
                {/*  onChange={this.handleChange}*/}
                {/*  helperText={*/}
                {/*    this.state.errorMessages && this.state.errorMessages.recentamount*/}
                {/*    ? this.state.errorMessages.recentamount*/}
                {/*    : ""*/}
                {/*}*/}
                {/*/>*/}
              {/*</div>*/}
            {/*</div>*/}
            <div className={'row'}>
               <div className={'col-md-6'}>
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <Grid container justifyContent="space-around">
                       <KeyboardDateTimePicker
                           variant="inline"
                           format="MM/dd/yyyy"
                           margin="normal"
                           name="startdate"
                           placeholder="Enter start date"
                           label="Coupon Start Date *"
                           value={
                             this.state.startdate
                                 ? this.state.startdate
                                 : null
                           }
                           onChange={(date)=>this.setState({startdate : date,
                             // earlier it was 12 hr below
                             // starttime: `${moment(date).format("YYYY-MM-DD hh:mm A").toString().split(" ")[1]}`
                             //changes done for 24 hr below
                             starttime: `${moment(date, "YYYY-MM-DD hh:mm A").format("HH:mm").toString()}`
                           })}
                           // onChange={this.handleDateChange.bind(this, "startdate","starttime")}
                           KeyboardButtonProps={{
                             "aria-label": "change date"
                           }}
                           keyboardIcon={
                             <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="20.5"
                                 height="20"
                                 viewBox="0 0 31.5 36"
                             >
                               <path
                                   id="Icon_awesome-calendar-alt"
                                   data-name="Icon awesome-calendar-alt"
                                   d="M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0ZM22.5,18.844A.846.846,0,0,1,23.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,23.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,14.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,14.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,5.344,18H8.156A.846.846,0,0,1,9,18.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,5.344,27H8.156A.846.846,0,0,1,9,27.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z"
                               />
                             </svg>
                           }
                           helperText={
                             this.state.errorMessages && this.state.errorMessages.startdate
                                 ? this.state.errorMessages.startdate
                                 : ""
                           }
                       />
                     </Grid>
                   </MuiPickersUtilsProvider>
                 </div>
                <div className={'col-md-6'} >
                  <input style={{marginTop:"35px", padding:"6px"}} value={this.state.starttime || "Start Time"} />
                </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDateTimePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        name="enddate"
                        // maxDate={item.max}
                        minDate={this.state.startdate}
                        placeholder="Enter end date"
                        label="Coupon End Date *"
                        value={
                          this.state.enddate
                              ? this.state.enddate
                              : null
                        }
                        // onChange={this.handleDateChange.bind(this, "enddate","endtime")}
                        onChange={(date)=>this.setState({enddate : date,
                          // earlier it was 12 hr below
                          // endtime: `${moment(date).format("YYYY-MM-DD hh:mm A").toString().split(" ")[1]}`
                          // now it as 24 hr below
                          endtime: `${moment(date, "YYYY-MM-DD hh:mm A").format("HH:mm").toString()}`
                        })}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        keyboardIcon={
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.5"
                              height="20"
                              viewBox="0 0 31.5 36"
                          >
                            <path
                                id="Icon_awesome-calendar-alt"
                                data-name="Icon awesome-calendar-alt"
                                d="M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0ZM22.5,18.844A.846.846,0,0,1,23.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,23.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H23.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,14.344,18h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,14.344,27h2.813a.846.846,0,0,1,.844.844v2.813a.846.846,0,0,1-.844.844H14.344a.846.846,0,0,1-.844-.844Zm-9-9A.846.846,0,0,1,5.344,18H8.156A.846.846,0,0,1,9,18.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844Zm0,9A.846.846,0,0,1,5.344,27H8.156A.846.846,0,0,1,9,27.844v2.813a.846.846,0,0,1-.844.844H5.344a.846.846,0,0,1-.844-.844ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z"
                            />
                          </svg>
                        }
                        helperText={
                          this.state.errorMessages && this.state.errorMessages.enddate
                              ? this.state.errorMessages.enddate
                              : ""
                        }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div className={'col-md-6'}>
                <input style={{marginTop:"35px", padding:"6px"}} value={this.state.endtime || "End Time"}/>
              </div>
            </div>
            {/*<div className="flexBox">*/}
              {/*<div className="item">*/}
              {/*  <TextField*/}
              {/*    name="couponname"*/}
              {/*    label="Coupon Name *"*/}
              {/*    value={this.state.couponname ? this.state.couponname : ""}*/}
              {/*    onChange={this.handleChange}*/}
              {/*    margin="normal"*/}
              {/*    multiline*/}
              {/*    helperText={*/}
              {/*      this.state.errorMessages && this.state.errorMessages.couponname*/}
              {/*        ? this.state.errorMessages.couponname*/}
              {/*        : ""*/}
              {/*    }*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="item">*/}
              {/*  <TextField*/}
              {/*    name="couponstitle"*/}
              {/*    label="Coupon Title *"*/}
              {/*    value={this.state.couponstitle ? this.state.couponstitle : ""}*/}
              {/*    onChange={this.handleChange}*/}
              {/*    helperText={*/}
              {/*      this.state.errorMessages && this.state.errorMessages.couponstitle*/}
              {/*      ? this.state.errorMessages.couponstitle*/}
              {/*      : ""*/}
              {/*  }*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="dropDown item col-sm-6 ">*/}
                {/*    <InputLabel>Discount Type</InputLabel>*/}
                {/*    <Select*/}
                {/*      name="discount"*/}
                {/*      value={this.state.discount ? this.state.discount : 0}*/}
                {/*      onChange={this.handleFilter}*/}
                {/*    >*/}
                {/*      <MenuItem value={0}>Discounted Amount</MenuItem>*/}
                {/*      <MenuItem value={1}>Discounted Percentage</MenuItem>*/}
                {/*    </Select>*/}
              {/*</div>*/}
              {/*<div className={'col-md-6'} >*/}
                {/*<input value={this.state.endtime || "End Time"}/>*/}
              {/*</div>*/}
            {/*</div>*/}

            {/*<div className="flexBox">*/}
              {/*{this.state.discount === 0 ?*/}
              {/*    <div className="item discountAmountBox">*/}
              {/*        <span className="dollarSymbol">$</span>*/}
              {/*        <TextField*/}
              {/*     name="discountedamount"*/}
              {/*     label="Discounted Amount *"*/}
              {/*     value={this.state.discountedamount ? this.state.discountedamount : ""}*/}
              {/*     onChange={this.handleChange}*/}
              {/*     helperText={*/}
              {/*       this.state.errorMessages && this.state.errorMessages.discountedamount*/}
              {/*       ? this.state.errorMessages.discountedamount*/}
              {/*       : ""*/}
              {/*   }*/}
              {/*   />*/}
              {/*</div>*/}
              {/* : */}
              {/* <div className="item discountAmountBox">*/}
              {/*   <TextField*/}
              {/*     name="discountedpercentage"*/}
              {/*     label="Discounted Percentage"*/}
              {/*     value={this.state.discountedpercentage ? this.state.discountedpercentage : ""}*/}
              {/*     onChange={this.handleChange}*/}
              {/*     helperText={*/}
              {/*       this.state.errorMessages && this.state.errorMessages.discountedpercentage*/}
              {/*       ? this.state.errorMessages.discountedpercentage*/}
              {/*       : ""*/}
              {/*   }*/}
              {/*   />*/}
              {/*   <span className="dollarSymbol">%</span>*/}
              {/* </div> */}
              {/*}*/}
              {/* <div className="item">*/}
              {/*//earlier in this section multi calender is there*/}
              {/*</div>*/}
              {/*<div className="item">*/}
              {/*</div>*/}
            {/*</div>*/}
            <div className={'row'}>
              <div className={'col-md-6'}>
                <FormControl>
                  <InputLabel id="demo-multiple-checkbox-label">Location</InputLabel>
                  <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={this.state.locationid}
                      name="locationid"
                      multiple
                      input={<Input />}
                      renderValue={selected => {
                        return selected
                            ? selected.map((item, index) => {
                              if (index + 1 === selected.length)
                                return item && item.name ? item.name : "";
                              else {
                                return item && item.name
                                    ? item.name.concat(", ")
                                    : "";
                              }
                            })
                            : "";
                      }}
                  >
                    {this.state.locationData && this.state.locationData.length
                        ? this.state.locationData.map((item, index) => {
                          return (
                              <MenuItem key={index} value={item.name}>
                                <Checkbox
                                    checked={
                                      this.state.locationid
                                          .map(item1 => {
                                            return item1 ? item1.id : 0;
                                          })
                                          .indexOf(item.id) > -1
                                    }
                                    onChange={e => this.handleCheck(e, item)} />
                                <ListItemText primary={item.name} />
                                {/*{item.name}*/}
                              </MenuItem>
                          );
                        })
                        : <MenuItem value={""}>
                          Select
                        </MenuItem>}
                  </Select>
                  {this.state.errorMessages && this.state.errorMessages.locationid ? (
                      <FormHelperText>
                        {this.state.errorMessages.locationid}
                      </FormHelperText>
                  ) : (
                      ""
                  )}
                </FormControl>
              </div>
              <div className={'col-md-6'}>
                <div className="item uploadItemBox">
                  <input
                      style={{marginTop:"10px"}}
                      name='couponlogo'
                      accept="image/png, image/gif, image/jpeg"
                      type="file"
                      // value={this.state.couponlogo}
                      onChange={(e) => this.onChangeFileHandler(e)}
                      id='uploadFile' />
                  <label>{this.state.couponlogo ? this.state.couponlogo : "Upload Coupon Image"}</label>
                </div>
              </div>
            </div>
            {/*<div className="flexBox">*/}
              {/*<div className="item">*/}
              {/*</div>*/}
              {/*<div className="dropDown item col-sm-6 ">*/}
              {/*<FormControl>*/}
              {/*        <InputLabel>Category</InputLabel>*/}
              {/*        <Select*/}
              {/*          name="categoryid"*/}
              {/*          value={this.state.categoryid ? this.state.categoryid : ""}*/}
              {/*          onChange={this.handleFilter}*/}
              {/*        >*/}
              {/*          <MenuItem value={1}>Coupon</MenuItem>*/}
              {/*        </Select>*/}
              {/*</FormControl>*/}
              {/*      </div>*/}
              {/*<div className="item uploadItemBox">*/}
                {/*<input*/}
                {/*  name='couponlogo'*/}
                {/*  accept="image/png, image/gif, image/jpeg"*/}
                {/*  type="file"*/}
                {/*  value={this.state.couponlogo}*/}
                {/*  onChange={(e) => this.onChangeFileHandler(e)}*/}
                {/*  id='uploadFile' />*/}
                {/*<label>{this.state.couponlogo ? this.state.couponlogo : "Upload Coupon Image"}</label>*/}
              {/*</div>*/}
            {/*</div>*/}
            <div className="actionBtnBox">
              <Button className="saveBtn" onClick={() => this.saveCouponBtn()}>Save</Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CouponAddEdit;