export const addUserManagementConfig = {
    className: "addUserManagementForm",
    fields: [
      {
        label: "First Name",
        type: "input",
        name: "firstname",
        value: "",
        required: true,
      },
      {
        label: "Last Name",
        type: "input",
        name: "lastname",
        value: "",
        required: true,
      },
      {
        label: "Email",
        type: "input",
        name: "email",
        value: "",
        required: true,
      },
      {
        label: "Mobile Number",
        type: "input",
        name: "phonenumber",
        value: "",
        required: true,
      },
      {
        label: "Type",
        type: "staticSelect",
        name: "role",
        value: "LOCATIONMANAGER",
        data: [{id: "LOCATIONMANAGER", name: "Location Manager"}],
      },
      {
        label: "Location",
        type: "dynamicSelectCheckbox",
        name: "locationid",
        multiple: true,
        value: [],
        data: "locationOptions",
        required: true,
      }
    ],
    validations: {
      firstname: (e) => {
        let re = new RegExp(/^[A-Za-z]+$/);
        if (e == "") {
          return {
            valid: false,
            errMsg: "Please enter first name",
          };
        }
        if (re.test(e) === false) {
          return {
            valid: false,
            errMsg: "First name should be contain only letters.",
          };
        }
        return {
          valid: true,
        };
      },
      lastname: (e) => {
        let re = new RegExp(/^[A-Za-z]+$/);
        if (e == "") {
          return {
            valid: false,
            errMsg: "Please enter last name",
          };
        }
        if (re.test(e) === false) {
          return {
            valid: false,
            errMsg: "First name should be contain only letters.",
          };
        }
        return {
          valid: true,
        };
      },
      locationid: (e) => {
        if (e.length == 0) {
          return {
            valid: false,
            errMsg: "Please Select Location",
          };
        }
        return {
          valid: true,
        };
      },
      email: (e) => {
        let re = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (e == "") {
          return {
            valid: false,
            errMsg: "Please enter email",
          };
        }
        if (re.test(e) === false) {
          return {
            valid: false,
            errMsg: "Please enter valid email ",
          };
        }
        return {
          valid: true,
        };
      },
      phonenumber: (e) => {
        let re = new RegExp("[1-9]");
        let digit = new RegExp("^[0-9]{10}$");
        if (e == "") {
          return {
            valid: false,
            errMsg: "Please enter mobile number",
          };
        }
        if (re.test(e) === false) {
          return {
            valid: false,
            errMsg: "Mobile number should be contain only numeric value.",
          };
        }
        if (digit.test(e) === false) {
          return {
            valid: false,
            errMsg: "Mobile number should be contain 10 digit.",
          };
        }
        return {
          valid: true,
        };
      },
    },
  };
export const editUserManagementConfig = {
  className: "addUserManagementForm",
  fields: [
    {
      label: "First Name",
      type: "input",
      name: "firstname",
      value: "",
      required: true,
    },
    {
      label: "Last Name",
      type: "input",
      name: "lastname",
      value: "",
    },
    {
      label: "Email",
      type: "input",
      name: "email",
      value: "",
      required: true,
    },
    {
      label: "Mobile Number",
      type: "input",
      name: "phonenumber",
      value: "",
      required: true,
    },
    {
      label: "Type",
      type: "staticSelect",
      name: "role",
      value: "LOCATIONMANAGER",
      data: [{id: "LOCATIONMANAGER", name: "Location Manager"}],
    },
    {
      label: "Location",
      type: "dynamicSelectCheckbox",
      name: "locationid",
      multiple: true,
      value: [],
      data: "locationOptions",
      required: true,
    },
    {
      label: "Status",
      type: "staticSelect",
      name: "active",
      value: "",
      data: [
          {id: 0, name: "inActive"},
          {id: 1, name: "Active"}
      ],
    },
  ],
  validations: {
    firstname: (e) => {
      let re = new RegExp("[a-zA-Z]");
      if (e == "") {
        return {
          valid: false,
          errMsg: "Please enter first name",
        };
      }
      if (re.test(e) === false) {
        return {
          valid: false,
          errMsg: "First name should be contain only letters.",
        };
      }
      return {
        valid: true,
      };
    },
    email: (e) => {
      let re = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (e == "") {
        return {
          valid: false,
          errMsg: "Please enter email",
        };
      }
      if (re.test(e) === false) {
        return {
          valid: false,
          errMsg: "Please enter valid email ",
        };
      }
      return {
        valid: true,
      };
    },
    phonenumber: (e) => {
      let re = new RegExp("[1-9]");
      let digit = new RegExp("^[0-9]{10}$");
      if (e == "") {
        return {
          valid: false,
          errMsg: "Please enter mobile number",
        };
      }
      if (re.test(e) === false) {
        return {
          valid: false,
          errMsg: "Mobile number should be contain only numeric value.",
        };
      }
      if (digit.test(e) === false) {
        return {
          valid: false,
          errMsg: "Mobile number should be contain 10 digit.",
        };
      }
      return {
        valid: true,
      };
    },
  },
};
