import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import {ReactComponent  as Apple} from '../../src/assets/apple.svg' ;
import {ReactComponent  as GoolgePlay} from '../../src/assets/googleplay.svg';


class Advertisement extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    render(){
        return(
            <div className="subHeader" style={{padding:"0"}}>
                <div className="statement">
                    {/*<p>Download our <span className="new">new</span> <span>Chanticlear Pizza App</span> to recieve a free chanti sticks and exclusive coupons!</p>*/}
                </div>
               <div className="actionFlexbox">
                   {/*<Button>*/}
                    <div>
                    {/*<Apple/>*/}
                        <div className="f-col">
                            {/*<small>Download on the</small>*/}
                            {/*<span>App Store</span>*/}
                        </div>
                    </div>
                {/*</Button>*/}
                {/*<Button>*/}
                    <div>
                       {/*<GoolgePlay/>*/}
                        <div className="f-col">
                            {/*<small>Get it on</small>*/}
                            {/*<span>Google Play</span>*/}
                        </div>
                    </div>
                {/*</Button>*/}
                </div>
            </div>
        );
    }
}

export default Advertisement;