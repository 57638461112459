import React from "react";
import ManageLocation from "../Components/ManageLocation/ManageLocation";
import UserManagement from "../Components/UserManagement/UserManagement";
import Analytics from "../Components/Analytics/Analytics";
import Activity from "../Components/Activity/Activity";
import Coupon from "../Components/Coupon/Coupon";
import CoverScreen from "../Components/CoverScreen/CoverScreen";
import ProfileSetting from "../Components/ProfileSetting/ProfileSetting";
import Logout from "../Components/Logout/Logout";
import CouponImage from "../assets/coupon.png";
import ArrowChart from "../assets/ArrowChart.png";
import AddManageLocation from "../Components/ManageLocation/AddManageLocation";
import PushNotification from "../Components/Notification/PushNotification";
import ViewNotification from "../Components/Notification/ViewNotification";
import { User } from '../Components/User/'
export const routeConfigList = [
    {
        title: "Create Push Notification",
        path: "/pushNotification",
        image: (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 60 60" style={{ enableBackground: "new 0 0 60 60" }}>
                <g>
                    <path d="M26,9.586C11.664,9.586,0,20.09,0,33c0,4.499,1.418,8.856,4.106,12.627c-0.51,5.578-1.86,9.712-3.813,11.666   c-0.304,0.304-0.38,0.768-0.188,1.153C0.276,58.789,0.625,59,1,59c0.046,0,0.093-0.003,0.14-0.01   c0.349-0.049,8.432-1.213,14.317-4.585c3.33,1.333,6.874,2.009,10.544,2.009c14.336,0,26-10.503,26-23.414S40.337,9.586,26,9.586z" />
                    <path d="M55.894,37.042C58.582,33.27,60,28.912,60,24.414C60,11.503,48.337,1,34,1c-8.246,0-15.968,3.592-20.824,9.42   C17.021,8.614,21.38,7.586,26,7.586c15.439,0,28,11.4,28,25.414c0,5.506-1.945,10.604-5.236,14.77   c4.946,1.887,9.853,2.6,10.096,2.634c0.047,0.006,0.094,0.01,0.14,0.01c0.375,0,0.724-0.211,0.895-0.554   c0.192-0.385,0.116-0.849-0.188-1.153C57.753,46.753,56.403,42.619,55.894,37.042z" />
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>
        )
    },
    {
        title: "View Push Notification",
        path: "/viewNotification",
        image: (
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" /></svg>
        )
    },
    {
        title: "Coupons",
        path: "/coupon",
        image: (
            <img src={CouponImage} />
        )
    },
    {
        title: "Cover Screens",
        path: "/coverScreen",
        image: (
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M14 7H8v14h11v-9h-5z" opacity=".3" />
                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zm4 16H8V7h6v5h5v9z" />
            </svg>
        )
    },
    {
        title: "Analytics",
        path: "/analytics",
        image: (
            <img src={ArrowChart} />
        )
    },
    {
        title: "Activity",
        path: "/activity",
        image: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 20 20">
                <path d="M4.34 15.66A7.97 7.97 0 0 0 9 17.94V10H5V8h4V5.83a3 3 0 1 1 2 0V8h4v2h-4v7.94a7.97 7.97 0 0 0 4.66-2.28l-1.42-1.42h5.66l-2.83 2.83a10 10 0 0 1-14.14 0L.1 14.24h5.66l-1.42 1.42zM10 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
        )
    },
  {
    title: "Locations",
    path: "/",
    role:"LOCATIONMANAGER",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" />
        <circle cx="12" cy="9" r="2.5" />
      </svg>
    )
  },
  {
    title: "Users",
    path: "/userManagement",
    role:"LOCATIONMANAGER",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
      </svg>
    )
  },
    {
        title: "Customers",
        path: "/User",
        image: (
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M14 7H8v14h11v-9h-5z" opacity=".3" />
                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zm4 16H8V7h6v5h5v9z" />
            </svg>
        )
    },
  {
    title: "Profile Settings",
    path: "/profileSetting",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
      </svg>
    )
  },
  {
    title: "Logout",
    path: "/logout",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
        </g>
      </svg>
    )
  }
];

// export const locationManagerConfigList = [
//   {
//     title: "Analytics",
//     path: "/",
//     image: (
//       <img src={ArrowChart} />
//     )
//   },
//   {
//     title: "Activity",
//     path: "/activity",
//     image: (
//       <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 20 20">
//         <path d="M4.34 15.66A7.97 7.97 0 0 0 9 17.94V10H5V8h4V5.83a3 3 0 1 1 2 0V8h4v2h-4v7.94a7.97 7.97 0 0 0 4.66-2.28l-1.42-1.42h5.66l-2.83 2.83a10 10 0 0 1-14.14 0L.1 14.24h5.66l-1.42 1.42zM10 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
//       </svg>
//     )
//   },
//   {
//     title: "Coupon",
//     path: "/coupon",
//     image: (
//       <img src={CouponImage} />
//     )
//   },
//   {
//     title: "Cover Screen",
//     path: "/coverScreen",
//     image: (
//       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
//         <path d="M0 0h24v24H0V0z" fill="none" />
//         <path d="M14 7H8v14h11v-9h-5z" opacity=".3" />
//         <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zm4 16H8V7h6v5h5v9z" />
//       </svg>
//     )
//   },

//   {
//     title: "Profile Setting",
//     path: "/profileSetting",
//     image: (
//       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
//         <path d="M0 0h24v24H0V0z" fill="none" />
//         <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
//       </svg>
//     )
//   },
//   {
//     title: "Push Notification",
//     path: "/pushNotification",
//     image: (
//       <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 60 60" style={{ enableBackground: "new 0 0 60 60" }}>
//         <g>
//           <path d="M26,9.586C11.664,9.586,0,20.09,0,33c0,4.499,1.418,8.856,4.106,12.627c-0.51,5.578-1.86,9.712-3.813,11.666   c-0.304,0.304-0.38,0.768-0.188,1.153C0.276,58.789,0.625,59,1,59c0.046,0,0.093-0.003,0.14-0.01   c0.349-0.049,8.432-1.213,14.317-4.585c3.33,1.333,6.874,2.009,10.544,2.009c14.336,0,26-10.503,26-23.414S40.337,9.586,26,9.586z" />
//           <path d="M55.894,37.042C58.582,33.27,60,28.912,60,24.414C60,11.503,48.337,1,34,1c-8.246,0-15.968,3.592-20.824,9.42   C17.021,8.614,21.38,7.586,26,7.586c15.439,0,28,11.4,28,25.414c0,5.506-1.945,10.604-5.236,14.77   c4.946,1.887,9.853,2.6,10.096,2.634c0.047,0.006,0.094,0.01,0.14,0.01c0.375,0,0.724-0.211,0.895-0.554   c0.192-0.385,0.116-0.849-0.188-1.153C57.753,46.753,56.403,42.619,55.894,37.042z" />
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//         <g>
//         </g>
//       </svg>
//     )
//   },
//   {
//     title: "View Notification",
//     path: "/viewNotification",
//     image: (
//       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
//         <path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" /></svg>
//     )
//   },
//   {
//     title: "Customer",
//     path: "/User",
//     image: (
//       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
//         <path d="M0 0h24v24H0V0z" fill="none" />
//         <path d="M14 7H8v14h11v-9h-5z" opacity=".3" />
//         <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zm4 16H8V7h6v5h5v9z" />
//       </svg>
//     )
//   },
//   {
//     title: "Logout",
//     path: "/logout",
//     image: (
//       <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
//         <g>
//           <path d="M0,0h24v24H0V0z" fill="none" />
//         </g>
//         <g>
//           <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
//         </g>
//       </svg>
//     )
//   }
// ];

export const Routes = [
  {
    path: "/",
    exact: true,
    main: (e, props) => <ManageLocation {...e} data={props} />
  },
  {
    path: "/userManagement",
    exact: true,
    main: (e, props) => <UserManagement {...e} data={props} />
  },
  {
    path: "/analytics",
    exact: true,
    main: (e, props) => <Analytics {...e} data={props} />
  },
  {
    path: "/activity",
    exact: true,
    main: (e, props) => <Activity {...e} data={props} />
  },
  {
    path: "/coupon",
    exact: true,
    main: (e, props) => <Coupon {...e} data={props} />
  },
  {
    path: "/coverScreen",
    exact: true,
    main: (e, props) => <CoverScreen {...e} data={props} />
  },
  {
    path: "/profileSetting",
    exact: true,
    main: (e, props) => <ProfileSetting {...e} data={props} />
  },
  {
    path: "/logout",
    exact: true,
    main: (e, props) => <Logout {...e} data={props} />
  },
  {
    path: "/addLocation",
    exact: true,
    main: (e, props) => <AddManageLocation {...e} data={props} />
  },
  {
    path: "/editLocation",
    exact: true,
    main: (e, props) => <AddManageLocation {...e} data={props} />
  },
  {
    path: "/pushNotification",
    exact: true,
    main: (e, props) => <PushNotification {...e} data={props} />
  },
    {
        path: "/editNotification",
        exact: true,
        main: (e, props) => <PushNotification {...e} data={props} />
    },
  {
    path: "/viewNotification",
    exact: true,
    main: (e, props) => <ViewNotification {...e} data={props} />
  },
  {
    path: "/User",
    exact: true,
    main: (e, props) => <User {...e} data={props} />
  }
];

// export const locationRoutes = [
//   {
//     path: "/",
//     exact: true,
//     main: (e, props) => <Analytics {...e} data={props} />
//   },
//   {
//     path: "/activity",
//     exact: true,
//     main: (e, props) => <Activity {...e} data={props} />
//   },
//   {
//     path: "/coupon",
//     exact: true,
//     main: (e, props) => <Coupon {...e} data={props} />
//   },
//   {
//     path: "/coverScreen",
//     exact: true,
//     main: (e, props) => <CoverScreen {...e} data={props} />
//   },
//   {
//     path: "/profileSetting",
//     exact: true,
//     main: (e, props) => <ProfileSetting {...e} data={props} />
//   },
//   {
//     path: "/logout",
//     exact: true,
//     main: (e, props) => <Logout {...e} data={props} />
//   },
//   {
//     path: "/pushNotification",
//     exact: true,
//     main: (e, props) => <PushNotification {...e} data={props} />
//   },
//     {
//         path: "/editNotification",
//         exact: true,
//         main: (e, props) => <PushNotification {...e} data={props} />
//     },
//   {
//     path: "/viewNotification",
//     exact: true,
//     main: (e, props) => <ViewNotification {...e} data={props} />
//   },
//   {
//     path: "/User",
//     exact: true,
//     main: (e, props) => <User {...e} data={props} />
//   }
// ];