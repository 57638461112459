export const getAllUserQuery = (offset, limit) => `
query allUserdata($where: JSON){
    allUserdata(where:$where, first:${offset || null}, last:${limit || null}){
    totalCount
     Userdata{
     userlocationmappingUseridMap(where:{active:1}){
        Userlocationmappings{
          userlocationmappinglocationidmaprel{
            Locations{
              locationname
            }
          }
        }
      }
       active
       locationname
       locationid
       firstname
       lastname
       id
       createdon
       active
       email
       phonenumber
       role
       address
       city
       country
       state
       zipcode
     }
   }
   }
`;

export const saveUserData = `
mutation saveUserdata($obj: UserdataInput!){
  saveUserdata(obj: $obj){
    id
  }
}
`;

//Query for get all user data based on user id
export const getUserByID = (id) =>`query{
  allUserdata(where:{id:${id}}){
    Userdata{
      id
      active
      firstname
      lastname
      email
      phonenumber
      role
      userdatalocationidmaprel{
        Locations{
          id
          locationname
        }
      }
       userlocationmappingUseridMap(where: {active:1}){
        Userlocationmappings{
       id
       locationid
          userlocationmappinglocationidmaprel{
            Locations{
              id
              locationname
            }
          }
        }
      }
    }
  }
}
`;

//Query for get all customer email
export const getCustomerEmail  = (userRole) =>`query{
  allUserdata(where:{role:"${userRole}"}){
    Userdata{
      id
      email
      role
      active
    }
  }
}
`;