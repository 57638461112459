import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import "./manageLocation.css";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import ChanticlearLogo from "../../assets/ChanticlearLogo.png";
import {manageLocationQuery, saveLocationQuery} from "./ManageLocationQuery";
import swal from "sweetalert";
import {fetchMethod} from "../../FetchMethod";
import {ImageUploadURL} from "../../Config"
import {mutationQueries} from "../../MutationMethod";
import { isTemplateElement } from '@babel/types';
import { connect } from "react-redux";
import {API} from "../../service";
import '../../common/style/style.css'
import { Loader } from '../../common/Loader'
import Pagination from "react-js-pagination";

class ManageLocation extends Component{
    constructor(props){
        super(props);
        this.state={
            listData:[],
            count:0,
            perPage:20,
            offset:20,
            currentPage:1,
            loader : true
        }
    }

    componentDidMount(){
      this.props.sideBarPath("/");
        this.getManageLocationData(null, this.state.offset, this.state.perPage);
    }

    getManageLocationData = async (body, offset, limit) => {
        this.setState({loader : true})
        const res = await API.getManageLocation(null, offset, limit)
              if(res){
                this.setState({
                  listData: res.data?.allLocations?.Locations || [],
                  count: res?.data?.allLocations?.totalCount || [],
                  loader: false
                });
              }else{
                  swal({
                    title: "Server Error",
                    icon: "warning"
                  });
                  this.setState({ listData: [], loader: false });
                }
    }
    handleDelete = async (item) => {
      if (item.active === 1 || item.active === "1") {
      const willDelete = await  swal({
          title: "Are you sure you want to delete this record?",
          icon: "warning",
          buttons: true,
          dangerMode: true
        })
          if (willDelete) {
            const test = {
                obj:{
                    id: item.id,
                    active: 0
                }
            }
            const  res  = await  API.saveManageLocation(test)
            if(res){
              swal({
                title: "Deleted successfully",
                icon: "success"
              });
              this.getManageLocationData();
            }else{
                swal({
                  title: "Server Error While Deleting,Try Agian",
                  icon: "warning"
                })
            }
          }
      }
    }

    handleEditModal = (row) => {
      this.props.history.push({
        pathname: "/editLocation",
        state: row.id
      });
    }
    //func for pagination showing 10 location per page
    handlePagination = async (page_num) => {

        const offset_num = page_num * this.state.perPage
        this.setState({
            currentPage : page_num,
            offset:offset_num,
            loader : true
        })
        this.getManageLocationData(null, offset_num, this.state.perPage)
        // console.log("")
    }
    render(){
        const listData = this.state.listData;
        return(
            <div className="manageLocation">
               <h3>Manage Location</h3>
                {this.state.loader ?
                    <Loader/>
                    :
                    <>
                        <div className="addLocation">
                            <Button onClick={() => this.props.history.push("/addLocation")}>Add Location</Button>
                        </div>
                        <table className="table">
                            <tr>
                                <th><span>Logo</span></th>
                                {/* <div className="flexBox">Product <span onClick={() => onClickIcon('name')} style={{cursor: "pointer"}}>{imageIcon.name ? <ArrowUpward /> : <ArrowDownward />}</span></div> */}
                                <th><span>Location</span></th>
                                <th><span>Address</span></th>
                                <th><span>Status</span></th>
                                <th><span>Edit</span></th>
                                <th><span>Delete</span></th>
                            </tr>


                            {listData?.length > 0 ? listData?.map((ed, ex) => {
                                    return (
                                        <tr key={ex}>
                                            <td><img
                                                src={ed.locationlogo ? ImageUploadURL.download + "/" + ed.locationlogo : ""}
                                                alt="Image Crashed"/></td>
                                            <td>{ed?.locationname ?? "N/A"}</td>
                                            <td>{ed?.locationaddress ?? "N/A"}</td>
                                            <td><span
                                                style={ed?.active === 1 ? {color: "green"} : {color: "red"}}>{ed?.active === 1 ? "Active" : "InActive"}</span>
                                            </td>
                                            <td className="editIcon" onClick={() => this.handleEditModal(ed)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                                     width="24px" fill="#000000">
                                                    <path d="M0 0h24v24H0V0z" fill="none"/>
                                                    <path
                                                        d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/>
                                                </svg>
                                            </td>
                                            <td className="deleteIcon" onClick={() => this.handleDelete(ed)}
                                                style={ed?.active === 0 ? {cursor: "not-allowed"} : {}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                                     width="24px" fill="#000000">
                                                    <path d="M0 0h24v24H0V0z" fill="none"/>
                                                    <path
                                                        d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
                                                </svg>
                                            </td>
                                        </tr>
                                    )
                                })
                                : <div style={{paddingLeft: "10px", fontSize: "15px", fontWeight: "700"}}>
                                    No Data Found
                                </div>
                            }
                        </table>
                        <div className={'row'}>
                            <div className={'col-md-7'}>
                                <div className="newPagination" style={{textAlign: "right"}}>
                                    <Pagination
                                        innerClass='pagination pagination-sm'
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activePage={this.state.currentPage}
                                        activeClass={"active"}
                                        itemsCountPerPage={this.state.perPage}
                                        totalItemsCount={this.state.count}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePagination}
                                    />
                                </div>
                            </div>
                            <div className={'col-md-5'}>
                               <span style={{float:"right", color:"#ef3125"}}>
                                   {`Showing ${this.state.count || 0} Locations (20 PerPage) `}
                               </span>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    sideBarPath: route =>
      dispatch({ type: "Show_Sidebar_Route", payload: route })
  };
};

export default connect(null,mapDispatchToProps)(ManageLocation);