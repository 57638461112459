import React, { Component } from "react";
import Sidebar from "./SideBar/Sidebar";
import { Switch, BrowserRouter, Route, HashRouter } from "react-router-dom";
import Login from "./Login/Login";
import ForgotPassword from "./Login/ForgotPassword";
import ResetPassword from "./Login/ResetPassword";
import Analytics from "./Components/Analytics/Analytics";
class App extends Component {

  render() {
    return (
      <div className="App">
          <HashRouter>
            <Switch>
              {localStorage.getItem("token") !== null && localStorage.getItem("token") !== "null" ? (
                <Route path="/" component={Sidebar} />
              ) : (
                <Route exact path="/" component={Login} />
              )}
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/resetPassword" component={ResetPassword} />      
            </Switch>
          </HashRouter>
      </div>
    );
  }
}

export default App;
