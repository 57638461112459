export const locationDataQuery = `
query allLocations($where:JSON){
  allLocations(where:$where){
  totalCount
    Locations{
      id
      locationname
    }
  }
}
`;