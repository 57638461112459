export const getImageDataQuery = `
query allCovers($where:JSON){
  allCovers(where:$where){
    Covers{
      id
      active
      rankvlue
      covername
      locationid
      setmenuurl
      setsignupurl
      setspecialurl
      setorderurl
    }
  }
}
`;

export const saveCoverScreen = `
mutation saveCover($obj:CoverInput!){
    saveCover(obj:$obj){
      id
    }
  }
`;