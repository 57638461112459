import React from "react";
import { Map, GoogleApiWrapper, HeatMap, Marker, InfoWindow } from "google-maps-react";
import { ENV_Key } from '../Config'

class MapContainer extends React.Component {
    constructor(props){
        console.log("propsss===>>>", props)
        super(props);
        this.state={
            heatMapData: this.props.data,
            showingInfoWindow: false,
            activeMarker: false,
            selectedPlace: false
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({heatMapData: nextProps.data})
    }


    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        const { heatMapData  } = this.state;
      //  console.log("heattttt===>>>",heatMapData)
        const marker ={
            lat : heatMapData.lat,
            lng : heatMapData.lng
        }
        console.log("heatrmaapp===>>>",marker)
        // const positions = heatMapData.map(item => { return { "lat": item.lat, "lng": item.lng}});
        // const gradient = [
        //     "rgba(0, 255, 255, 0)",
        //     "rgba(0, 255, 255, 1)",
        //     "rgba(0, 191, 255, 1)",
        //     "rgba(0, 127, 255, 1)",
        //     "rgba(0, 63, 255, 1)",
        //     "rgba(0, 0, 255, 1)",
        //     "rgba(0, 0, 223, 1)",
        //     "rgba(0, 0, 191, 1)",
        //     "rgba(0, 0, 159, 1)",
        //     "rgba(0, 0, 127, 1)",
        //     "rgba(63, 0, 91, 1)",
        //     "rgba(127, 0, 63, 1)",
        //     "rgba(191, 0, 31, 1)",
        //     "rgba(255, 0, 0, 1)"
        // ];
        return (
            <div className={'row'}>
                <Map
                    style={{width:"50%",height:"50%",marginTop:"50px"}}
                    google={this.props.google}
                    className={"map"}
                    zoom={4}
                    initialCenter={this.props.center}
                    onReady={this.handleMapReady}
                >
                    {/*{heatMapData.length ? <HeatMap*/}
                    {/*    gradient={gradient}*/}
                    {/*    positions={positions}*/}
                    {/*    opacity={1}*/}
                    {/*    radius={15}*/}
                    {/*/> : ""}*/}
                            <Marker
                                position={marker}
                                draggable={false}
                                onClick={this.onMarkerClick}
                                name={"noida"}
                            />
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div>
                            <h4>{this.state.selectedPlace.name}</h4>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ENV_Key,
    libraries: ["visualization"]
})(MapContainer);
